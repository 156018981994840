import { Routes } from "@angular/router";
import { AuthGuard } from "@libs/guards/auth/auth.guard";

export const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
  },
  {
    path: "login",
    loadComponent: () => import("./pages/login/login.component").then((m) => m.LoginComponent),
  },
  {
    path: "admin",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/admin/admin.component").then((m) => m.AdminComponent),
  },
  {
    path: "admin/:id",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/admin/admin.component").then((m) => m.AdminComponent),
  },
  {
    path: "admin/register/:type",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/admin-register/admin-register.component").then((m) => m.AdminRegisterComponent),
  },
  {
    path: "admin/register/:type/:id",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/admin-register/admin-register.component").then((m) => m.AdminRegisterComponent),
  },
  {
    path: "transfer",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/transfer/transfer.component").then((m) => m.TransferComponent),
  },
  {
    path: "transfer-details",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/transfer-details/transfer-details.component").then((m) => m.TransferDetailsComponent),
  },
  {
    path: "workflow",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/workflow/workflow.component").then((m) => m.WorkflowComponent),
  },
  {
    path: "workflow-resume",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/workflow-resume/workflow-resume.component").then((m) => m.WorkflowResumeComponent),
  },
  {
    path: "report-operation",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/report-operation/report-operation.component").then((m) => m.ReportOperationComponent),
  },
  {
    path: "template",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/template/template.component").then((m) => m.TemplateComponent),
  },
  {
    path: "network",
    canActivate: [AuthGuard],
    loadComponent: () => import("./pages/network/network.component").then((m) => m.NetworkComponent),
  },
  {
    path: "validar",
    loadComponent: () => import("./pages/validation/validation.component").then((m) => m.ValidationComponent),
  },
  {
    path: "forgot-password",
    loadComponent: () => import("./pages/forgot-password/forgot-password.component").then((m) => m.ForgotPasswordComponent),
  },
  {
    path: "create-password",
    loadComponent: () => import("./pages/create-password/create-password.component").then((m) => m.CreatePasswordComponent),
  },
  {
    path: "confirm-code",
    loadComponent: () => import("./pages/confirm-code/confirm-code.component").then((m) => m.ConfirmCodeComponent),
  },
  {
    path: "taxes",
    loadComponent: () => import("./pages/taxes/taxes.component").then((m) => m.TaxesComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "taxes-history",
    loadComponent: () => import("./pages/taxes-history/taxes-history.component").then((m) => m.TaxesHistoryComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "report-financial",
    loadComponent: () => import("./pages/report-financial/report-financial.component").then((m) => m.ReportFinancialComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];
