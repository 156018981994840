/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/product/v1/product.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as dependency_2 from "./../../transaction/v1/transaction";
import * as pb_1 from "google-protobuf";
export namespace core.product.v1 {
    export enum Status {
        PRODUCT_STATUS_UNSPECIFIED = 0,
        PRODUCT_STATUS_PENDING = 1,
        PRODUCT_STATUS_PUBLISHED = 2,
        PRODUCT_STATUS_EXPIRED = 3,
        PRODUCT_STATUS_REJECTED = 4,
        PRODUCT_STATUS_PUBLISHING = 5,
        PRODUCT_STATUS_BURNING = 6,
        PRODUCT_STATUS_BURNED = 7,
        PRODUCT_STATUS_EXPIRING = 8,
        PRODUCT_STATUS_TELEPORTING = 9,
        PRODUCT_STATUS_TELEPORTED = 10,
        PRODUCT_STATUS_TRANSFERRING = 11,
        PRODUCT_STATUS_TRANSFERRED = 12
    }
    export enum ParameterType {
        unspecified = 0,
        string = 1,
        number = 2,
        date = 3,
        tenor = 4,
        object = 5,
        money = 6,
        hour = 7,
        cpf = 8,
        cnpj = 9,
        options_multiple = 10,
        options_single = 11,
        number_decimal = 12,
        cep = 13,
        phone = 14,
        email = 15,
        url = 16,
        checkbox = 17,
        file = 18,
        toggle = 19,
        document = 20,
        face_value = 21
    }
    export enum ProductSettings {
        PRODUCT_SETTINGS_UNSPECIFIED = 0
    }
    export enum ParameterKind {
        PARAMETER_KIND_UNSPECIFIED = 0,
        PARAMETER_KIND_EXPLICIT_VALUE = 1,
        PARAMETER_KIND_PERCENTAGE_VALUE = 2
    }
    export class Product extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            commercialName?: string;
            productInfoId?: string;
            productInfo?: ProductInfo;
            values?: Map<string, string>;
            productSettings?: ProductSettings[];
            enabled?: boolean;
            status?: Status;
            transactions?: string[];
            createdAt?: dependency_1.google.protobuf.Timestamp;
            lastError?: string;
            dataPublicacao?: dependency_1.google.protobuf.Timestamp;
            tokenId?: string;
            originId?: string;
            originName?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6, 9], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("commercialName" in data && data.commercialName != undefined) {
                    this.commercialName = data.commercialName;
                }
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("productInfo" in data && data.productInfo != undefined) {
                    this.productInfo = data.productInfo;
                }
                if ("values" in data && data.values != undefined) {
                    this.values = data.values;
                }
                if ("productSettings" in data && data.productSettings != undefined) {
                    this.productSettings = data.productSettings;
                }
                if ("enabled" in data && data.enabled != undefined) {
                    this.enabled = data.enabled;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("lastError" in data && data.lastError != undefined) {
                    this.lastError = data.lastError;
                }
                if ("dataPublicacao" in data && data.dataPublicacao != undefined) {
                    this.dataPublicacao = data.dataPublicacao;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("originId" in data && data.originId != undefined) {
                    this.originId = data.originId;
                }
                if ("originName" in data && data.originName != undefined) {
                    this.originName = data.originName;
                }
            }
            if (!this.values)
                this.values = new Map();
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get commercialName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set commercialName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get productInfo() {
            return pb_1.Message.getWrapperField(this, ProductInfo, 4) as ProductInfo;
        }
        set productInfo(value: ProductInfo) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasProductInfo() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get values() {
            return pb_1.Message.getField(this, 5) as any as Map<string, string>;
        }
        set values(value: Map<string, string>) {
            pb_1.Message.setField(this, 5, value as any);
        }
        get productSettings() {
            return pb_1.Message.getFieldWithDefault(this, 6, []) as ProductSettings[];
        }
        set productSettings(value: ProductSettings[]) {
            pb_1.Message.setField(this, 6, value);
        }
        get enabled() {
            return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
        }
        set enabled(value: boolean) {
            pb_1.Message.setField(this, 7, value);
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 8, Status.PRODUCT_STATUS_UNSPECIFIED) as Status;
        }
        set status(value: Status) {
            pb_1.Message.setField(this, 8, value);
        }
        get transactions() {
            return pb_1.Message.getFieldWithDefault(this, 9, []) as string[];
        }
        set transactions(value: string[]) {
            pb_1.Message.setField(this, 9, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 10) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 10, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get lastError() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set lastError(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        get dataPublicacao() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 12) as dependency_1.google.protobuf.Timestamp;
        }
        set dataPublicacao(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 12, value);
        }
        get hasDataPublicacao() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 13, value);
        }
        get originId() {
            return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
        }
        set originId(value: string) {
            pb_1.Message.setField(this, 14, value);
        }
        get originName() {
            return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
        }
        set originName(value: string) {
            pb_1.Message.setField(this, 15, value);
        }
        static fromObject(data: {
            id?: string;
            commercialName?: string;
            productInfoId?: string;
            productInfo?: ReturnType<typeof ProductInfo.prototype.toObject>;
            values?: {
                [key: string]: string;
            };
            productSettings?: ProductSettings[];
            enabled?: boolean;
            status?: Status;
            transactions?: string[];
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            lastError?: string;
            dataPublicacao?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            tokenId?: string;
            originId?: string;
            originName?: string;
        }): Product {
            const message = new Product({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.commercialName != null) {
                message.commercialName = data.commercialName;
            }
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.productInfo != null) {
                message.productInfo = ProductInfo.fromObject(data.productInfo);
            }
            if (typeof data.values == "object") {
                message.values = new Map(Object.entries(data.values));
            }
            if (data.productSettings != null) {
                message.productSettings = data.productSettings;
            }
            if (data.enabled != null) {
                message.enabled = data.enabled;
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.transactions != null) {
                message.transactions = data.transactions;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.lastError != null) {
                message.lastError = data.lastError;
            }
            if (data.dataPublicacao != null) {
                message.dataPublicacao = dependency_1.google.protobuf.Timestamp.fromObject(data.dataPublicacao);
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.originId != null) {
                message.originId = data.originId;
            }
            if (data.originName != null) {
                message.originName = data.originName;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                commercialName?: string;
                productInfoId?: string;
                productInfo?: ReturnType<typeof ProductInfo.prototype.toObject>;
                values?: {
                    [key: string]: string;
                };
                productSettings?: ProductSettings[];
                enabled?: boolean;
                status?: Status;
                transactions?: string[];
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                lastError?: string;
                dataPublicacao?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                tokenId?: string;
                originId?: string;
                originName?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.commercialName != null) {
                data.commercialName = this.commercialName;
            }
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.productInfo != null) {
                data.productInfo = this.productInfo.toObject();
            }
            if (this.values != null) {
                data.values = (Object.fromEntries)(this.values);
            }
            if (this.productSettings != null) {
                data.productSettings = this.productSettings;
            }
            if (this.enabled != null) {
                data.enabled = this.enabled;
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.transactions != null) {
                data.transactions = this.transactions;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.lastError != null) {
                data.lastError = this.lastError;
            }
            if (this.dataPublicacao != null) {
                data.dataPublicacao = this.dataPublicacao.toObject();
            }
            if (this.tokenId != null) {
                data.tokenId = this.tokenId;
            }
            if (this.originId != null) {
                data.originId = this.originId;
            }
            if (this.originName != null) {
                data.originName = this.originName;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.commercialName.length)
                writer.writeString(2, this.commercialName);
            if (this.productInfoId.length)
                writer.writeString(3, this.productInfoId);
            if (this.hasProductInfo)
                writer.writeMessage(4, this.productInfo, () => this.productInfo.serialize(writer));
            for (const [key, value] of this.values) {
                writer.writeMessage(5, this.values, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (this.productSettings.length)
                writer.writePackedEnum(6, this.productSettings);
            if (this.enabled != false)
                writer.writeBool(7, this.enabled);
            if (this.status != Status.PRODUCT_STATUS_UNSPECIFIED)
                writer.writeEnum(8, this.status);
            if (this.transactions.length)
                writer.writeRepeatedString(9, this.transactions);
            if (this.hasCreatedAt)
                writer.writeMessage(10, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.lastError.length)
                writer.writeString(11, this.lastError);
            if (this.hasDataPublicacao)
                writer.writeMessage(12, this.dataPublicacao, () => this.dataPublicacao.serialize(writer));
            if (this.tokenId.length)
                writer.writeString(13, this.tokenId);
            if (this.originId.length)
                writer.writeString(14, this.originId);
            if (this.originName.length)
                writer.writeString(15, this.originName);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Product {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Product();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.commercialName = reader.readString();
                        break;
                    case 3:
                        message.productInfoId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.productInfo, () => message.productInfo = ProductInfo.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.values as any, reader, reader.readString, reader.readString));
                        break;
                    case 6:
                        message.productSettings = reader.readPackedEnum();
                        break;
                    case 7:
                        message.enabled = reader.readBool();
                        break;
                    case 8:
                        message.status = reader.readEnum();
                        break;
                    case 9:
                        pb_1.Message.addToRepeatedField(message, 9, reader.readString());
                        break;
                    case 10:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 11:
                        message.lastError = reader.readString();
                        break;
                    case 12:
                        reader.readMessage(message.dataPublicacao, () => message.dataPublicacao = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 13:
                        message.tokenId = reader.readString();
                        break;
                    case 14:
                        message.originId = reader.readString();
                        break;
                    case 15:
                        message.originName = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Product {
            return Product.deserialize(bytes);
        }
    }
    export class ProductCategory extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            name?: string;
            displayName?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("displayName" in data && data.displayName != undefined) {
                    this.displayName = data.displayName;
                }
            }
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get displayName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set displayName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            name?: string;
            displayName?: string;
        }): ProductCategory {
            const message = new ProductCategory({});
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.displayName != null) {
                message.displayName = data.displayName;
            }
            return message;
        }
        toObject() {
            const data: {
                name?: string;
                displayName?: string;
            } = {};
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.displayName != null) {
                data.displayName = this.displayName;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.name.length)
                writer.writeString(1, this.name);
            if (this.displayName.length)
                writer.writeString(2, this.displayName);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProductCategory {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProductCategory();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.name = reader.readString();
                        break;
                    case 2:
                        message.displayName = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ProductCategory {
            return ProductCategory.deserialize(bytes);
        }
    }
    export class SectionsProductInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productInfoId?: string;
            sections?: Section[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("sections" in data && data.sections != undefined) {
                    this.sections = data.sections;
                }
            }
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get sections() {
            return pb_1.Message.getRepeatedWrapperField(this, Section, 5) as Section[];
        }
        set sections(value: Section[]) {
            pb_1.Message.setRepeatedWrapperField(this, 5, value);
        }
        static fromObject(data: {
            productInfoId?: string;
            sections?: ReturnType<typeof Section.prototype.toObject>[];
        }): SectionsProductInfo {
            const message = new SectionsProductInfo({});
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.sections != null) {
                message.sections = data.sections.map(item => Section.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                productInfoId?: string;
                sections?: ReturnType<typeof Section.prototype.toObject>[];
            } = {};
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.sections != null) {
                data.sections = this.sections.map((item: Section) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productInfoId.length)
                writer.writeString(1, this.productInfoId);
            if (this.sections.length)
                writer.writeRepeatedMessage(5, this.sections, (item: Section) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SectionsProductInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SectionsProductInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productInfoId = reader.readString();
                        break;
                    case 5:
                        reader.readMessage(message.sections, () => pb_1.Message.addToRepeatedWrapperField(message, 5, Section.deserialize(reader), Section));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SectionsProductInfo {
            return SectionsProductInfo.deserialize(bytes);
        }
    }
    export class ProductInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            name?: string;
            displayName?: string;
            category?: ProductCategory;
            parameters?: Map<string, ProductParameter>;
            transactions?: dependency_2.core.transaction.v1.Transaction[];
            smartContractAddress?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [9], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("displayName" in data && data.displayName != undefined) {
                    this.displayName = data.displayName;
                }
                if ("category" in data && data.category != undefined) {
                    this.category = data.category;
                }
                if ("parameters" in data && data.parameters != undefined) {
                    this.parameters = data.parameters;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
                if ("smartContractAddress" in data && data.smartContractAddress != undefined) {
                    this.smartContractAddress = data.smartContractAddress;
                }
            }
            if (!this.parameters)
                this.parameters = new Map();
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get displayName() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set displayName(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get category() {
            return pb_1.Message.getWrapperField(this, ProductCategory, 4) as ProductCategory;
        }
        set category(value: ProductCategory) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasCategory() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get parameters() {
            return pb_1.Message.getField(this, 5) as any as Map<string, ProductParameter>;
        }
        set parameters(value: Map<string, ProductParameter>) {
            pb_1.Message.setField(this, 5, value as any);
        }
        get transactions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.transaction.v1.Transaction, 9) as dependency_2.core.transaction.v1.Transaction[];
        }
        set transactions(value: dependency_2.core.transaction.v1.Transaction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 9, value);
        }
        get smartContractAddress() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set smartContractAddress(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        static fromObject(data: {
            id?: string;
            name?: string;
            displayName?: string;
            category?: ReturnType<typeof ProductCategory.prototype.toObject>;
            parameters?: {
                [key: string]: ReturnType<typeof ProductParameter.prototype.toObject>;
            };
            transactions?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>[];
            smartContractAddress?: string;
        }): ProductInfo {
            const message = new ProductInfo({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.displayName != null) {
                message.displayName = data.displayName;
            }
            if (data.category != null) {
                message.category = ProductCategory.fromObject(data.category);
            }
            if (typeof data.parameters == "object") {
                message.parameters = new Map(Object.entries(data.parameters).map(([key, value]) => [key, ProductParameter.fromObject(value)]));
            }
            if (data.transactions != null) {
                message.transactions = data.transactions.map(item => dependency_2.core.transaction.v1.Transaction.fromObject(item));
            }
            if (data.smartContractAddress != null) {
                message.smartContractAddress = data.smartContractAddress;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                name?: string;
                displayName?: string;
                category?: ReturnType<typeof ProductCategory.prototype.toObject>;
                parameters?: {
                    [key: string]: ReturnType<typeof ProductParameter.prototype.toObject>;
                };
                transactions?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>[];
                smartContractAddress?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.displayName != null) {
                data.displayName = this.displayName;
            }
            if (this.category != null) {
                data.category = this.category.toObject();
            }
            if (this.parameters != null) {
                data.parameters = (Object.fromEntries)((Array.from)(this.parameters).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.transactions != null) {
                data.transactions = this.transactions.map((item: dependency_2.core.transaction.v1.Transaction) => item.toObject());
            }
            if (this.smartContractAddress != null) {
                data.smartContractAddress = this.smartContractAddress;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.displayName.length)
                writer.writeString(3, this.displayName);
            if (this.hasCategory)
                writer.writeMessage(4, this.category, () => this.category.serialize(writer));
            for (const [key, value] of this.parameters) {
                writer.writeMessage(5, this.parameters, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.transactions.length)
                writer.writeRepeatedMessage(9, this.transactions, (item: dependency_2.core.transaction.v1.Transaction) => item.serialize(writer));
            if (this.smartContractAddress.length)
                writer.writeString(10, this.smartContractAddress);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProductInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProductInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.displayName = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.category, () => message.category = ProductCategory.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.parameters as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = ProductParameter.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 9:
                        reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 9, dependency_2.core.transaction.v1.Transaction.deserialize(reader), dependency_2.core.transaction.v1.Transaction));
                        break;
                    case 10:
                        message.smartContractAddress = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ProductInfo {
            return ProductInfo.deserialize(bytes);
        }
    }
    export class StubAttribute extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            value?: string;
            attributeType?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("attributeType" in data && data.attributeType != undefined) {
                    this.attributeType = data.attributeType;
                }
            }
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get attributeType() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set attributeType(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            value?: string;
            attributeType?: string;
        }): StubAttribute {
            const message = new StubAttribute({});
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.attributeType != null) {
                message.attributeType = data.attributeType;
            }
            return message;
        }
        toObject() {
            const data: {
                value?: string;
                attributeType?: string;
            } = {};
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.attributeType != null) {
                data.attributeType = this.attributeType;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.value.length)
                writer.writeString(2, this.value);
            if (this.attributeType.length)
                writer.writeString(3, this.attributeType);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StubAttribute {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StubAttribute();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 2:
                        message.value = reader.readString();
                        break;
                    case 3:
                        message.attributeType = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): StubAttribute {
            return StubAttribute.deserialize(bytes);
        }
    }
    export class ParameterTypeString extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            type?: ParameterType;
            typeString?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("typeString" in data && data.typeString != undefined) {
                    this.typeString = data.typeString;
                }
            }
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 1, ParameterType.unspecified) as ParameterType;
        }
        set type(value: ParameterType) {
            pb_1.Message.setField(this, 1, value);
        }
        get typeString() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set typeString(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            type?: ParameterType;
            typeString?: string;
        }): ParameterTypeString {
            const message = new ParameterTypeString({});
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.typeString != null) {
                message.typeString = data.typeString;
            }
            return message;
        }
        toObject() {
            const data: {
                type?: ParameterType;
                typeString?: string;
            } = {};
            if (this.type != null) {
                data.type = this.type;
            }
            if (this.typeString != null) {
                data.typeString = this.typeString;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.type != ParameterType.unspecified)
                writer.writeEnum(1, this.type);
            if (this.typeString.length)
                writer.writeString(2, this.typeString);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParameterTypeString {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParameterTypeString();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.type = reader.readEnum();
                        break;
                    case 2:
                        message.typeString = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ParameterTypeString {
            return ParameterTypeString.deserialize(bytes);
        }
    }
    export class ParameterTypeMapping extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            mapping?: ParameterTypeString[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("mapping" in data && data.mapping != undefined) {
                    this.mapping = data.mapping;
                }
            }
        }
        get mapping() {
            return pb_1.Message.getRepeatedWrapperField(this, ParameterTypeString, 1) as ParameterTypeString[];
        }
        set mapping(value: ParameterTypeString[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            mapping?: ReturnType<typeof ParameterTypeString.prototype.toObject>[];
        }): ParameterTypeMapping {
            const message = new ParameterTypeMapping({});
            if (data.mapping != null) {
                message.mapping = data.mapping.map(item => ParameterTypeString.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                mapping?: ReturnType<typeof ParameterTypeString.prototype.toObject>[];
            } = {};
            if (this.mapping != null) {
                data.mapping = this.mapping.map((item: ParameterTypeString) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.mapping.length)
                writer.writeRepeatedMessage(1, this.mapping, (item: ParameterTypeString) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParameterTypeMapping {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParameterTypeMapping();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.mapping, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ParameterTypeString.deserialize(reader), ParameterTypeString));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ParameterTypeMapping {
            return ParameterTypeMapping.deserialize(bytes);
        }
    }
    export class ProductParameter extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            type?: ParameterType;
            parameterKind?: ParameterKind;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("parameterKind" in data && data.parameterKind != undefined) {
                    this.parameterKind = data.parameterKind;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 2, ParameterType.unspecified) as ParameterType;
        }
        set type(value: ParameterType) {
            pb_1.Message.setField(this, 2, value);
        }
        get parameterKind() {
            return pb_1.Message.getFieldWithDefault(this, 3, ParameterKind.PARAMETER_KIND_UNSPECIFIED) as ParameterKind;
        }
        set parameterKind(value: ParameterKind) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            id?: string;
            type?: ParameterType;
            parameterKind?: ParameterKind;
        }): ProductParameter {
            const message = new ProductParameter({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.parameterKind != null) {
                message.parameterKind = data.parameterKind;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                type?: ParameterType;
                parameterKind?: ParameterKind;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.type != null) {
                data.type = this.type;
            }
            if (this.parameterKind != null) {
                data.parameterKind = this.parameterKind;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.type != ParameterType.unspecified)
                writer.writeEnum(2, this.type);
            if (this.parameterKind != ParameterKind.PARAMETER_KIND_UNSPECIFIED)
                writer.writeEnum(3, this.parameterKind);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProductParameter {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProductParameter();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.type = reader.readEnum();
                        break;
                    case 3:
                        message.parameterKind = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ProductParameter {
            return ProductParameter.deserialize(bytes);
        }
    }
    export class ParameterValue extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3, 4]];
        constructor(data?: any[] | ({
            parameterKind?: ParameterKind;
        } & (({
            stringValue?: string;
            boolValue?: never;
            dateValue?: never;
            numericValue?: never;
        } | {
            stringValue?: never;
            boolValue?: boolean;
            dateValue?: never;
            numericValue?: never;
        } | {
            stringValue?: never;
            boolValue?: never;
            dateValue?: dependency_1.google.protobuf.Timestamp;
            numericValue?: never;
        } | {
            stringValue?: never;
            boolValue?: never;
            dateValue?: never;
            numericValue?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("stringValue" in data && data.stringValue != undefined) {
                    this.stringValue = data.stringValue;
                }
                if ("boolValue" in data && data.boolValue != undefined) {
                    this.boolValue = data.boolValue;
                }
                if ("dateValue" in data && data.dateValue != undefined) {
                    this.dateValue = data.dateValue;
                }
                if ("numericValue" in data && data.numericValue != undefined) {
                    this.numericValue = data.numericValue;
                }
                if ("parameterKind" in data && data.parameterKind != undefined) {
                    this.parameterKind = data.parameterKind;
                }
            }
        }
        get stringValue() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set stringValue(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get hasStringValue() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get boolValue() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set boolValue(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get hasBoolValue() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get dateValue() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set dateValue(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get hasDateValue() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get numericValue() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set numericValue(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0], value);
        }
        get hasNumericValue() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get parameterKind() {
            return pb_1.Message.getFieldWithDefault(this, 5, ParameterKind.PARAMETER_KIND_UNSPECIFIED) as ParameterKind;
        }
        set parameterKind(value: ParameterKind) {
            pb_1.Message.setField(this, 5, value);
        }
        get value() {
            const cases: {
                [index: number]: "none" | "stringValue" | "boolValue" | "dateValue" | "numericValue";
            } = {
                0: "none",
                1: "stringValue",
                2: "boolValue",
                3: "dateValue",
                4: "numericValue"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4])];
        }
        static fromObject(data: {
            stringValue?: string;
            boolValue?: boolean;
            dateValue?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            numericValue?: number;
            parameterKind?: ParameterKind;
        }): ParameterValue {
            const message = new ParameterValue({});
            if (data.stringValue != null) {
                message.stringValue = data.stringValue;
            }
            if (data.boolValue != null) {
                message.boolValue = data.boolValue;
            }
            if (data.dateValue != null) {
                message.dateValue = dependency_1.google.protobuf.Timestamp.fromObject(data.dateValue);
            }
            if (data.numericValue != null) {
                message.numericValue = data.numericValue;
            }
            if (data.parameterKind != null) {
                message.parameterKind = data.parameterKind;
            }
            return message;
        }
        toObject() {
            const data: {
                stringValue?: string;
                boolValue?: boolean;
                dateValue?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                numericValue?: number;
                parameterKind?: ParameterKind;
            } = {};
            if (this.stringValue != null) {
                data.stringValue = this.stringValue;
            }
            if (this.boolValue != null) {
                data.boolValue = this.boolValue;
            }
            if (this.dateValue != null) {
                data.dateValue = this.dateValue.toObject();
            }
            if (this.numericValue != null) {
                data.numericValue = this.numericValue;
            }
            if (this.parameterKind != null) {
                data.parameterKind = this.parameterKind;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasStringValue)
                writer.writeString(1, this.stringValue);
            if (this.hasBoolValue)
                writer.writeBool(2, this.boolValue);
            if (this.hasDateValue)
                writer.writeMessage(3, this.dateValue, () => this.dateValue.serialize(writer));
            if (this.hasNumericValue)
                writer.writeDouble(4, this.numericValue);
            if (this.parameterKind != ParameterKind.PARAMETER_KIND_UNSPECIFIED)
                writer.writeEnum(5, this.parameterKind);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ParameterValue {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ParameterValue();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.stringValue = reader.readString();
                        break;
                    case 2:
                        message.boolValue = reader.readBool();
                        break;
                    case 3:
                        reader.readMessage(message.dateValue, () => message.dateValue = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        message.numericValue = reader.readDouble();
                        break;
                    case 5:
                        message.parameterKind = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ParameterValue {
            return ParameterValue.deserialize(bytes);
        }
    }
    export class Template extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            name?: string;
            displayName?: string;
            category?: ProductCategory;
            sections?: Section[];
            enabled?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("displayName" in data && data.displayName != undefined) {
                    this.displayName = data.displayName;
                }
                if ("category" in data && data.category != undefined) {
                    this.category = data.category;
                }
                if ("sections" in data && data.sections != undefined) {
                    this.sections = data.sections;
                }
                if ("enabled" in data && data.enabled != undefined) {
                    this.enabled = data.enabled;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get displayName() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set displayName(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get category() {
            return pb_1.Message.getWrapperField(this, ProductCategory, 4) as ProductCategory;
        }
        set category(value: ProductCategory) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasCategory() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get sections() {
            return pb_1.Message.getRepeatedWrapperField(this, Section, 5) as Section[];
        }
        set sections(value: Section[]) {
            pb_1.Message.setRepeatedWrapperField(this, 5, value);
        }
        get enabled() {
            return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
        }
        set enabled(value: boolean) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            id?: string;
            name?: string;
            displayName?: string;
            category?: ReturnType<typeof ProductCategory.prototype.toObject>;
            sections?: ReturnType<typeof Section.prototype.toObject>[];
            enabled?: boolean;
        }): Template {
            const message = new Template({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.displayName != null) {
                message.displayName = data.displayName;
            }
            if (data.category != null) {
                message.category = ProductCategory.fromObject(data.category);
            }
            if (data.sections != null) {
                message.sections = data.sections.map(item => Section.fromObject(item));
            }
            if (data.enabled != null) {
                message.enabled = data.enabled;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                name?: string;
                displayName?: string;
                category?: ReturnType<typeof ProductCategory.prototype.toObject>;
                sections?: ReturnType<typeof Section.prototype.toObject>[];
                enabled?: boolean;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.displayName != null) {
                data.displayName = this.displayName;
            }
            if (this.category != null) {
                data.category = this.category.toObject();
            }
            if (this.sections != null) {
                data.sections = this.sections.map((item: Section) => item.toObject());
            }
            if (this.enabled != null) {
                data.enabled = this.enabled;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.displayName.length)
                writer.writeString(3, this.displayName);
            if (this.hasCategory)
                writer.writeMessage(4, this.category, () => this.category.serialize(writer));
            if (this.sections.length)
                writer.writeRepeatedMessage(5, this.sections, (item: Section) => item.serialize(writer));
            if (this.enabled != false)
                writer.writeBool(6, this.enabled);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Template {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Template();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.displayName = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.category, () => message.category = ProductCategory.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.sections, () => pb_1.Message.addToRepeatedWrapperField(message, 5, Section.deserialize(reader), Section));
                        break;
                    case 6:
                        message.enabled = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Template {
            return Template.deserialize(bytes);
        }
    }
    export class Section extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            title?: string;
            rows?: Row[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("title" in data && data.title != undefined) {
                    this.title = data.title;
                }
                if ("rows" in data && data.rows != undefined) {
                    this.rows = data.rows;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get title() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set title(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get rows() {
            return pb_1.Message.getRepeatedWrapperField(this, Row, 3) as Row[];
        }
        set rows(value: Row[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            id?: string;
            title?: string;
            rows?: ReturnType<typeof Row.prototype.toObject>[];
        }): Section {
            const message = new Section({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.title != null) {
                message.title = data.title;
            }
            if (data.rows != null) {
                message.rows = data.rows.map(item => Row.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                title?: string;
                rows?: ReturnType<typeof Row.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.title != null) {
                data.title = this.title;
            }
            if (this.rows != null) {
                data.rows = this.rows.map((item: Row) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.title.length)
                writer.writeString(2, this.title);
            if (this.rows.length)
                writer.writeRepeatedMessage(3, this.rows, (item: Row) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Section {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Section();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.title = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.rows, () => pb_1.Message.addToRepeatedWrapperField(message, 3, Row.deserialize(reader), Row));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Section {
            return Section.deserialize(bytes);
        }
    }
    export class Row extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            inputs?: Input[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("inputs" in data && data.inputs != undefined) {
                    this.inputs = data.inputs;
                }
            }
        }
        get inputs() {
            return pb_1.Message.getRepeatedWrapperField(this, Input, 1) as Input[];
        }
        set inputs(value: Input[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            inputs?: ReturnType<typeof Input.prototype.toObject>[];
        }): Row {
            const message = new Row({});
            if (data.inputs != null) {
                message.inputs = data.inputs.map(item => Input.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                inputs?: ReturnType<typeof Input.prototype.toObject>[];
            } = {};
            if (this.inputs != null) {
                data.inputs = this.inputs.map((item: Input) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.inputs.length)
                writer.writeRepeatedMessage(1, this.inputs, (item: Input) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Row {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Row();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.inputs, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Input.deserialize(reader), Input));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Row {
            return Row.deserialize(bytes);
        }
    }
    export class Input extends pb_1.Message {
        #one_of_decls: number[][] = [[7, 8]];
        constructor(data?: any[] | ({
            id?: string;
            name?: string;
            displayName?: string;
            description?: string;
            type?: ParameterType;
            options?: Option[];
            order?: number;
            parameterKind?: ParameterKind;
            field?: string;
            placeholder?: string;
            required?: boolean;
            readOnly?: boolean;
            defaultValue?: string;
            min?: number;
            max?: number;
        } & (({
            stringValue?: string;
            doubleValue?: never;
        } | {
            stringValue?: never;
            doubleValue?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("displayName" in data && data.displayName != undefined) {
                    this.displayName = data.displayName;
                }
                if ("description" in data && data.description != undefined) {
                    this.description = data.description;
                }
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("options" in data && data.options != undefined) {
                    this.options = data.options;
                }
                if ("stringValue" in data && data.stringValue != undefined) {
                    this.stringValue = data.stringValue;
                }
                if ("doubleValue" in data && data.doubleValue != undefined) {
                    this.doubleValue = data.doubleValue;
                }
                if ("order" in data && data.order != undefined) {
                    this.order = data.order;
                }
                if ("parameterKind" in data && data.parameterKind != undefined) {
                    this.parameterKind = data.parameterKind;
                }
                if ("field" in data && data.field != undefined) {
                    this.field = data.field;
                }
                if ("placeholder" in data && data.placeholder != undefined) {
                    this.placeholder = data.placeholder;
                }
                if ("required" in data && data.required != undefined) {
                    this.required = data.required;
                }
                if ("readOnly" in data && data.readOnly != undefined) {
                    this.readOnly = data.readOnly;
                }
                if ("defaultValue" in data && data.defaultValue != undefined) {
                    this.defaultValue = data.defaultValue;
                }
                if ("min" in data && data.min != undefined) {
                    this.min = data.min;
                }
                if ("max" in data && data.max != undefined) {
                    this.max = data.max;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get displayName() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set displayName(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get description() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set description(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 5, ParameterType.unspecified) as ParameterType;
        }
        set type(value: ParameterType) {
            pb_1.Message.setField(this, 5, value);
        }
        get options() {
            return pb_1.Message.getRepeatedWrapperField(this, Option, 6) as Option[];
        }
        set options(value: Option[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get stringValue() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set stringValue(value: string) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[0], value);
        }
        get hasStringValue() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get doubleValue() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set doubleValue(value: number) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[0], value);
        }
        get hasDoubleValue() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get order() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set order(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get parameterKind() {
            return pb_1.Message.getFieldWithDefault(this, 10, ParameterKind.PARAMETER_KIND_UNSPECIFIED) as ParameterKind;
        }
        set parameterKind(value: ParameterKind) {
            pb_1.Message.setField(this, 10, value);
        }
        get field() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set field(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        get placeholder() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set placeholder(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get required() {
            return pb_1.Message.getFieldWithDefault(this, 13, false) as boolean;
        }
        set required(value: boolean) {
            pb_1.Message.setField(this, 13, value);
        }
        get readOnly() {
            return pb_1.Message.getFieldWithDefault(this, 14, false) as boolean;
        }
        set readOnly(value: boolean) {
            pb_1.Message.setField(this, 14, value);
        }
        get defaultValue() {
            return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
        }
        set defaultValue(value: string) {
            pb_1.Message.setField(this, 15, value);
        }
        get min() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set min(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        get max() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set max(value: number) {
            pb_1.Message.setField(this, 17, value);
        }
        get value() {
            const cases: {
                [index: number]: "none" | "stringValue" | "doubleValue";
            } = {
                0: "none",
                7: "stringValue",
                8: "doubleValue"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7, 8])];
        }
        static fromObject(data: {
            id?: string;
            name?: string;
            displayName?: string;
            description?: string;
            type?: ParameterType;
            options?: ReturnType<typeof Option.prototype.toObject>[];
            stringValue?: string;
            doubleValue?: number;
            order?: number;
            parameterKind?: ParameterKind;
            field?: string;
            placeholder?: string;
            required?: boolean;
            readOnly?: boolean;
            defaultValue?: string;
            min?: number;
            max?: number;
        }): Input {
            const message = new Input({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.displayName != null) {
                message.displayName = data.displayName;
            }
            if (data.description != null) {
                message.description = data.description;
            }
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.options != null) {
                message.options = data.options.map(item => Option.fromObject(item));
            }
            if (data.stringValue != null) {
                message.stringValue = data.stringValue;
            }
            if (data.doubleValue != null) {
                message.doubleValue = data.doubleValue;
            }
            if (data.order != null) {
                message.order = data.order;
            }
            if (data.parameterKind != null) {
                message.parameterKind = data.parameterKind;
            }
            if (data.field != null) {
                message.field = data.field;
            }
            if (data.placeholder != null) {
                message.placeholder = data.placeholder;
            }
            if (data.required != null) {
                message.required = data.required;
            }
            if (data.readOnly != null) {
                message.readOnly = data.readOnly;
            }
            if (data.defaultValue != null) {
                message.defaultValue = data.defaultValue;
            }
            if (data.min != null) {
                message.min = data.min;
            }
            if (data.max != null) {
                message.max = data.max;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                name?: string;
                displayName?: string;
                description?: string;
                type?: ParameterType;
                options?: ReturnType<typeof Option.prototype.toObject>[];
                stringValue?: string;
                doubleValue?: number;
                order?: number;
                parameterKind?: ParameterKind;
                field?: string;
                placeholder?: string;
                required?: boolean;
                readOnly?: boolean;
                defaultValue?: string;
                min?: number;
                max?: number;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.displayName != null) {
                data.displayName = this.displayName;
            }
            if (this.description != null) {
                data.description = this.description;
            }
            if (this.type != null) {
                data.type = this.type;
            }
            if (this.options != null) {
                data.options = this.options.map((item: Option) => item.toObject());
            }
            if (this.stringValue != null) {
                data.stringValue = this.stringValue;
            }
            if (this.doubleValue != null) {
                data.doubleValue = this.doubleValue;
            }
            if (this.order != null) {
                data.order = this.order;
            }
            if (this.parameterKind != null) {
                data.parameterKind = this.parameterKind;
            }
            if (this.field != null) {
                data.field = this.field;
            }
            if (this.placeholder != null) {
                data.placeholder = this.placeholder;
            }
            if (this.required != null) {
                data.required = this.required;
            }
            if (this.readOnly != null) {
                data.readOnly = this.readOnly;
            }
            if (this.defaultValue != null) {
                data.defaultValue = this.defaultValue;
            }
            if (this.min != null) {
                data.min = this.min;
            }
            if (this.max != null) {
                data.max = this.max;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.displayName.length)
                writer.writeString(3, this.displayName);
            if (this.description.length)
                writer.writeString(4, this.description);
            if (this.type != ParameterType.unspecified)
                writer.writeEnum(5, this.type);
            if (this.options.length)
                writer.writeRepeatedMessage(6, this.options, (item: Option) => item.serialize(writer));
            if (this.hasStringValue)
                writer.writeString(7, this.stringValue);
            if (this.hasDoubleValue)
                writer.writeDouble(8, this.doubleValue);
            if (this.order != 0)
                writer.writeInt32(9, this.order);
            if (this.parameterKind != ParameterKind.PARAMETER_KIND_UNSPECIFIED)
                writer.writeEnum(10, this.parameterKind);
            if (this.field.length)
                writer.writeString(11, this.field);
            if (this.placeholder.length)
                writer.writeString(12, this.placeholder);
            if (this.required != false)
                writer.writeBool(13, this.required);
            if (this.readOnly != false)
                writer.writeBool(14, this.readOnly);
            if (this.defaultValue.length)
                writer.writeString(15, this.defaultValue);
            if (this.min != 0)
                writer.writeInt32(16, this.min);
            if (this.max != 0)
                writer.writeInt32(17, this.max);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Input {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Input();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.displayName = reader.readString();
                        break;
                    case 4:
                        message.description = reader.readString();
                        break;
                    case 5:
                        message.type = reader.readEnum();
                        break;
                    case 6:
                        reader.readMessage(message.options, () => pb_1.Message.addToRepeatedWrapperField(message, 6, Option.deserialize(reader), Option));
                        break;
                    case 7:
                        message.stringValue = reader.readString();
                        break;
                    case 8:
                        message.doubleValue = reader.readDouble();
                        break;
                    case 9:
                        message.order = reader.readInt32();
                        break;
                    case 10:
                        message.parameterKind = reader.readEnum();
                        break;
                    case 11:
                        message.field = reader.readString();
                        break;
                    case 12:
                        message.placeholder = reader.readString();
                        break;
                    case 13:
                        message.required = reader.readBool();
                        break;
                    case 14:
                        message.readOnly = reader.readBool();
                        break;
                    case 15:
                        message.defaultValue = reader.readString();
                        break;
                    case 16:
                        message.min = reader.readInt32();
                        break;
                    case 17:
                        message.max = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Input {
            return Input.deserialize(bytes);
        }
    }
    export class Option extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            label?: string;
            value?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("label" in data && data.label != undefined) {
                    this.label = data.label;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
        }
        get label() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set label(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            label?: string;
            value?: string;
        }): Option {
            const message = new Option({});
            if (data.label != null) {
                message.label = data.label;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: {
                label?: string;
                value?: string;
            } = {};
            if (this.label != null) {
                data.label = this.label;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.label.length)
                writer.writeString(1, this.label);
            if (this.value.length)
                writer.writeString(2, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Option {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Option();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.label = reader.readString();
                        break;
                    case 2:
                        message.value = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Option {
            return Option.deserialize(bytes);
        }
    }
    export class ProductTransferTemplate extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            productInfoId?: string;
            id?: string;
            name?: string;
            displayName?: string;
            sections?: Section[];
            enabled?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("displayName" in data && data.displayName != undefined) {
                    this.displayName = data.displayName;
                }
                if ("sections" in data && data.sections != undefined) {
                    this.sections = data.sections;
                }
                if ("enabled" in data && data.enabled != undefined) {
                    this.enabled = data.enabled;
                }
            }
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get displayName() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set displayName(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get sections() {
            return pb_1.Message.getRepeatedWrapperField(this, Section, 5) as Section[];
        }
        set sections(value: Section[]) {
            pb_1.Message.setRepeatedWrapperField(this, 5, value);
        }
        get enabled() {
            return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
        }
        set enabled(value: boolean) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            productInfoId?: string;
            id?: string;
            name?: string;
            displayName?: string;
            sections?: ReturnType<typeof Section.prototype.toObject>[];
            enabled?: boolean;
        }): ProductTransferTemplate {
            const message = new ProductTransferTemplate({});
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.displayName != null) {
                message.displayName = data.displayName;
            }
            if (data.sections != null) {
                message.sections = data.sections.map(item => Section.fromObject(item));
            }
            if (data.enabled != null) {
                message.enabled = data.enabled;
            }
            return message;
        }
        toObject() {
            const data: {
                productInfoId?: string;
                id?: string;
                name?: string;
                displayName?: string;
                sections?: ReturnType<typeof Section.prototype.toObject>[];
                enabled?: boolean;
            } = {};
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.displayName != null) {
                data.displayName = this.displayName;
            }
            if (this.sections != null) {
                data.sections = this.sections.map((item: Section) => item.toObject());
            }
            if (this.enabled != null) {
                data.enabled = this.enabled;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.productInfoId.length)
                writer.writeString(1, this.productInfoId);
            if (this.id.length)
                writer.writeString(2, this.id);
            if (this.name.length)
                writer.writeString(3, this.name);
            if (this.displayName.length)
                writer.writeString(4, this.displayName);
            if (this.sections.length)
                writer.writeRepeatedMessage(5, this.sections, (item: Section) => item.serialize(writer));
            if (this.enabled != false)
                writer.writeBool(6, this.enabled);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ProductTransferTemplate {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ProductTransferTemplate();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.productInfoId = reader.readString();
                        break;
                    case 2:
                        message.id = reader.readString();
                        break;
                    case 3:
                        message.name = reader.readString();
                        break;
                    case 4:
                        message.displayName = reader.readString();
                        break;
                    case 5:
                        reader.readMessage(message.sections, () => pb_1.Message.addToRepeatedWrapperField(message, 5, Section.deserialize(reader), Section));
                        break;
                    case 6:
                        message.enabled = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ProductTransferTemplate {
            return ProductTransferTemplate.deserialize(bytes);
        }
    }
}
