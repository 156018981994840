/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/account/v1/account_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/api/annotations";
import * as dependency_2 from "./account";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.account.v1 {
    export enum CreateCustomerStep {
        CREATE_CUSTOMER_UNSPECIFIED = 0,
        CREATE_CUSTOMER_INITIAL = 1,
        CREATE_CUSTOMER_FINISH = 2
    }
    export class GetManagementRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            document?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
            }
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            document?: string;
        }): GetManagementRequest {
            const message = new GetManagementRequest({});
            if (data.document != null) {
                message.document = data.document;
            }
            return message;
        }
        toObject() {
            const data: {
                document?: string;
            } = {};
            if (this.document != null) {
                data.document = this.document;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.document.length)
                writer.writeString(1, this.document);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetManagementRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetManagementRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.document = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetManagementRequest {
            return GetManagementRequest.deserialize(bytes);
        }
    }
    export class GetManagementResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            management?: dependency_2.core.account.v1.Management;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("management" in data && data.management != undefined) {
                    this.management = data.management;
                }
            }
        }
        get management() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Management, 1) as dependency_2.core.account.v1.Management;
        }
        set management(value: dependency_2.core.account.v1.Management) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasManagement() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
        }): GetManagementResponse {
            const message = new GetManagementResponse({});
            if (data.management != null) {
                message.management = dependency_2.core.account.v1.Management.fromObject(data.management);
            }
            return message;
        }
        toObject() {
            const data: {
                management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
            } = {};
            if (this.management != null) {
                data.management = this.management.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasManagement)
                writer.writeMessage(1, this.management, () => this.management.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetManagementResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetManagementResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.management, () => message.management = dependency_2.core.account.v1.Management.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetManagementResponse {
            return GetManagementResponse.deserialize(bytes);
        }
    }
    export class CreateManagementRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            management?: dependency_2.core.account.v1.Management;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("management" in data && data.management != undefined) {
                    this.management = data.management;
                }
            }
        }
        get management() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Management, 1) as dependency_2.core.account.v1.Management;
        }
        set management(value: dependency_2.core.account.v1.Management) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasManagement() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
        }): CreateManagementRequest {
            const message = new CreateManagementRequest({});
            if (data.management != null) {
                message.management = dependency_2.core.account.v1.Management.fromObject(data.management);
            }
            return message;
        }
        toObject() {
            const data: {
                management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
            } = {};
            if (this.management != null) {
                data.management = this.management.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasManagement)
                writer.writeMessage(1, this.management, () => this.management.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateManagementRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateManagementRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.management, () => message.management = dependency_2.core.account.v1.Management.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateManagementRequest {
            return CreateManagementRequest.deserialize(bytes);
        }
    }
    export class CreateManagementResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            management?: dependency_2.core.account.v1.Management;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("management" in data && data.management != undefined) {
                    this.management = data.management;
                }
            }
        }
        get management() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Management, 1) as dependency_2.core.account.v1.Management;
        }
        set management(value: dependency_2.core.account.v1.Management) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasManagement() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
        }): CreateManagementResponse {
            const message = new CreateManagementResponse({});
            if (data.management != null) {
                message.management = dependency_2.core.account.v1.Management.fromObject(data.management);
            }
            return message;
        }
        toObject() {
            const data: {
                management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
            } = {};
            if (this.management != null) {
                data.management = this.management.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasManagement)
                writer.writeMessage(1, this.management, () => this.management.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateManagementResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateManagementResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.management, () => message.management = dependency_2.core.account.v1.Management.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateManagementResponse {
            return CreateManagementResponse.deserialize(bytes);
        }
    }
    export class ListManagementRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            lastCnpj?: string;
            limit?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("lastCnpj" in data && data.lastCnpj != undefined) {
                    this.lastCnpj = data.lastCnpj;
                }
                if ("limit" in data && data.limit != undefined) {
                    this.limit = data.limit;
                }
            }
        }
        get lastCnpj() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set lastCnpj(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get limit() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set limit(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            lastCnpj?: string;
            limit?: number;
        }): ListManagementRequest {
            const message = new ListManagementRequest({});
            if (data.lastCnpj != null) {
                message.lastCnpj = data.lastCnpj;
            }
            if (data.limit != null) {
                message.limit = data.limit;
            }
            return message;
        }
        toObject() {
            const data: {
                lastCnpj?: string;
                limit?: number;
            } = {};
            if (this.lastCnpj != null) {
                data.lastCnpj = this.lastCnpj;
            }
            if (this.limit != null) {
                data.limit = this.limit;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.lastCnpj.length)
                writer.writeString(1, this.lastCnpj);
            if (this.limit != 0)
                writer.writeInt64(2, this.limit);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListManagementRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListManagementRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.lastCnpj = reader.readString();
                        break;
                    case 2:
                        message.limit = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListManagementRequest {
            return ListManagementRequest.deserialize(bytes);
        }
    }
    export class ListManagementResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            managements?: dependency_2.core.account.v1.Management[];
            totalPages?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("managements" in data && data.managements != undefined) {
                    this.managements = data.managements;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
            }
        }
        get managements() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.account.v1.Management, 1) as dependency_2.core.account.v1.Management[];
        }
        set managements(value: dependency_2.core.account.v1.Management[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            managements?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>[];
            totalPages?: number;
        }): ListManagementResponse {
            const message = new ListManagementResponse({});
            if (data.managements != null) {
                message.managements = data.managements.map(item => dependency_2.core.account.v1.Management.fromObject(item));
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            return message;
        }
        toObject() {
            const data: {
                managements?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>[];
                totalPages?: number;
            } = {};
            if (this.managements != null) {
                data.managements = this.managements.map((item: dependency_2.core.account.v1.Management) => item.toObject());
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.managements.length)
                writer.writeRepeatedMessage(1, this.managements, (item: dependency_2.core.account.v1.Management) => item.serialize(writer));
            if (this.totalPages != 0)
                writer.writeInt64(2, this.totalPages);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListManagementResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListManagementResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.managements, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.core.account.v1.Management.deserialize(reader), dependency_2.core.account.v1.Management));
                        break;
                    case 2:
                        message.totalPages = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListManagementResponse {
            return ListManagementResponse.deserialize(bytes);
        }
    }
    export class UpdateManagementRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            management?: dependency_2.core.account.v1.Management;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("management" in data && data.management != undefined) {
                    this.management = data.management;
                }
            }
        }
        get management() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Management, 1) as dependency_2.core.account.v1.Management;
        }
        set management(value: dependency_2.core.account.v1.Management) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasManagement() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
        }): UpdateManagementRequest {
            const message = new UpdateManagementRequest({});
            if (data.management != null) {
                message.management = dependency_2.core.account.v1.Management.fromObject(data.management);
            }
            return message;
        }
        toObject() {
            const data: {
                management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
            } = {};
            if (this.management != null) {
                data.management = this.management.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasManagement)
                writer.writeMessage(1, this.management, () => this.management.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateManagementRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateManagementRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.management, () => message.management = dependency_2.core.account.v1.Management.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateManagementRequest {
            return UpdateManagementRequest.deserialize(bytes);
        }
    }
    export class UpdateManagementResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            management?: dependency_2.core.account.v1.Management;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("management" in data && data.management != undefined) {
                    this.management = data.management;
                }
            }
        }
        get management() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Management, 1) as dependency_2.core.account.v1.Management;
        }
        set management(value: dependency_2.core.account.v1.Management) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasManagement() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
        }): UpdateManagementResponse {
            const message = new UpdateManagementResponse({});
            if (data.management != null) {
                message.management = dependency_2.core.account.v1.Management.fromObject(data.management);
            }
            return message;
        }
        toObject() {
            const data: {
                management?: ReturnType<typeof dependency_2.core.account.v1.Management.prototype.toObject>;
            } = {};
            if (this.management != null) {
                data.management = this.management.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasManagement)
                writer.writeMessage(1, this.management, () => this.management.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateManagementResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateManagementResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.management, () => message.management = dependency_2.core.account.v1.Management.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateManagementResponse {
            return UpdateManagementResponse.deserialize(bytes);
        }
    }
    export class DeleteManagementRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            cnpj?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("cnpj" in data && data.cnpj != undefined) {
                    this.cnpj = data.cnpj;
                }
            }
        }
        get cnpj() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set cnpj(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            cnpj?: string;
        }): DeleteManagementRequest {
            const message = new DeleteManagementRequest({});
            if (data.cnpj != null) {
                message.cnpj = data.cnpj;
            }
            return message;
        }
        toObject() {
            const data: {
                cnpj?: string;
            } = {};
            if (this.cnpj != null) {
                data.cnpj = this.cnpj;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.cnpj.length)
                writer.writeString(1, this.cnpj);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteManagementRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteManagementRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.cnpj = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteManagementRequest {
            return DeleteManagementRequest.deserialize(bytes);
        }
    }
    export class DeleteManagementResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): DeleteManagementResponse {
            const message = new DeleteManagementResponse({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteManagementResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteManagementResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteManagementResponse {
            return DeleteManagementResponse.deserialize(bytes);
        }
    }
    export class GetTermsOfServiceRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetTermsOfServiceRequest {
            const message = new GetTermsOfServiceRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTermsOfServiceRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTermsOfServiceRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTermsOfServiceRequest {
            return GetTermsOfServiceRequest.deserialize(bytes);
        }
    }
    export class GetTermsOfServiceResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            lastUpdateDate?: string;
            termsHtml?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("lastUpdateDate" in data && data.lastUpdateDate != undefined) {
                    this.lastUpdateDate = data.lastUpdateDate;
                }
                if ("termsHtml" in data && data.termsHtml != undefined) {
                    this.termsHtml = data.termsHtml;
                }
            }
        }
        get lastUpdateDate() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set lastUpdateDate(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get termsHtml() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set termsHtml(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            lastUpdateDate?: string;
            termsHtml?: string;
        }): GetTermsOfServiceResponse {
            const message = new GetTermsOfServiceResponse({});
            if (data.lastUpdateDate != null) {
                message.lastUpdateDate = data.lastUpdateDate;
            }
            if (data.termsHtml != null) {
                message.termsHtml = data.termsHtml;
            }
            return message;
        }
        toObject() {
            const data: {
                lastUpdateDate?: string;
                termsHtml?: string;
            } = {};
            if (this.lastUpdateDate != null) {
                data.lastUpdateDate = this.lastUpdateDate;
            }
            if (this.termsHtml != null) {
                data.termsHtml = this.termsHtml;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.lastUpdateDate.length)
                writer.writeString(1, this.lastUpdateDate);
            if (this.termsHtml.length)
                writer.writeString(2, this.termsHtml);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTermsOfServiceResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTermsOfServiceResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.lastUpdateDate = reader.readString();
                        break;
                    case 2:
                        message.termsHtml = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTermsOfServiceResponse {
            return GetTermsOfServiceResponse.deserialize(bytes);
        }
    }
    export class ClientApi extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            clientName?: string;
            clientId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("clientName" in data && data.clientName != undefined) {
                    this.clientName = data.clientName;
                }
                if ("clientId" in data && data.clientId != undefined) {
                    this.clientId = data.clientId;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get clientName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set clientName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get clientId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set clientId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            companyId?: string;
            clientName?: string;
            clientId?: string;
        }): ClientApi {
            const message = new ClientApi({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.clientName != null) {
                message.clientName = data.clientName;
            }
            if (data.clientId != null) {
                message.clientId = data.clientId;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                clientName?: string;
                clientId?: string;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.clientName != null) {
                data.clientName = this.clientName;
            }
            if (this.clientId != null) {
                data.clientId = this.clientId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.clientName.length)
                writer.writeString(2, this.clientName);
            if (this.clientId.length)
                writer.writeString(3, this.clientId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClientApi {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClientApi();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.clientName = reader.readString();
                        break;
                    case 3:
                        message.clientId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ClientApi {
            return ClientApi.deserialize(bytes);
        }
    }
    export class CreateClientAppRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            clientApi?: ClientApi;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("clientApi" in data && data.clientApi != undefined) {
                    this.clientApi = data.clientApi;
                }
            }
        }
        get clientApi() {
            return pb_1.Message.getWrapperField(this, ClientApi, 1) as ClientApi;
        }
        set clientApi(value: ClientApi) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasClientApi() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            clientApi?: ReturnType<typeof ClientApi.prototype.toObject>;
        }): CreateClientAppRequest {
            const message = new CreateClientAppRequest({});
            if (data.clientApi != null) {
                message.clientApi = ClientApi.fromObject(data.clientApi);
            }
            return message;
        }
        toObject() {
            const data: {
                clientApi?: ReturnType<typeof ClientApi.prototype.toObject>;
            } = {};
            if (this.clientApi != null) {
                data.clientApi = this.clientApi.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasClientApi)
                writer.writeMessage(1, this.clientApi, () => this.clientApi.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateClientAppRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateClientAppRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.clientApi, () => message.clientApi = ClientApi.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateClientAppRequest {
            return CreateClientAppRequest.deserialize(bytes);
        }
    }
    export class CreateClientAppResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            clientId?: string;
            clientSecrect?: string;
            companyId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("clientId" in data && data.clientId != undefined) {
                    this.clientId = data.clientId;
                }
                if ("clientSecrect" in data && data.clientSecrect != undefined) {
                    this.clientSecrect = data.clientSecrect;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
            }
        }
        get clientId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set clientId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get clientSecrect() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set clientSecrect(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            clientId?: string;
            clientSecrect?: string;
            companyId?: string;
        }): CreateClientAppResponse {
            const message = new CreateClientAppResponse({});
            if (data.clientId != null) {
                message.clientId = data.clientId;
            }
            if (data.clientSecrect != null) {
                message.clientSecrect = data.clientSecrect;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            return message;
        }
        toObject() {
            const data: {
                clientId?: string;
                clientSecrect?: string;
                companyId?: string;
            } = {};
            if (this.clientId != null) {
                data.clientId = this.clientId;
            }
            if (this.clientSecrect != null) {
                data.clientSecrect = this.clientSecrect;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.clientId.length)
                writer.writeString(1, this.clientId);
            if (this.clientSecrect.length)
                writer.writeString(2, this.clientSecrect);
            if (this.companyId.length)
                writer.writeString(3, this.companyId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateClientAppResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateClientAppResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.clientId = reader.readString();
                        break;
                    case 2:
                        message.clientSecrect = reader.readString();
                        break;
                    case 3:
                        message.companyId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateClientAppResponse {
            return CreateClientAppResponse.deserialize(bytes);
        }
    }
    export class ListClientAppRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            page?: number;
            limit?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("limit" in data && data.limit != undefined) {
                    this.limit = data.limit;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get limit() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set limit(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            companyId?: string;
            page?: number;
            limit?: number;
        }): ListClientAppRequest {
            const message = new ListClientAppRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.limit != null) {
                message.limit = data.limit;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                page?: number;
                limit?: number;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.limit != null) {
                data.limit = this.limit;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.page != 0)
                writer.writeInt64(2, this.page);
            if (this.limit != 0)
                writer.writeInt64(3, this.limit);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListClientAppRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListClientAppRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.page = reader.readInt64();
                        break;
                    case 3:
                        message.limit = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListClientAppRequest {
            return ListClientAppRequest.deserialize(bytes);
        }
    }
    export class ListClientAppResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            clientsApis?: ClientApi[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("clientsApis" in data && data.clientsApis != undefined) {
                    this.clientsApis = data.clientsApis;
                }
            }
        }
        get clientsApis() {
            return pb_1.Message.getRepeatedWrapperField(this, ClientApi, 2) as ClientApi[];
        }
        set clientsApis(value: ClientApi[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            clientsApis?: ReturnType<typeof ClientApi.prototype.toObject>[];
        }): ListClientAppResponse {
            const message = new ListClientAppResponse({});
            if (data.clientsApis != null) {
                message.clientsApis = data.clientsApis.map(item => ClientApi.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                clientsApis?: ReturnType<typeof ClientApi.prototype.toObject>[];
            } = {};
            if (this.clientsApis != null) {
                data.clientsApis = this.clientsApis.map((item: ClientApi) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.clientsApis.length)
                writer.writeRepeatedMessage(2, this.clientsApis, (item: ClientApi) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListClientAppResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListClientAppResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 2:
                        reader.readMessage(message.clientsApis, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ClientApi.deserialize(reader), ClientApi));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListClientAppResponse {
            return ListClientAppResponse.deserialize(bytes);
        }
    }
    export class DeleteClientAppRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            clientId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("clientId" in data && data.clientId != undefined) {
                    this.clientId = data.clientId;
                }
            }
        }
        get clientId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set clientId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            clientId?: string;
        }): DeleteClientAppRequest {
            const message = new DeleteClientAppRequest({});
            if (data.clientId != null) {
                message.clientId = data.clientId;
            }
            return message;
        }
        toObject() {
            const data: {
                clientId?: string;
            } = {};
            if (this.clientId != null) {
                data.clientId = this.clientId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.clientId.length)
                writer.writeString(1, this.clientId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteClientAppRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteClientAppRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.clientId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteClientAppRequest {
            return DeleteClientAppRequest.deserialize(bytes);
        }
    }
    export class DeleteClientAppResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): DeleteClientAppResponse {
            const message = new DeleteClientAppResponse({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteClientAppResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteClientAppResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteClientAppResponse {
            return DeleteClientAppResponse.deserialize(bytes);
        }
    }
    export class GetUserRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            username?: string;
            email?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
            }
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            username?: string;
            email?: string;
        }): GetUserRequest {
            const message = new GetUserRequest({});
            if (data.username != null) {
                message.username = data.username;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            return message;
        }
        toObject() {
            const data: {
                username?: string;
                email?: string;
            } = {};
            if (this.username != null) {
                data.username = this.username;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.username.length)
                writer.writeString(1, this.username);
            if (this.email.length)
                writer.writeString(2, this.email);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetUserRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetUserRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.username = reader.readString();
                        break;
                    case 2:
                        message.email = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetUserRequest {
            return GetUserRequest.deserialize(bytes);
        }
    }
    export class GetUserResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            user?: dependency_2.core.account.v1.User;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("user" in data && data.user != undefined) {
                    this.user = data.user;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get user() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.User, 3) as dependency_2.core.account.v1.User;
        }
        set user(value: dependency_2.core.account.v1.User) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasUser() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
        }): GetUserResponse {
            const message = new GetUserResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.user != null) {
                message.user = dependency_2.core.account.v1.User.fromObject(data.user);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.user != null) {
                data.user = this.user.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasUser)
                writer.writeMessage(3, this.user, () => this.user.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetUserResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetUserResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.user, () => message.user = dependency_2.core.account.v1.User.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetUserResponse {
            return GetUserResponse.deserialize(bytes);
        }
    }
    export class UpdateUserRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            user?: dependency_2.core.account.v1.User;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("user" in data && data.user != undefined) {
                    this.user = data.user;
                }
            }
        }
        get user() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.User, 1) as dependency_2.core.account.v1.User;
        }
        set user(value: dependency_2.core.account.v1.User) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasUser() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
        }): UpdateUserRequest {
            const message = new UpdateUserRequest({});
            if (data.user != null) {
                message.user = dependency_2.core.account.v1.User.fromObject(data.user);
            }
            return message;
        }
        toObject() {
            const data: {
                user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
            } = {};
            if (this.user != null) {
                data.user = this.user.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasUser)
                writer.writeMessage(1, this.user, () => this.user.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateUserRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateUserRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.user, () => message.user = dependency_2.core.account.v1.User.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateUserRequest {
            return UpdateUserRequest.deserialize(bytes);
        }
    }
    export class UpdateUserResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            user?: dependency_2.core.account.v1.User;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("user" in data && data.user != undefined) {
                    this.user = data.user;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get user() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.User, 3) as dependency_2.core.account.v1.User;
        }
        set user(value: dependency_2.core.account.v1.User) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasUser() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
        }): UpdateUserResponse {
            const message = new UpdateUserResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.user != null) {
                message.user = dependency_2.core.account.v1.User.fromObject(data.user);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.user != null) {
                data.user = this.user.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasUser)
                writer.writeMessage(3, this.user, () => this.user.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateUserResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateUserResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.user, () => message.user = dependency_2.core.account.v1.User.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateUserResponse {
            return UpdateUserResponse.deserialize(bytes);
        }
    }
    export class DeleteUserRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            username?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
            }
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            username?: string;
        }): DeleteUserRequest {
            const message = new DeleteUserRequest({});
            if (data.username != null) {
                message.username = data.username;
            }
            return message;
        }
        toObject() {
            const data: {
                username?: string;
            } = {};
            if (this.username != null) {
                data.username = this.username;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.username.length)
                writer.writeString(1, this.username);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteUserRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteUserRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.username = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteUserRequest {
            return DeleteUserRequest.deserialize(bytes);
        }
    }
    export class DeleteUserResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): DeleteUserResponse {
            const message = new DeleteUserResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteUserResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteUserResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteUserResponse {
            return DeleteUserResponse.deserialize(bytes);
        }
    }
    export class CreateUserRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            user?: dependency_2.core.account.v1.User;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("user" in data && data.user != undefined) {
                    this.user = data.user;
                }
            }
        }
        get user() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.User, 1) as dependency_2.core.account.v1.User;
        }
        set user(value: dependency_2.core.account.v1.User) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasUser() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
        }): CreateUserRequest {
            const message = new CreateUserRequest({});
            if (data.user != null) {
                message.user = dependency_2.core.account.v1.User.fromObject(data.user);
            }
            return message;
        }
        toObject() {
            const data: {
                user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
            } = {};
            if (this.user != null) {
                data.user = this.user.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasUser)
                writer.writeMessage(1, this.user, () => this.user.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateUserRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateUserRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.user, () => message.user = dependency_2.core.account.v1.User.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateUserRequest {
            return CreateUserRequest.deserialize(bytes);
        }
    }
    export class CreateCustomerRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            customer?: dependency_2.core.account.v1.Customer;
            password?: string;
            createCustomerStep?: CreateCustomerStep;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("customer" in data && data.customer != undefined) {
                    this.customer = data.customer;
                }
                if ("password" in data && data.password != undefined) {
                    this.password = data.password;
                }
                if ("createCustomerStep" in data && data.createCustomerStep != undefined) {
                    this.createCustomerStep = data.createCustomerStep;
                }
            }
        }
        get customer() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Customer, 1) as dependency_2.core.account.v1.Customer;
        }
        set customer(value: dependency_2.core.account.v1.Customer) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasCustomer() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get password() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set password(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get createCustomerStep() {
            return pb_1.Message.getFieldWithDefault(this, 3, CreateCustomerStep.CREATE_CUSTOMER_UNSPECIFIED) as CreateCustomerStep;
        }
        set createCustomerStep(value: CreateCustomerStep) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            customer?: ReturnType<typeof dependency_2.core.account.v1.Customer.prototype.toObject>;
            password?: string;
            createCustomerStep?: CreateCustomerStep;
        }): CreateCustomerRequest {
            const message = new CreateCustomerRequest({});
            if (data.customer != null) {
                message.customer = dependency_2.core.account.v1.Customer.fromObject(data.customer);
            }
            if (data.password != null) {
                message.password = data.password;
            }
            if (data.createCustomerStep != null) {
                message.createCustomerStep = data.createCustomerStep;
            }
            return message;
        }
        toObject() {
            const data: {
                customer?: ReturnType<typeof dependency_2.core.account.v1.Customer.prototype.toObject>;
                password?: string;
                createCustomerStep?: CreateCustomerStep;
            } = {};
            if (this.customer != null) {
                data.customer = this.customer.toObject();
            }
            if (this.password != null) {
                data.password = this.password;
            }
            if (this.createCustomerStep != null) {
                data.createCustomerStep = this.createCustomerStep;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasCustomer)
                writer.writeMessage(1, this.customer, () => this.customer.serialize(writer));
            if (this.password.length)
                writer.writeString(2, this.password);
            if (this.createCustomerStep != CreateCustomerStep.CREATE_CUSTOMER_UNSPECIFIED)
                writer.writeEnum(3, this.createCustomerStep);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateCustomerRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateCustomerRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.customer, () => message.customer = dependency_2.core.account.v1.Customer.deserialize(reader));
                        break;
                    case 2:
                        message.password = reader.readString();
                        break;
                    case 3:
                        message.createCustomerStep = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateCustomerRequest {
            return CreateCustomerRequest.deserialize(bytes);
        }
    }
    export class CreateUserResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            user?: dependency_2.core.account.v1.User;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("user" in data && data.user != undefined) {
                    this.user = data.user;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get user() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.User, 3) as dependency_2.core.account.v1.User;
        }
        set user(value: dependency_2.core.account.v1.User) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasUser() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
        }): CreateUserResponse {
            const message = new CreateUserResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.user != null) {
                message.user = dependency_2.core.account.v1.User.fromObject(data.user);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                user?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.user != null) {
                data.user = this.user.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasUser)
                writer.writeMessage(3, this.user, () => this.user.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateUserResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateUserResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.user, () => message.user = dependency_2.core.account.v1.User.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateUserResponse {
            return CreateUserResponse.deserialize(bytes);
        }
    }
    export class CreateCustomerResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            customer?: dependency_2.core.account.v1.Customer;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("customer" in data && data.customer != undefined) {
                    this.customer = data.customer;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get customer() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Customer, 3) as dependency_2.core.account.v1.Customer;
        }
        set customer(value: dependency_2.core.account.v1.Customer) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCustomer() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            customer?: ReturnType<typeof dependency_2.core.account.v1.Customer.prototype.toObject>;
        }): CreateCustomerResponse {
            const message = new CreateCustomerResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.customer != null) {
                message.customer = dependency_2.core.account.v1.Customer.fromObject(data.customer);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                customer?: ReturnType<typeof dependency_2.core.account.v1.Customer.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.customer != null) {
                data.customer = this.customer.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasCustomer)
                writer.writeMessage(3, this.customer, () => this.customer.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateCustomerResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateCustomerResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.customer, () => message.customer = dependency_2.core.account.v1.Customer.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateCustomerResponse {
            return CreateCustomerResponse.deserialize(bytes);
        }
    }
    export class UpdateUserEmailRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            username?: string;
            currentEmail?: string;
            newEmail?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
                if ("currentEmail" in data && data.currentEmail != undefined) {
                    this.currentEmail = data.currentEmail;
                }
                if ("newEmail" in data && data.newEmail != undefined) {
                    this.newEmail = data.newEmail;
                }
            }
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get currentEmail() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set currentEmail(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get newEmail() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set newEmail(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            username?: string;
            currentEmail?: string;
            newEmail?: string;
        }): UpdateUserEmailRequest {
            const message = new UpdateUserEmailRequest({});
            if (data.username != null) {
                message.username = data.username;
            }
            if (data.currentEmail != null) {
                message.currentEmail = data.currentEmail;
            }
            if (data.newEmail != null) {
                message.newEmail = data.newEmail;
            }
            return message;
        }
        toObject() {
            const data: {
                username?: string;
                currentEmail?: string;
                newEmail?: string;
            } = {};
            if (this.username != null) {
                data.username = this.username;
            }
            if (this.currentEmail != null) {
                data.currentEmail = this.currentEmail;
            }
            if (this.newEmail != null) {
                data.newEmail = this.newEmail;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.username.length)
                writer.writeString(1, this.username);
            if (this.currentEmail.length)
                writer.writeString(2, this.currentEmail);
            if (this.newEmail.length)
                writer.writeString(3, this.newEmail);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateUserEmailRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateUserEmailRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.username = reader.readString();
                        break;
                    case 2:
                        message.currentEmail = reader.readString();
                        break;
                    case 3:
                        message.newEmail = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateUserEmailRequest {
            return UpdateUserEmailRequest.deserialize(bytes);
        }
    }
    export class UpdateUserEmailResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            success?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("success" in data && data.success != undefined) {
                    this.success = data.success;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get success() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set success(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            success?: boolean;
            message?: string;
        }): UpdateUserEmailResponse {
            const message = new UpdateUserEmailResponse({});
            if (data.success != null) {
                message.success = data.success;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                success?: boolean;
                message?: string;
            } = {};
            if (this.success != null) {
                data.success = this.success;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.success != false)
                writer.writeBool(1, this.success);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateUserEmailResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateUserEmailResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.success = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateUserEmailResponse {
            return UpdateUserEmailResponse.deserialize(bytes);
        }
    }
    export class ListUsersRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            page?: number;
            itemsPerPage?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            companyId?: string;
            page?: number;
            itemsPerPage?: number;
        }): ListUsersRequest {
            const message = new ListUsersRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                page?: number;
                itemsPerPage?: number;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.page != 0)
                writer.writeInt32(2, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt32(3, this.itemsPerPage);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListUsersRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListUsersRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.page = reader.readInt32();
                        break;
                    case 3:
                        message.itemsPerPage = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListUsersRequest {
            return ListUsersRequest.deserialize(bytes);
        }
    }
    export class ListUsersResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            users?: dependency_2.core.account.v1.User[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
                if ("users" in data && data.users != undefined) {
                    this.users = data.users;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get users() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.account.v1.User, 6) as dependency_2.core.account.v1.User[];
        }
        set users(value: dependency_2.core.account.v1.User[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            users?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>[];
        }): ListUsersResponse {
            const message = new ListUsersResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            if (data.users != null) {
                message.users = data.users.map(item => dependency_2.core.account.v1.User.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
                users?: ReturnType<typeof dependency_2.core.account.v1.User.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            if (this.users != null) {
                data.users = this.users.map((item: dependency_2.core.account.v1.User) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.currentPage != 0)
                writer.writeInt32(3, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(4, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt32(5, this.totalItems);
            if (this.users.length)
                writer.writeRepeatedMessage(6, this.users, (item: dependency_2.core.account.v1.User) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListUsersResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListUsersResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.currentPage = reader.readInt32();
                        break;
                    case 4:
                        message.totalPages = reader.readInt32();
                        break;
                    case 5:
                        message.totalItems = reader.readInt32();
                        break;
                    case 6:
                        reader.readMessage(message.users, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_2.core.account.v1.User.deserialize(reader), dependency_2.core.account.v1.User));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListUsersResponse {
            return ListUsersResponse.deserialize(bytes);
        }
    }
    export class CreateCompanyRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            company?: dependency_2.core.account.v1.Company;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("company" in data && data.company != undefined) {
                    this.company = data.company;
                }
            }
        }
        get company() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Company, 1) as dependency_2.core.account.v1.Company;
        }
        set company(value: dependency_2.core.account.v1.Company) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasCompany() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
        }): CreateCompanyRequest {
            const message = new CreateCompanyRequest({});
            if (data.company != null) {
                message.company = dependency_2.core.account.v1.Company.fromObject(data.company);
            }
            return message;
        }
        toObject() {
            const data: {
                company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
            } = {};
            if (this.company != null) {
                data.company = this.company.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasCompany)
                writer.writeMessage(1, this.company, () => this.company.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateCompanyRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateCompanyRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.company, () => message.company = dependency_2.core.account.v1.Company.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateCompanyRequest {
            return CreateCompanyRequest.deserialize(bytes);
        }
    }
    export class CreateCompanyResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            company?: dependency_2.core.account.v1.Company;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("company" in data && data.company != undefined) {
                    this.company = data.company;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get company() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Company, 3) as dependency_2.core.account.v1.Company;
        }
        set company(value: dependency_2.core.account.v1.Company) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCompany() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
        }): CreateCompanyResponse {
            const message = new CreateCompanyResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.company != null) {
                message.company = dependency_2.core.account.v1.Company.fromObject(data.company);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.company != null) {
                data.company = this.company.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasCompany)
                writer.writeMessage(3, this.company, () => this.company.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateCompanyResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateCompanyResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.company, () => message.company = dependency_2.core.account.v1.Company.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateCompanyResponse {
            return CreateCompanyResponse.deserialize(bytes);
        }
    }
    export class GetCompanyRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            document?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            id?: string;
            document?: string;
        }): GetCompanyRequest {
            const message = new GetCompanyRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.document != null) {
                message.document = data.document;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                document?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.document != null) {
                data.document = this.document;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.document.length)
                writer.writeString(2, this.document);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetCompanyRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetCompanyRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.document = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetCompanyRequest {
            return GetCompanyRequest.deserialize(bytes);
        }
    }
    export class GetCompanyResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            company?: dependency_2.core.account.v1.Company;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("company" in data && data.company != undefined) {
                    this.company = data.company;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get company() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Company, 3) as dependency_2.core.account.v1.Company;
        }
        set company(value: dependency_2.core.account.v1.Company) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCompany() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
        }): GetCompanyResponse {
            const message = new GetCompanyResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.company != null) {
                message.company = dependency_2.core.account.v1.Company.fromObject(data.company);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.company != null) {
                data.company = this.company.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasCompany)
                writer.writeMessage(3, this.company, () => this.company.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetCompanyResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetCompanyResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.company, () => message.company = dependency_2.core.account.v1.Company.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetCompanyResponse {
            return GetCompanyResponse.deserialize(bytes);
        }
    }
    export class ListCompaniesRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            page?: number;
            itemsPerPage?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
            }
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            page?: number;
            itemsPerPage?: number;
        }): ListCompaniesRequest {
            const message = new ListCompaniesRequest({});
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            return message;
        }
        toObject() {
            const data: {
                page?: number;
                itemsPerPage?: number;
            } = {};
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.page != 0)
                writer.writeInt32(1, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt32(2, this.itemsPerPage);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListCompaniesRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListCompaniesRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.page = reader.readInt32();
                        break;
                    case 2:
                        message.itemsPerPage = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListCompaniesRequest {
            return ListCompaniesRequest.deserialize(bytes);
        }
    }
    export class ListCompaniesResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            companies?: dependency_2.core.account.v1.Company[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
                if ("companies" in data && data.companies != undefined) {
                    this.companies = data.companies;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get companies() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.account.v1.Company, 6) as dependency_2.core.account.v1.Company[];
        }
        set companies(value: dependency_2.core.account.v1.Company[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
            companies?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>[];
        }): ListCompaniesResponse {
            const message = new ListCompaniesResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            if (data.companies != null) {
                message.companies = data.companies.map(item => dependency_2.core.account.v1.Company.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
                companies?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            if (this.companies != null) {
                data.companies = this.companies.map((item: dependency_2.core.account.v1.Company) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.currentPage != 0)
                writer.writeInt32(3, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(4, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt32(5, this.totalItems);
            if (this.companies.length)
                writer.writeRepeatedMessage(6, this.companies, (item: dependency_2.core.account.v1.Company) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListCompaniesResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListCompaniesResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        message.currentPage = reader.readInt32();
                        break;
                    case 4:
                        message.totalPages = reader.readInt32();
                        break;
                    case 5:
                        message.totalItems = reader.readInt32();
                        break;
                    case 6:
                        reader.readMessage(message.companies, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_2.core.account.v1.Company.deserialize(reader), dependency_2.core.account.v1.Company));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListCompaniesResponse {
            return ListCompaniesResponse.deserialize(bytes);
        }
    }
    export class UpdateCompanyRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            company?: dependency_2.core.account.v1.Company;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("company" in data && data.company != undefined) {
                    this.company = data.company;
                }
            }
        }
        get company() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Company, 1) as dependency_2.core.account.v1.Company;
        }
        set company(value: dependency_2.core.account.v1.Company) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasCompany() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
        }): UpdateCompanyRequest {
            const message = new UpdateCompanyRequest({});
            if (data.company != null) {
                message.company = dependency_2.core.account.v1.Company.fromObject(data.company);
            }
            return message;
        }
        toObject() {
            const data: {
                company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
            } = {};
            if (this.company != null) {
                data.company = this.company.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasCompany)
                writer.writeMessage(1, this.company, () => this.company.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateCompanyRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateCompanyRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.company, () => message.company = dependency_2.core.account.v1.Company.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateCompanyRequest {
            return UpdateCompanyRequest.deserialize(bytes);
        }
    }
    export class UpdateCompanyResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            company?: dependency_2.core.account.v1.Company;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("company" in data && data.company != undefined) {
                    this.company = data.company;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get company() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.account.v1.Company, 3) as dependency_2.core.account.v1.Company;
        }
        set company(value: dependency_2.core.account.v1.Company) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCompany() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
        }): UpdateCompanyResponse {
            const message = new UpdateCompanyResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.company != null) {
                message.company = dependency_2.core.account.v1.Company.fromObject(data.company);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                company?: ReturnType<typeof dependency_2.core.account.v1.Company.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.company != null) {
                data.company = this.company.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasCompany)
                writer.writeMessage(3, this.company, () => this.company.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateCompanyResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateCompanyResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.company, () => message.company = dependency_2.core.account.v1.Company.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): UpdateCompanyResponse {
            return UpdateCompanyResponse.deserialize(bytes);
        }
    }
    export class DeleteCompanyRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            document?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            companyId?: string;
            document?: string;
        }): DeleteCompanyRequest {
            const message = new DeleteCompanyRequest({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.document != null) {
                message.document = data.document;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                document?: string;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.document != null) {
                data.document = this.document;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.document.length)
                writer.writeString(2, this.document);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteCompanyRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteCompanyRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.document = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteCompanyRequest {
            return DeleteCompanyRequest.deserialize(bytes);
        }
    }
    export class DeleteCompanyResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): DeleteCompanyResponse {
            const message = new DeleteCompanyResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DeleteCompanyResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DeleteCompanyResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DeleteCompanyResponse {
            return DeleteCompanyResponse.deserialize(bytes);
        }
    }
    export class GenerateTemporaryPasswordRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            username?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
            }
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            username?: string;
        }): GenerateTemporaryPasswordRequest {
            const message = new GenerateTemporaryPasswordRequest({});
            if (data.username != null) {
                message.username = data.username;
            }
            return message;
        }
        toObject() {
            const data: {
                username?: string;
            } = {};
            if (this.username != null) {
                data.username = this.username;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.username.length)
                writer.writeString(1, this.username);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GenerateTemporaryPasswordRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GenerateTemporaryPasswordRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.username = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GenerateTemporaryPasswordRequest {
            return GenerateTemporaryPasswordRequest.deserialize(bytes);
        }
    }
    export class GenerateTemporaryPasswordResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): GenerateTemporaryPasswordResponse {
            const message = new GenerateTemporaryPasswordResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GenerateTemporaryPasswordResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GenerateTemporaryPasswordResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GenerateTemporaryPasswordResponse {
            return GenerateTemporaryPasswordResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedAccountServiceService {
        static definition = {
            UpdateEmail: {
                path: "/core.account.v1.AccountService/UpdateEmail",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateUserEmailRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateUserEmailRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateUserEmailResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateUserEmailResponse.deserialize(new Uint8Array(bytes))
            },
            ListUsers: {
                path: "/core.account.v1.AccountService/ListUsers",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListUsersRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListUsersRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListUsersResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListUsersResponse.deserialize(new Uint8Array(bytes))
            },
            GetUser: {
                path: "/core.account.v1.AccountService/GetUser",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetUserRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetUserRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetUserResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetUserResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateUser: {
                path: "/core.account.v1.AccountService/UpdateUser",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateUserRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateUserRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateUserResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateUserResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteUser: {
                path: "/core.account.v1.AccountService/DeleteUser",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteUserRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteUserRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteUserResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteUserResponse.deserialize(new Uint8Array(bytes))
            },
            CreateUser: {
                path: "/core.account.v1.AccountService/CreateUser",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateUserRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateUserRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateUserResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateUserResponse.deserialize(new Uint8Array(bytes))
            },
            CreateCustomer: {
                path: "/core.account.v1.AccountService/CreateCustomer",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateCustomerRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateCustomerRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateCustomerResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateCustomerResponse.deserialize(new Uint8Array(bytes))
            },
            CreateCompany: {
                path: "/core.account.v1.AccountService/CreateCompany",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateCompanyRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateCompanyRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateCompanyResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateCompanyResponse.deserialize(new Uint8Array(bytes))
            },
            GetCompany: {
                path: "/core.account.v1.AccountService/GetCompany",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetCompanyRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetCompanyRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetCompanyResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetCompanyResponse.deserialize(new Uint8Array(bytes))
            },
            ListCompanies: {
                path: "/core.account.v1.AccountService/ListCompanies",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListCompaniesRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListCompaniesRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListCompaniesResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListCompaniesResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateCompany: {
                path: "/core.account.v1.AccountService/UpdateCompany",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateCompanyRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateCompanyRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateCompanyResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateCompanyResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteCompany: {
                path: "/core.account.v1.AccountService/DeleteCompany",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteCompanyRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteCompanyRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteCompanyResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteCompanyResponse.deserialize(new Uint8Array(bytes))
            },
            CreateClientApp: {
                path: "/core.account.v1.AccountService/CreateClientApp",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateClientAppRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateClientAppRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateClientAppResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateClientAppResponse.deserialize(new Uint8Array(bytes))
            },
            ListClientApp: {
                path: "/core.account.v1.AccountService/ListClientApp",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListClientAppRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListClientAppRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListClientAppResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListClientAppResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteClientApp: {
                path: "/core.account.v1.AccountService/DeleteClientApp",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteClientAppRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteClientAppRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteClientAppResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteClientAppResponse.deserialize(new Uint8Array(bytes))
            },
            GetTermsOfService: {
                path: "/core.account.v1.AccountService/GetTermsOfService",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTermsOfServiceRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTermsOfServiceRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTermsOfServiceResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTermsOfServiceResponse.deserialize(new Uint8Array(bytes))
            },
            GenerateTemporaryPassword: {
                path: "/core.account.v1.AccountService/GenerateTemporaryPassword",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GenerateTemporaryPasswordRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GenerateTemporaryPasswordRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GenerateTemporaryPasswordResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GenerateTemporaryPasswordResponse.deserialize(new Uint8Array(bytes))
            },
            CreateManagement: {
                path: "/core.account.v1.AccountService/CreateManagement",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateManagementRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateManagementRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateManagementResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateManagementResponse.deserialize(new Uint8Array(bytes))
            },
            ListManagement: {
                path: "/core.account.v1.AccountService/ListManagement",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListManagementRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListManagementRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListManagementResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListManagementResponse.deserialize(new Uint8Array(bytes))
            },
            UpdateManagement: {
                path: "/core.account.v1.AccountService/UpdateManagement",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: UpdateManagementRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => UpdateManagementRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: UpdateManagementResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => UpdateManagementResponse.deserialize(new Uint8Array(bytes))
            },
            DeleteManagement: {
                path: "/core.account.v1.AccountService/DeleteManagement",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: DeleteManagementRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => DeleteManagementRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: DeleteManagementResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => DeleteManagementResponse.deserialize(new Uint8Array(bytes))
            },
            GetManagement: {
                path: "/core.account.v1.AccountService/GetManagement",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetManagementRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetManagementRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetManagementResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetManagementResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract UpdateEmail(call: grpc_1.ServerUnaryCall<UpdateUserEmailRequest, UpdateUserEmailResponse>, callback: grpc_1.sendUnaryData<UpdateUserEmailResponse>): void;
        abstract ListUsers(call: grpc_1.ServerUnaryCall<ListUsersRequest, ListUsersResponse>, callback: grpc_1.sendUnaryData<ListUsersResponse>): void;
        abstract GetUser(call: grpc_1.ServerUnaryCall<GetUserRequest, GetUserResponse>, callback: grpc_1.sendUnaryData<GetUserResponse>): void;
        abstract UpdateUser(call: grpc_1.ServerUnaryCall<UpdateUserRequest, UpdateUserResponse>, callback: grpc_1.sendUnaryData<UpdateUserResponse>): void;
        abstract DeleteUser(call: grpc_1.ServerUnaryCall<DeleteUserRequest, DeleteUserResponse>, callback: grpc_1.sendUnaryData<DeleteUserResponse>): void;
        abstract CreateUser(call: grpc_1.ServerUnaryCall<CreateUserRequest, CreateUserResponse>, callback: grpc_1.sendUnaryData<CreateUserResponse>): void;
        abstract CreateCustomer(call: grpc_1.ServerUnaryCall<CreateCustomerRequest, CreateCustomerResponse>, callback: grpc_1.sendUnaryData<CreateCustomerResponse>): void;
        abstract CreateCompany(call: grpc_1.ServerUnaryCall<CreateCompanyRequest, CreateCompanyResponse>, callback: grpc_1.sendUnaryData<CreateCompanyResponse>): void;
        abstract GetCompany(call: grpc_1.ServerUnaryCall<GetCompanyRequest, GetCompanyResponse>, callback: grpc_1.sendUnaryData<GetCompanyResponse>): void;
        abstract ListCompanies(call: grpc_1.ServerUnaryCall<ListCompaniesRequest, ListCompaniesResponse>, callback: grpc_1.sendUnaryData<ListCompaniesResponse>): void;
        abstract UpdateCompany(call: grpc_1.ServerUnaryCall<UpdateCompanyRequest, UpdateCompanyResponse>, callback: grpc_1.sendUnaryData<UpdateCompanyResponse>): void;
        abstract DeleteCompany(call: grpc_1.ServerUnaryCall<DeleteCompanyRequest, DeleteCompanyResponse>, callback: grpc_1.sendUnaryData<DeleteCompanyResponse>): void;
        abstract CreateClientApp(call: grpc_1.ServerUnaryCall<CreateClientAppRequest, CreateClientAppResponse>, callback: grpc_1.sendUnaryData<CreateClientAppResponse>): void;
        abstract ListClientApp(call: grpc_1.ServerUnaryCall<ListClientAppRequest, ListClientAppResponse>, callback: grpc_1.sendUnaryData<ListClientAppResponse>): void;
        abstract DeleteClientApp(call: grpc_1.ServerUnaryCall<DeleteClientAppRequest, DeleteClientAppResponse>, callback: grpc_1.sendUnaryData<DeleteClientAppResponse>): void;
        abstract GetTermsOfService(call: grpc_1.ServerUnaryCall<GetTermsOfServiceRequest, GetTermsOfServiceResponse>, callback: grpc_1.sendUnaryData<GetTermsOfServiceResponse>): void;
        abstract GenerateTemporaryPassword(call: grpc_1.ServerUnaryCall<GenerateTemporaryPasswordRequest, GenerateTemporaryPasswordResponse>, callback: grpc_1.sendUnaryData<GenerateTemporaryPasswordResponse>): void;
        abstract CreateManagement(call: grpc_1.ServerUnaryCall<CreateManagementRequest, CreateManagementResponse>, callback: grpc_1.sendUnaryData<CreateManagementResponse>): void;
        abstract ListManagement(call: grpc_1.ServerUnaryCall<ListManagementRequest, ListManagementResponse>, callback: grpc_1.sendUnaryData<ListManagementResponse>): void;
        abstract UpdateManagement(call: grpc_1.ServerUnaryCall<UpdateManagementRequest, UpdateManagementResponse>, callback: grpc_1.sendUnaryData<UpdateManagementResponse>): void;
        abstract DeleteManagement(call: grpc_1.ServerUnaryCall<DeleteManagementRequest, DeleteManagementResponse>, callback: grpc_1.sendUnaryData<DeleteManagementResponse>): void;
        abstract GetManagement(call: grpc_1.ServerUnaryCall<GetManagementRequest, GetManagementResponse>, callback: grpc_1.sendUnaryData<GetManagementResponse>): void;
    }
    export class AccountServiceClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static UpdateEmail = new grpc_web_1.MethodDescriptor<UpdateUserEmailRequest, UpdateUserEmailResponse>("/core.account.v1.AccountService/UpdateEmail", grpc_web_1.MethodType.UNARY, UpdateUserEmailRequest, UpdateUserEmailResponse, (message: UpdateUserEmailRequest) => message.serialize(), UpdateUserEmailResponse.deserialize);
        UpdateEmail(message: UpdateUserEmailRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateUserEmailRequest, UpdateUserEmailResponse>(this._address + "/core.account.v1.AccountService/UpdateEmail", message, metadata || {}, AccountServiceClient.UpdateEmail);
        }
        private static ListUsers = new grpc_web_1.MethodDescriptor<ListUsersRequest, ListUsersResponse>("/core.account.v1.AccountService/ListUsers", grpc_web_1.MethodType.UNARY, ListUsersRequest, ListUsersResponse, (message: ListUsersRequest) => message.serialize(), ListUsersResponse.deserialize);
        ListUsers(message: ListUsersRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListUsersRequest, ListUsersResponse>(this._address + "/core.account.v1.AccountService/ListUsers", message, metadata || {}, AccountServiceClient.ListUsers);
        }
        private static GetUser = new grpc_web_1.MethodDescriptor<GetUserRequest, GetUserResponse>("/core.account.v1.AccountService/GetUser", grpc_web_1.MethodType.UNARY, GetUserRequest, GetUserResponse, (message: GetUserRequest) => message.serialize(), GetUserResponse.deserialize);
        GetUser(message: GetUserRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetUserRequest, GetUserResponse>(this._address + "/core.account.v1.AccountService/GetUser", message, metadata || {}, AccountServiceClient.GetUser);
        }
        private static UpdateUser = new grpc_web_1.MethodDescriptor<UpdateUserRequest, UpdateUserResponse>("/core.account.v1.AccountService/UpdateUser", grpc_web_1.MethodType.UNARY, UpdateUserRequest, UpdateUserResponse, (message: UpdateUserRequest) => message.serialize(), UpdateUserResponse.deserialize);
        UpdateUser(message: UpdateUserRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateUserRequest, UpdateUserResponse>(this._address + "/core.account.v1.AccountService/UpdateUser", message, metadata || {}, AccountServiceClient.UpdateUser);
        }
        private static DeleteUser = new grpc_web_1.MethodDescriptor<DeleteUserRequest, DeleteUserResponse>("/core.account.v1.AccountService/DeleteUser", grpc_web_1.MethodType.UNARY, DeleteUserRequest, DeleteUserResponse, (message: DeleteUserRequest) => message.serialize(), DeleteUserResponse.deserialize);
        DeleteUser(message: DeleteUserRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteUserRequest, DeleteUserResponse>(this._address + "/core.account.v1.AccountService/DeleteUser", message, metadata || {}, AccountServiceClient.DeleteUser);
        }
        private static CreateUser = new grpc_web_1.MethodDescriptor<CreateUserRequest, CreateUserResponse>("/core.account.v1.AccountService/CreateUser", grpc_web_1.MethodType.UNARY, CreateUserRequest, CreateUserResponse, (message: CreateUserRequest) => message.serialize(), CreateUserResponse.deserialize);
        CreateUser(message: CreateUserRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateUserRequest, CreateUserResponse>(this._address + "/core.account.v1.AccountService/CreateUser", message, metadata || {}, AccountServiceClient.CreateUser);
        }
        private static CreateCustomer = new grpc_web_1.MethodDescriptor<CreateCustomerRequest, CreateCustomerResponse>("/core.account.v1.AccountService/CreateCustomer", grpc_web_1.MethodType.UNARY, CreateCustomerRequest, CreateCustomerResponse, (message: CreateCustomerRequest) => message.serialize(), CreateCustomerResponse.deserialize);
        CreateCustomer(message: CreateCustomerRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateCustomerRequest, CreateCustomerResponse>(this._address + "/core.account.v1.AccountService/CreateCustomer", message, metadata || {}, AccountServiceClient.CreateCustomer);
        }
        private static CreateCompany = new grpc_web_1.MethodDescriptor<CreateCompanyRequest, CreateCompanyResponse>("/core.account.v1.AccountService/CreateCompany", grpc_web_1.MethodType.UNARY, CreateCompanyRequest, CreateCompanyResponse, (message: CreateCompanyRequest) => message.serialize(), CreateCompanyResponse.deserialize);
        CreateCompany(message: CreateCompanyRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateCompanyRequest, CreateCompanyResponse>(this._address + "/core.account.v1.AccountService/CreateCompany", message, metadata || {}, AccountServiceClient.CreateCompany);
        }
        private static GetCompany = new grpc_web_1.MethodDescriptor<GetCompanyRequest, GetCompanyResponse>("/core.account.v1.AccountService/GetCompany", grpc_web_1.MethodType.UNARY, GetCompanyRequest, GetCompanyResponse, (message: GetCompanyRequest) => message.serialize(), GetCompanyResponse.deserialize);
        GetCompany(message: GetCompanyRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetCompanyRequest, GetCompanyResponse>(this._address + "/core.account.v1.AccountService/GetCompany", message, metadata || {}, AccountServiceClient.GetCompany);
        }
        private static ListCompanies = new grpc_web_1.MethodDescriptor<ListCompaniesRequest, ListCompaniesResponse>("/core.account.v1.AccountService/ListCompanies", grpc_web_1.MethodType.UNARY, ListCompaniesRequest, ListCompaniesResponse, (message: ListCompaniesRequest) => message.serialize(), ListCompaniesResponse.deserialize);
        ListCompanies(message: ListCompaniesRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListCompaniesRequest, ListCompaniesResponse>(this._address + "/core.account.v1.AccountService/ListCompanies", message, metadata || {}, AccountServiceClient.ListCompanies);
        }
        private static UpdateCompany = new grpc_web_1.MethodDescriptor<UpdateCompanyRequest, UpdateCompanyResponse>("/core.account.v1.AccountService/UpdateCompany", grpc_web_1.MethodType.UNARY, UpdateCompanyRequest, UpdateCompanyResponse, (message: UpdateCompanyRequest) => message.serialize(), UpdateCompanyResponse.deserialize);
        UpdateCompany(message: UpdateCompanyRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateCompanyRequest, UpdateCompanyResponse>(this._address + "/core.account.v1.AccountService/UpdateCompany", message, metadata || {}, AccountServiceClient.UpdateCompany);
        }
        private static DeleteCompany = new grpc_web_1.MethodDescriptor<DeleteCompanyRequest, DeleteCompanyResponse>("/core.account.v1.AccountService/DeleteCompany", grpc_web_1.MethodType.UNARY, DeleteCompanyRequest, DeleteCompanyResponse, (message: DeleteCompanyRequest) => message.serialize(), DeleteCompanyResponse.deserialize);
        DeleteCompany(message: DeleteCompanyRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteCompanyRequest, DeleteCompanyResponse>(this._address + "/core.account.v1.AccountService/DeleteCompany", message, metadata || {}, AccountServiceClient.DeleteCompany);
        }
        private static CreateClientApp = new grpc_web_1.MethodDescriptor<CreateClientAppRequest, CreateClientAppResponse>("/core.account.v1.AccountService/CreateClientApp", grpc_web_1.MethodType.UNARY, CreateClientAppRequest, CreateClientAppResponse, (message: CreateClientAppRequest) => message.serialize(), CreateClientAppResponse.deserialize);
        CreateClientApp(message: CreateClientAppRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateClientAppRequest, CreateClientAppResponse>(this._address + "/core.account.v1.AccountService/CreateClientApp", message, metadata || {}, AccountServiceClient.CreateClientApp);
        }
        private static ListClientApp = new grpc_web_1.MethodDescriptor<ListClientAppRequest, ListClientAppResponse>("/core.account.v1.AccountService/ListClientApp", grpc_web_1.MethodType.UNARY, ListClientAppRequest, ListClientAppResponse, (message: ListClientAppRequest) => message.serialize(), ListClientAppResponse.deserialize);
        ListClientApp(message: ListClientAppRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListClientAppRequest, ListClientAppResponse>(this._address + "/core.account.v1.AccountService/ListClientApp", message, metadata || {}, AccountServiceClient.ListClientApp);
        }
        private static DeleteClientApp = new grpc_web_1.MethodDescriptor<DeleteClientAppRequest, DeleteClientAppResponse>("/core.account.v1.AccountService/DeleteClientApp", grpc_web_1.MethodType.UNARY, DeleteClientAppRequest, DeleteClientAppResponse, (message: DeleteClientAppRequest) => message.serialize(), DeleteClientAppResponse.deserialize);
        DeleteClientApp(message: DeleteClientAppRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteClientAppRequest, DeleteClientAppResponse>(this._address + "/core.account.v1.AccountService/DeleteClientApp", message, metadata || {}, AccountServiceClient.DeleteClientApp);
        }
        private static GetTermsOfService = new grpc_web_1.MethodDescriptor<GetTermsOfServiceRequest, GetTermsOfServiceResponse>("/core.account.v1.AccountService/GetTermsOfService", grpc_web_1.MethodType.UNARY, GetTermsOfServiceRequest, GetTermsOfServiceResponse, (message: GetTermsOfServiceRequest) => message.serialize(), GetTermsOfServiceResponse.deserialize);
        GetTermsOfService(message: GetTermsOfServiceRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTermsOfServiceRequest, GetTermsOfServiceResponse>(this._address + "/core.account.v1.AccountService/GetTermsOfService", message, metadata || {}, AccountServiceClient.GetTermsOfService);
        }
        private static GenerateTemporaryPassword = new grpc_web_1.MethodDescriptor<GenerateTemporaryPasswordRequest, GenerateTemporaryPasswordResponse>("/core.account.v1.AccountService/GenerateTemporaryPassword", grpc_web_1.MethodType.UNARY, GenerateTemporaryPasswordRequest, GenerateTemporaryPasswordResponse, (message: GenerateTemporaryPasswordRequest) => message.serialize(), GenerateTemporaryPasswordResponse.deserialize);
        GenerateTemporaryPassword(message: GenerateTemporaryPasswordRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GenerateTemporaryPasswordRequest, GenerateTemporaryPasswordResponse>(this._address + "/core.account.v1.AccountService/GenerateTemporaryPassword", message, metadata || {}, AccountServiceClient.GenerateTemporaryPassword);
        }
        private static CreateManagement = new grpc_web_1.MethodDescriptor<CreateManagementRequest, CreateManagementResponse>("/core.account.v1.AccountService/CreateManagement", grpc_web_1.MethodType.UNARY, CreateManagementRequest, CreateManagementResponse, (message: CreateManagementRequest) => message.serialize(), CreateManagementResponse.deserialize);
        CreateManagement(message: CreateManagementRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateManagementRequest, CreateManagementResponse>(this._address + "/core.account.v1.AccountService/CreateManagement", message, metadata || {}, AccountServiceClient.CreateManagement);
        }
        private static ListManagement = new grpc_web_1.MethodDescriptor<ListManagementRequest, ListManagementResponse>("/core.account.v1.AccountService/ListManagement", grpc_web_1.MethodType.UNARY, ListManagementRequest, ListManagementResponse, (message: ListManagementRequest) => message.serialize(), ListManagementResponse.deserialize);
        ListManagement(message: ListManagementRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListManagementRequest, ListManagementResponse>(this._address + "/core.account.v1.AccountService/ListManagement", message, metadata || {}, AccountServiceClient.ListManagement);
        }
        private static UpdateManagement = new grpc_web_1.MethodDescriptor<UpdateManagementRequest, UpdateManagementResponse>("/core.account.v1.AccountService/UpdateManagement", grpc_web_1.MethodType.UNARY, UpdateManagementRequest, UpdateManagementResponse, (message: UpdateManagementRequest) => message.serialize(), UpdateManagementResponse.deserialize);
        UpdateManagement(message: UpdateManagementRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<UpdateManagementRequest, UpdateManagementResponse>(this._address + "/core.account.v1.AccountService/UpdateManagement", message, metadata || {}, AccountServiceClient.UpdateManagement);
        }
        private static DeleteManagement = new grpc_web_1.MethodDescriptor<DeleteManagementRequest, DeleteManagementResponse>("/core.account.v1.AccountService/DeleteManagement", grpc_web_1.MethodType.UNARY, DeleteManagementRequest, DeleteManagementResponse, (message: DeleteManagementRequest) => message.serialize(), DeleteManagementResponse.deserialize);
        DeleteManagement(message: DeleteManagementRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<DeleteManagementRequest, DeleteManagementResponse>(this._address + "/core.account.v1.AccountService/DeleteManagement", message, metadata || {}, AccountServiceClient.DeleteManagement);
        }
        private static GetManagement = new grpc_web_1.MethodDescriptor<GetManagementRequest, GetManagementResponse>("/core.account.v1.AccountService/GetManagement", grpc_web_1.MethodType.UNARY, GetManagementRequest, GetManagementResponse, (message: GetManagementRequest) => message.serialize(), GetManagementResponse.deserialize);
        GetManagement(message: GetManagementRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetManagementRequest, GetManagementResponse>(this._address + "/core.account.v1.AccountService/GetManagement", message, metadata || {}, AccountServiceClient.GetManagement);
        }
    }
}
