import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Inject, Injectable } from "@angular/core";
import { removeSymbol } from "@libs/util/util";
import { Router } from "@angular/router";
import { User } from "@libs/models/user";
import { APP_NAME, MainService, NUCLEA_ID } from "@libs/main.service";
import { AppName } from "@libs/models/appName";

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  userPool;
  userAttributes;
  requiredAttributes;
  cognitoUser: CognitoUser;
  appName: AppName;
  constructor(public router: Router, public mainService: MainService, @Inject(APP_NAME) LOCAL_APP_NAME: AppName) {
    this.userPool = mainService.userPool;
    this.appName = LOCAL_APP_NAME;
  }

  auth(username: string, password: string): Promise<any> {
    const authenticationDetails = new AuthenticationDetails({ Username: username, Password: password });

    this.cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });

    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          localStorage.setItem("accessToken", result.getAccessToken().getJwtToken());
          resolve(result);
        },
        onFailure: (err) => reject(this.getErrorMessage(err.code)),
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.userAttributes = userAttributes;
          this.requiredAttributes = requiredAttributes;
          reject("new_password_required");
        },
      });
    });
  }

  async newPassword(newPassword: string): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        this.cognitoUser.completeNewPasswordChallenge(newPassword, this.requiredAttributes, {
          onSuccess: (result) => {
            localStorage.setItem("accessToken", result.getAccessToken().getJwtToken());
            resolve(result);
          },
          onFailure: (err) => {
            console.error("Error during completeNewPasswordChallenge: ", err);
            reject(this.getErrorMessage(err.code));
          },
        });
      });
    } catch (err) {
      console.error("Error during completeNewPasswordChallenge: ", err);
      throw this.getErrorMessage(err.code);
    }
  }
  
  signUp(user: User): Promise<any> {
    user.document = removeSymbol(user.document);
    const attributeList = [
      new CognitoUserAttribute({ Name: "name", Value: user.name ?? "" }),
      new CognitoUserAttribute({ Name: "email", Value: user.email ?? "" }),
      new CognitoUserAttribute({ Name: "preferred_username", Value: user.document ?? "" }),
      new CognitoUserAttribute({ Name: "custom:company_id", Value: user.companyId ?? "" }),
      new CognitoUserAttribute({ Name: "custom:first_access", Value: "0" }),
    ];

    if (this.appName === "ecotas") {
      attributeList.push(new CognitoUserAttribute({ Name: "custom:confirmed_bid", Value: "0" }));
      attributeList.push(new CognitoUserAttribute({ Name: "custom:accepted_terms_bid", Value: "0" }));
    }

    return new Promise((resolve, reject) => {
      this.userPool.signUp(user.document, user.password, attributeList, null, (err: any, result) => {
        if (err) {
          const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
          reject(errorMessage);
        } else {
          resolve(result.user);
        }
      });
    });
  }

  refreshSession(): Promise<any> {
    const cognitoUser = this.getCurrentUser();
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          localStorage.setItem("accessToken", session.getAccessToken().getJwtToken());
          resolve(session);
        }
      });
    });
  }

  confirmRegistration(username: string, code: string): Promise<any> {
    const cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
          reject(errorMessage);
        } else {
          resolve(result);
        }
      });
    });
  }

  resendConfirmationCode(email: string): Promise<any> {
    const cognitoUser = new CognitoUser({ Username: email, Pool: this.userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err: any, result) => {
        if (err) {
          const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
          reject(errorMessage);
        } else {
          resolve(result);
        }
      });
    });
  }

  getUser(): Promise<CognitoUserAttribute[]> {
    return new Promise((resolve, reject) => {
      this.getCurrentUser().getUserAttributes((err: any, result) => {
        if (err) {
          const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
          reject(errorMessage);
        } else {
          resolve(result);
        }
      });
    });
  }

  updateUser(user: any): Promise<any> {
    const currentUser = this.getCurrentUser();
    return this.ensureAuthenticated(currentUser).then(() => {
      const attributes = this.mapToCognitoUserAttribute(user);
      return new Promise((resolve, reject) => {
        currentUser.updateAttributes(attributes, (err: any, result) => {
          if (err) {
            const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
            reject(errorMessage);
          } else {
            currentUser.getUserAttributes((err, updatedUserAttributes) => {
              if (err) {
                reject(err.message);
              } else {
                resolve(updatedUserAttributes);
              }
            });
          }
        });
      });
    });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    const currentUser = this.getCurrentUser();
    return this.ensureAuthenticated(currentUser).then(() => {
      return new Promise((resolve, reject) => {
        currentUser.changePassword(oldPassword, newPassword, (err: any, result) => {
          if (err) {
            const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
            reject(errorMessage);
          } else {
            resolve(result);
          }
        });
      });
    });
  }

  deleteUser(): Promise<any> {
    const currentUser = this.getCurrentUser();
    return this.ensureAuthenticated(currentUser).then(() => {
      return new Promise((resolve, reject) => {
        currentUser.deleteUser((err: any, result) => {
          if (err) {
            const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
            reject(errorMessage);
          } else {
            localStorage.clear();
            this.router.navigate(["/login"]);
            resolve(result);
          }
        });
      });
    });
  }

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser();
      return this.ensureAuthenticated(currentUser)
        .then(() => {
          currentUser.signOut();
          localStorage.setItem("accessToken", null);
          localStorage.clear();
          this.router.navigate(["/login"]);
          resolve();
        })
        .catch(() => {
          localStorage.clear();
          this.router.navigate(["/login"]);
          reject("Nenhum usuário está logado no momento");
        });
    });
  }

  forgotPassword(email: string): Promise<any> {
    const cognitoUser = new CognitoUser({ Username: email, Pool: this.userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => resolve(result),
        onFailure: (err: any) => {
          const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
          reject(errorMessage);
        },
      });
    });
  }

  confirmPassword(email: string, newPassword: string, code: string): Promise<any> {
    const cognitoUser = new CognitoUser({ Username: email, Pool: this.userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (result) => resolve(result),
        onFailure: (err: any) => {
          const errorMessage = err.code ? this.getErrorMessage(err.code) : err.message;
          reject(errorMessage);
        },
      });
    });
  }

  getCurrentUser(): CognitoUser {
    return this.userPool.getCurrentUser();
  }

  getUserFromCognito(cognitoUser, session, attributes: CognitoUserAttribute[]): User {
    let user: User = {};
    attributes.forEach((attribute) => {
      if (attribute.getName() !== "preferred_username" && attribute.getName() !== "email_verified") {
        user[attribute.getName()] = attribute.getValue();
      }
    });
    user.username = cognitoUser.getUsername();
    user.preferred_username = user.username;
    user.document = user.username;
    user.id = user.sub;
    user.companyId = user["custom:company_id"];
    user.termsAcceptedAt = user["custom:terms_accepted_on"];
    if (!user.permissions) {
      user.permissions = {};
    }
    user.firstAccess = user["custom:first_access"] ?? "1";
    user.permissions.workflow = user["custom:workflow"] ?? "0";
    user.permissions.transfer = user["custom:transfer"] ?? "0";
    user.permissions.users = user["custom:users"] ?? "0";
    user.permissions.template = user["custom:template"] ?? "0";
    user.permissions.network = user["custom:network"] ?? "0";
    user.isAdmin = user.companyId === NUCLEA_ID;
    delete user["custom:first_access"];
    delete user["custom:company_id"];
    delete user["custom:terms_accepted_on"];
    delete user["custom:workflow"];
    delete user["custom:transfer"];
    delete user["custom:users"];
    delete user["custom:template"];
    delete user["custom:network"];
    localStorage.setItem("accessToken", session.getAccessToken().getJwtToken());
    return user;
  }

  private mapToCognitoUserAttribute(userAttributes: { [key: string]: any }): CognitoUserAttribute[] {
    return Object.entries(userAttributes).map(([attrName, value]) => {
      if (attrName === "termsAcceptedAt") {
        return new CognitoUserAttribute({ Name: "custom:terms_accepted_on", Value: value });
      }
      if (attrName === "companyId") {
        return new CognitoUserAttribute({ Name: "custom:company_id", Value: value });
      }
      if (attrName === "document") {
        return new CognitoUserAttribute({ Name: "preferred_username", Value: value });
      }
      if (attrName === "first_access") {
        return new CognitoUserAttribute({ Name: "custom:first_access", Value: value });
      }
      if (attrName === "worfklow") {
        return new CognitoUserAttribute({ Name: "custom:workflow", Value: value });
      }
      if (attrName === "transfer") {
        return new CognitoUserAttribute({ Name: "custom:transfer", Value: value });
      }
      if (attrName === "users") {
        return new CognitoUserAttribute({ Name: "custom:users", Value: value });
      }
      if (attrName === "template") {
        return new CognitoUserAttribute({ Name: "custom:template", Value: value });
      }
      if (attrName === "network") {
        return new CognitoUserAttribute({ Name: "custom:network", Value: value });
      }
      return new CognitoUserAttribute({ Name: attrName, Value: value });
    });
  }

  private ensureAuthenticated(user: CognitoUser): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!user) {
        this.router.navigate(["/login"]);
        reject("User is not authenticated");
      }

      user.getSession((err, session) => {
        if (err || !session.isValid()) {
          this.router.navigate(["/login"]);
          reject("User session is not valid");
        } else {
          resolve();
        }
      });
    });
  }

  private getErrorMessage(code: string): string {
    switch (code) {
      case "UserNotFoundException":
        return "Usuário não encontrado";
      case "NotAuthorizedException":
        return "Usuário ou senha inválidos ou usuário está desabilitado";
      case "UserNotConfirmedException":
        return "Usuário não confirmado";
      case "CodeMismatchException":
        return "Código inválido";
      case "LimitExceededException":
        return "Limite excedido, tente novamente mais tarde";
      case "TooManyRequestsException":
        return "Muitas solicitações, tente novamente mais tarde";
      case "PasswordResetRequiredException":
        return "É necessário redefinir a senha";
      case "InvalidPasswordException":
        return "Senha inválida";
      case "InvalidParameterException":
        return "Parâmetro inválido";
      case "UsernameExistsException":
        return "Nome de usuário já existe";
      case "ResourceNotFoundException":
        return "Recurso não encontrado";
      case "CodeDeliveryFailureException":
        return "Falha na entrega do código";
      case "InvalidLambdaResponseException":
        return "Resposta Lambda inválida";
      case "UnexpectedLambdaException":
        return "Exceção Lambda inesperada";
      case "InvalidUserPoolConfigurationException":
        return "Configuração inválida do pool de usuários";
      case "MFAMethodNotFoundException":
        return "Método MFA não encontrado";
      case "TooManyFailedAttemptsException":
        return "Muitas tentativas falhadas";
      case "ExpiredCodeException":
        return "Código expirado";
      default:
        return "Algo de errado aconteceu! Por favor, tente novamente mais tarde.";
    }
  }
}
