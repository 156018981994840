import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { SharedService } from "@libs/services/shared/shared.service";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  public loading$ = new ReplaySubject<boolean>(1);

  constructor(public shared: SharedService) {
    this.loading$.next(false);
  }
}
