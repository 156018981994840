import * as Api from "@libs/proto/core/profile/v1/profile_api";
import * as Profile from "@libs/proto/core/profile/v1/profile";
import { AuthorizationInterceptor } from "@libs/util/interceptor";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";

@Injectable({
  providedIn: "root",
})
export class GrpcProfileService {
  private client: Api.core.profile.v1.ProfileServiceClient;

  constructor(public mainService: MainService) {
    this.client = new Api.core.profile.v1.ProfileServiceClient(mainService.endpointBase, null, {
      unaryInterceptors: [new AuthorizationInterceptor()],
    });
  }

  confirmBid(externalId?: string) {
    // Criando o mapa de ações para confirmBid
    const confirmBidAction = new Profile.core.profile.v1.Action({
      hide: true,
    });

    const confirmActionMap = new Map<string, Profile.core.profile.v1.ConfirmAction>();

    confirmActionMap.set(
      `auction_${externalId}`,
      new Profile.core.profile.v1.ConfirmAction({
        action: new Map<string, Profile.core.profile.v1.Action>([["confirm_bid", confirmBidAction]]),
      })
    );

    const confirmActionConfig = new Profile.core.profile.v1.ConfirmActionConfiguration({
      confirmAction: confirmActionMap,
    });

    const objectProfile = new Profile.core.profile.v1.Profile({
      confirmAction: confirmActionConfig,
    });

    const request = new Api.core.profile.v1.UpdateProfileRequest({
      profile: objectProfile,
      // profileType: Profile.core.profile.v1.ProfileType.CONFIRM_ACTION_PROFILE
    });

    return this.client.UpdateProfile(request, {});
  }

  acceptTerms() {
    // Criando o mapa de ações para confirmBid
    const confirmBidAction = new Profile.core.profile.v1.Action({
      hide: true,
    });

    const confirmActionMap = new Map<string, Profile.core.profile.v1.ConfirmAction>();

    confirmActionMap.set(
      `user_info`,
      new Profile.core.profile.v1.ConfirmAction({
        action: new Map<string, Profile.core.profile.v1.Action>([["accepted_terms_bid", confirmBidAction]]),
      })
    );

    const confirmActionConfig = new Profile.core.profile.v1.ConfirmActionConfiguration({
      confirmAction: confirmActionMap,
    });

    const objectProfile = new Profile.core.profile.v1.Profile({
      confirmAction: confirmActionConfig,
    });

    const request = new Api.core.profile.v1.UpdateProfileRequest({
      profile: objectProfile
    });

    return this.client.UpdateProfile(request, {});
  }

  getProfile() {
    const request = new Api.core.profile.v1.GetProfileRequest({profileType: "confirmAction"});
    return this.client.GetProfile(request, {});
  }
}
