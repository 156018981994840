/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/transaction/v1/transaction_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./transaction";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.transaction.v1 {
    export class CreateTransactionBatchRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionType?: dependency_1.core.transaction.v1.TransactionType;
            externalServiceIds?: string[];
            extraData?: Map<string, string>;
            autoSigned?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("externalServiceIds" in data && data.externalServiceIds != undefined) {
                    this.externalServiceIds = data.externalServiceIds;
                }
                if ("extraData" in data && data.extraData != undefined) {
                    this.extraData = data.extraData;
                }
                if ("autoSigned" in data && data.autoSigned != undefined) {
                    this.autoSigned = data.autoSigned;
                }
            }
            if (!this.extraData)
                this.extraData = new Map();
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as dependency_1.core.transaction.v1.TransactionType;
        }
        set transactionType(value: dependency_1.core.transaction.v1.TransactionType) {
            pb_1.Message.setField(this, 1, value);
        }
        get externalServiceIds() {
            return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
        }
        set externalServiceIds(value: string[]) {
            pb_1.Message.setField(this, 2, value);
        }
        get extraData() {
            return pb_1.Message.getField(this, 3) as any as Map<string, string>;
        }
        set extraData(value: Map<string, string>) {
            pb_1.Message.setField(this, 3, value as any);
        }
        get autoSigned() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set autoSigned(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            transactionType?: dependency_1.core.transaction.v1.TransactionType;
            externalServiceIds?: string[];
            extraData?: {
                [key: string]: string;
            };
            autoSigned?: boolean;
        }): CreateTransactionBatchRequest {
            const message = new CreateTransactionBatchRequest({});
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (data.externalServiceIds != null) {
                message.externalServiceIds = data.externalServiceIds;
            }
            if (typeof data.extraData == "object") {
                message.extraData = new Map(Object.entries(data.extraData));
            }
            if (data.autoSigned != null) {
                message.autoSigned = data.autoSigned;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionType?: dependency_1.core.transaction.v1.TransactionType;
                externalServiceIds?: string[];
                extraData?: {
                    [key: string]: string;
                };
                autoSigned?: boolean;
            } = {};
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.externalServiceIds != null) {
                data.externalServiceIds = this.externalServiceIds;
            }
            if (this.extraData != null) {
                data.extraData = (Object.fromEntries)(this.extraData);
            }
            if (this.autoSigned != null) {
                data.autoSigned = this.autoSigned;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionType != dependency_1.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(1, this.transactionType);
            if (this.externalServiceIds.length)
                writer.writeRepeatedString(2, this.externalServiceIds);
            for (const [key, value] of this.extraData) {
                writer.writeMessage(3, this.extraData, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (this.autoSigned != false)
                writer.writeBool(4, this.autoSigned);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateTransactionBatchRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateTransactionBatchRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionType = reader.readEnum();
                        break;
                    case 2:
                        pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                        break;
                    case 3:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.extraData as any, reader, reader.readString, reader.readString));
                        break;
                    case 4:
                        message.autoSigned = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateTransactionBatchRequest {
            return CreateTransactionBatchRequest.deserialize(bytes);
        }
    }
    export class CreateTransactionBatchResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transactions?: dependency_1.core.transaction.v1.Transaction[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transactions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction[];
        }
        set transactions(value: dependency_1.core.transaction.v1.Transaction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
        }): CreateTransactionBatchResponse {
            const message = new CreateTransactionBatchResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transactions != null) {
                message.transactions = data.transactions.map(item => dependency_1.core.transaction.v1.Transaction.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transactions != null) {
                data.transactions = this.transactions.map((item: dependency_1.core.transaction.v1.Transaction) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.transactions.length)
                writer.writeRepeatedMessage(3, this.transactions, (item: dependency_1.core.transaction.v1.Transaction) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateTransactionBatchResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateTransactionBatchResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.transaction.v1.Transaction.deserialize(reader), dependency_1.core.transaction.v1.Transaction));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateTransactionBatchResponse {
            return CreateTransactionBatchResponse.deserialize(bytes);
        }
    }
    export class ReprocessTransactionsBatchRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            externalServiceIds?: string[];
            autoSigned?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("externalServiceIds" in data && data.externalServiceIds != undefined) {
                    this.externalServiceIds = data.externalServiceIds;
                }
                if ("autoSigned" in data && data.autoSigned != undefined) {
                    this.autoSigned = data.autoSigned;
                }
            }
        }
        get externalServiceIds() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set externalServiceIds(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        get autoSigned() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set autoSigned(value: boolean) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            externalServiceIds?: string[];
            autoSigned?: boolean;
        }): ReprocessTransactionsBatchRequest {
            const message = new ReprocessTransactionsBatchRequest({});
            if (data.externalServiceIds != null) {
                message.externalServiceIds = data.externalServiceIds;
            }
            if (data.autoSigned != null) {
                message.autoSigned = data.autoSigned;
            }
            return message;
        }
        toObject() {
            const data: {
                externalServiceIds?: string[];
                autoSigned?: boolean;
            } = {};
            if (this.externalServiceIds != null) {
                data.externalServiceIds = this.externalServiceIds;
            }
            if (this.autoSigned != null) {
                data.autoSigned = this.autoSigned;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.externalServiceIds.length)
                writer.writeRepeatedString(1, this.externalServiceIds);
            if (this.autoSigned != false)
                writer.writeBool(2, this.autoSigned);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReprocessTransactionsBatchRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReprocessTransactionsBatchRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    case 2:
                        message.autoSigned = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ReprocessTransactionsBatchRequest {
            return ReprocessTransactionsBatchRequest.deserialize(bytes);
        }
    }
    export class ReprocessTransactionsBatchResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transactions?: dependency_1.core.transaction.v1.Transaction[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transactions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction[];
        }
        set transactions(value: dependency_1.core.transaction.v1.Transaction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
        }): ReprocessTransactionsBatchResponse {
            const message = new ReprocessTransactionsBatchResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transactions != null) {
                message.transactions = data.transactions.map(item => dependency_1.core.transaction.v1.Transaction.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transactions != null) {
                data.transactions = this.transactions.map((item: dependency_1.core.transaction.v1.Transaction) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.transactions.length)
                writer.writeRepeatedMessage(3, this.transactions, (item: dependency_1.core.transaction.v1.Transaction) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReprocessTransactionsBatchResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ReprocessTransactionsBatchResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.transaction.v1.Transaction.deserialize(reader), dependency_1.core.transaction.v1.Transaction));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ReprocessTransactionsBatchResponse {
            return ReprocessTransactionsBatchResponse.deserialize(bytes);
        }
    }
    export class CreateTransactionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionType?: dependency_1.core.transaction.v1.TransactionType;
            externalServiceId?: string;
            extraData?: Map<string, string>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("externalServiceId" in data && data.externalServiceId != undefined) {
                    this.externalServiceId = data.externalServiceId;
                }
                if ("extraData" in data && data.extraData != undefined) {
                    this.extraData = data.extraData;
                }
            }
            if (!this.extraData)
                this.extraData = new Map();
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as dependency_1.core.transaction.v1.TransactionType;
        }
        set transactionType(value: dependency_1.core.transaction.v1.TransactionType) {
            pb_1.Message.setField(this, 1, value);
        }
        get externalServiceId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set externalServiceId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get extraData() {
            return pb_1.Message.getField(this, 3) as any as Map<string, string>;
        }
        set extraData(value: Map<string, string>) {
            pb_1.Message.setField(this, 3, value as any);
        }
        static fromObject(data: {
            transactionType?: dependency_1.core.transaction.v1.TransactionType;
            externalServiceId?: string;
            extraData?: {
                [key: string]: string;
            };
        }): CreateTransactionRequest {
            const message = new CreateTransactionRequest({});
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (data.externalServiceId != null) {
                message.externalServiceId = data.externalServiceId;
            }
            if (typeof data.extraData == "object") {
                message.extraData = new Map(Object.entries(data.extraData));
            }
            return message;
        }
        toObject() {
            const data: {
                transactionType?: dependency_1.core.transaction.v1.TransactionType;
                externalServiceId?: string;
                extraData?: {
                    [key: string]: string;
                };
            } = {};
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.externalServiceId != null) {
                data.externalServiceId = this.externalServiceId;
            }
            if (this.extraData != null) {
                data.extraData = (Object.fromEntries)(this.extraData);
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionType != dependency_1.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(1, this.transactionType);
            if (this.externalServiceId.length)
                writer.writeString(2, this.externalServiceId);
            for (const [key, value] of this.extraData) {
                writer.writeMessage(3, this.extraData, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateTransactionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateTransactionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionType = reader.readEnum();
                        break;
                    case 2:
                        message.externalServiceId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.extraData as any, reader, reader.readString, reader.readString));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateTransactionRequest {
            return CreateTransactionRequest.deserialize(bytes);
        }
    }
    export class CreateTransactionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transaction?: dependency_1.core.transaction.v1.Transaction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_1.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
        }): CreateTransactionResponse {
            const message = new CreateTransactionResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transaction != null) {
                message.transaction = dependency_1.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTransaction)
                writer.writeMessage(3, this.transaction, () => this.transaction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateTransactionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateTransactionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_1.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateTransactionResponse {
            return CreateTransactionResponse.deserialize(bytes);
        }
    }
    export class SignTransactionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionId" in data && data.transactionId != undefined) {
                    this.transactionId = data.transactionId;
                }
            }
        }
        get transactionId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set transactionId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            transactionId?: string;
        }): SignTransactionRequest {
            const message = new SignTransactionRequest({});
            if (data.transactionId != null) {
                message.transactionId = data.transactionId;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionId?: string;
            } = {};
            if (this.transactionId != null) {
                data.transactionId = this.transactionId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionId.length)
                writer.writeString(1, this.transactionId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SignTransactionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SignTransactionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SignTransactionRequest {
            return SignTransactionRequest.deserialize(bytes);
        }
    }
    export class SignTransactionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transaction?: dependency_1.core.transaction.v1.Transaction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_1.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
        }): SignTransactionResponse {
            const message = new SignTransactionResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transaction != null) {
                message.transaction = dependency_1.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTransaction)
                writer.writeMessage(3, this.transaction, () => this.transaction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SignTransactionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SignTransactionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_1.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SignTransactionResponse {
            return SignTransactionResponse.deserialize(bytes);
        }
    }
    export class ListTransactionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            externalId?: string;
            transactionType?: dependency_1.core.transaction.v1.TransactionType;
            page?: number;
            itemsPerPage?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemsPerPage" in data && data.itemsPerPage != undefined) {
                    this.itemsPerPage = data.itemsPerPage;
                }
            }
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 2, dependency_1.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as dependency_1.core.transaction.v1.TransactionType;
        }
        set transactionType(value: dependency_1.core.transaction.v1.TransactionType) {
            pb_1.Message.setField(this, 2, value);
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get itemsPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set itemsPerPage(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            externalId?: string;
            transactionType?: dependency_1.core.transaction.v1.TransactionType;
            page?: number;
            itemsPerPage?: number;
        }): ListTransactionRequest {
            const message = new ListTransactionRequest({});
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemsPerPage != null) {
                message.itemsPerPage = data.itemsPerPage;
            }
            return message;
        }
        toObject() {
            const data: {
                externalId?: string;
                transactionType?: dependency_1.core.transaction.v1.TransactionType;
                page?: number;
                itemsPerPage?: number;
            } = {};
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemsPerPage != null) {
                data.itemsPerPage = this.itemsPerPage;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.externalId.length)
                writer.writeString(1, this.externalId);
            if (this.transactionType != dependency_1.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(2, this.transactionType);
            if (this.page != 0)
                writer.writeInt32(3, this.page);
            if (this.itemsPerPage != 0)
                writer.writeInt32(4, this.itemsPerPage);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListTransactionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListTransactionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.externalId = reader.readString();
                        break;
                    case 2:
                        message.transactionType = reader.readEnum();
                        break;
                    case 3:
                        message.page = reader.readInt32();
                        break;
                    case 4:
                        message.itemsPerPage = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListTransactionRequest {
            return ListTransactionRequest.deserialize(bytes);
        }
    }
    export class ListTransactionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transactions?: dependency_1.core.transaction.v1.Transaction[];
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
                if ("currentPage" in data && data.currentPage != undefined) {
                    this.currentPage = data.currentPage;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
                if ("totalItems" in data && data.totalItems != undefined) {
                    this.totalItems = data.totalItems;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transactions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction[];
        }
        set transactions(value: dependency_1.core.transaction.v1.Transaction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        get currentPage() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set currentPage(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get totalItems() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set totalItems(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
            currentPage?: number;
            totalPages?: number;
            totalItems?: number;
        }): ListTransactionResponse {
            const message = new ListTransactionResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transactions != null) {
                message.transactions = data.transactions.map(item => dependency_1.core.transaction.v1.Transaction.fromObject(item));
            }
            if (data.currentPage != null) {
                message.currentPage = data.currentPage;
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            if (data.totalItems != null) {
                message.totalItems = data.totalItems;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
                currentPage?: number;
                totalPages?: number;
                totalItems?: number;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transactions != null) {
                data.transactions = this.transactions.map((item: dependency_1.core.transaction.v1.Transaction) => item.toObject());
            }
            if (this.currentPage != null) {
                data.currentPage = this.currentPage;
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            if (this.totalItems != null) {
                data.totalItems = this.totalItems;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.transactions.length)
                writer.writeRepeatedMessage(3, this.transactions, (item: dependency_1.core.transaction.v1.Transaction) => item.serialize(writer));
            if (this.currentPage != 0)
                writer.writeInt32(4, this.currentPage);
            if (this.totalPages != 0)
                writer.writeInt32(5, this.totalPages);
            if (this.totalItems != 0)
                writer.writeInt32(6, this.totalItems);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListTransactionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListTransactionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.transaction.v1.Transaction.deserialize(reader), dependency_1.core.transaction.v1.Transaction));
                        break;
                    case 4:
                        message.currentPage = reader.readInt32();
                        break;
                    case 5:
                        message.totalPages = reader.readInt32();
                        break;
                    case 6:
                        message.totalItems = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListTransactionResponse {
            return ListTransactionResponse.deserialize(bytes);
        }
    }
    export class ListTransactionsNotSyncedRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): ListTransactionsNotSyncedRequest {
            const message = new ListTransactionsNotSyncedRequest({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListTransactionsNotSyncedRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListTransactionsNotSyncedRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListTransactionsNotSyncedRequest {
            return ListTransactionsNotSyncedRequest.deserialize(bytes);
        }
    }
    export class ListTransactionsNotSyncedResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transactions?: dependency_1.core.transaction.v1.Transaction[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transactions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction[];
        }
        set transactions(value: dependency_1.core.transaction.v1.Transaction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
        }): ListTransactionsNotSyncedResponse {
            const message = new ListTransactionsNotSyncedResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transactions != null) {
                message.transactions = data.transactions.map(item => dependency_1.core.transaction.v1.Transaction.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transactions != null) {
                data.transactions = this.transactions.map((item: dependency_1.core.transaction.v1.Transaction) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.transactions.length)
                writer.writeRepeatedMessage(3, this.transactions, (item: dependency_1.core.transaction.v1.Transaction) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListTransactionsNotSyncedResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListTransactionsNotSyncedResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.transaction.v1.Transaction.deserialize(reader), dependency_1.core.transaction.v1.Transaction));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListTransactionsNotSyncedResponse {
            return ListTransactionsNotSyncedResponse.deserialize(bytes);
        }
    }
    export class SetTransactionSyncedRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionId" in data && data.transactionId != undefined) {
                    this.transactionId = data.transactionId;
                }
            }
        }
        get transactionId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set transactionId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            transactionId?: string;
        }): SetTransactionSyncedRequest {
            const message = new SetTransactionSyncedRequest({});
            if (data.transactionId != null) {
                message.transactionId = data.transactionId;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionId?: string;
            } = {};
            if (this.transactionId != null) {
                data.transactionId = this.transactionId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionId.length)
                writer.writeString(1, this.transactionId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SetTransactionSyncedRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SetTransactionSyncedRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SetTransactionSyncedRequest {
            return SetTransactionSyncedRequest.deserialize(bytes);
        }
    }
    export class SetTransactionSyncedResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
        }): SetTransactionSyncedResponse {
            const message = new SetTransactionSyncedResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SetTransactionSyncedResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SetTransactionSyncedResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SetTransactionSyncedResponse {
            return SetTransactionSyncedResponse.deserialize(bytes);
        }
    }
    export class GetTransactionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionId" in data && data.transactionId != undefined) {
                    this.transactionId = data.transactionId;
                }
            }
        }
        get transactionId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set transactionId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            transactionId?: string;
        }): GetTransactionRequest {
            const message = new GetTransactionRequest({});
            if (data.transactionId != null) {
                message.transactionId = data.transactionId;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionId?: string;
            } = {};
            if (this.transactionId != null) {
                data.transactionId = this.transactionId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionId.length)
                writer.writeString(1, this.transactionId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTransactionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTransactionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTransactionRequest {
            return GetTransactionRequest.deserialize(bytes);
        }
    }
    export class GetTransactionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transaction?: dependency_1.core.transaction.v1.Transaction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_1.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
        }): GetTransactionResponse {
            const message = new GetTransactionResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transaction != null) {
                message.transaction = dependency_1.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTransaction)
                writer.writeMessage(3, this.transaction, () => this.transaction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTransactionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTransactionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_1.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTransactionResponse {
            return GetTransactionResponse.deserialize(bytes);
        }
    }
    export class GetTransactionsByIdsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionsIds?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionsIds" in data && data.transactionsIds != undefined) {
                    this.transactionsIds = data.transactionsIds;
                }
            }
        }
        get transactionsIds() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set transactionsIds(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            transactionsIds?: string[];
        }): GetTransactionsByIdsRequest {
            const message = new GetTransactionsByIdsRequest({});
            if (data.transactionsIds != null) {
                message.transactionsIds = data.transactionsIds;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionsIds?: string[];
            } = {};
            if (this.transactionsIds != null) {
                data.transactionsIds = this.transactionsIds;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionsIds.length)
                writer.writeRepeatedString(1, this.transactionsIds);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTransactionsByIdsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTransactionsByIdsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTransactionsByIdsRequest {
            return GetTransactionsByIdsRequest.deserialize(bytes);
        }
    }
    export class GetTransactionsByIdsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transactions?: dependency_1.core.transaction.v1.Transaction[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transactions" in data && data.transactions != undefined) {
                    this.transactions = data.transactions;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transactions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction[];
        }
        set transactions(value: dependency_1.core.transaction.v1.Transaction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
        }): GetTransactionsByIdsResponse {
            const message = new GetTransactionsByIdsResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transactions != null) {
                message.transactions = data.transactions.map(item => dependency_1.core.transaction.v1.Transaction.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transactions?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>[];
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transactions != null) {
                data.transactions = this.transactions.map((item: dependency_1.core.transaction.v1.Transaction) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.transactions.length)
                writer.writeRepeatedMessage(3, this.transactions, (item: dependency_1.core.transaction.v1.Transaction) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTransactionsByIdsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTransactionsByIdsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_1.core.transaction.v1.Transaction.deserialize(reader), dependency_1.core.transaction.v1.Transaction));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTransactionsByIdsResponse {
            return GetTransactionsByIdsResponse.deserialize(bytes);
        }
    }
    export class GetTransactionBlockchainByHashResquest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hash?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hash" in data && data.hash != undefined) {
                    this.hash = data.hash;
                }
            }
        }
        get hash() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set hash(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            hash?: string;
        }): GetTransactionBlockchainByHashResquest {
            const message = new GetTransactionBlockchainByHashResquest({});
            if (data.hash != null) {
                message.hash = data.hash;
            }
            return message;
        }
        toObject() {
            const data: {
                hash?: string;
            } = {};
            if (this.hash != null) {
                data.hash = this.hash;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hash.length)
                writer.writeString(1, this.hash);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTransactionBlockchainByHashResquest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTransactionBlockchainByHashResquest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hash = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTransactionBlockchainByHashResquest {
            return GetTransactionBlockchainByHashResquest.deserialize(bytes);
        }
    }
    export class GetTransactionBlockchainByHashResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: boolean;
            message?: string;
            transaction?: dependency_1.core.transaction.v1.Transaction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set status(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.transaction.v1.Transaction, 3) as dependency_1.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_1.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            status?: boolean;
            message?: string;
            transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
        }): GetTransactionBlockchainByHashResponse {
            const message = new GetTransactionBlockchainByHashResponse({});
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.message != null) {
                message.message = data.message;
            }
            if (data.transaction != null) {
                message.transaction = dependency_1.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            return message;
        }
        toObject() {
            const data: {
                status?: boolean;
                message?: string;
                transaction?: ReturnType<typeof dependency_1.core.transaction.v1.Transaction.prototype.toObject>;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.message != null) {
                data.message = this.message;
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status != false)
                writer.writeBool(1, this.status);
            if (this.message.length)
                writer.writeString(2, this.message);
            if (this.hasTransaction)
                writer.writeMessage(3, this.transaction, () => this.transaction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTransactionBlockchainByHashResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTransactionBlockchainByHashResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readBool();
                        break;
                    case 2:
                        message.message = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_1.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTransactionBlockchainByHashResponse {
            return GetTransactionBlockchainByHashResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedTransactionAPIService {
        static definition = {
            CreateTransaction: {
                path: "/core.transaction.v1.TransactionAPI/CreateTransaction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateTransactionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateTransactionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateTransactionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateTransactionResponse.deserialize(new Uint8Array(bytes))
            },
            SignTransaction: {
                path: "/core.transaction.v1.TransactionAPI/SignTransaction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: SignTransactionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => SignTransactionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: SignTransactionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => SignTransactionResponse.deserialize(new Uint8Array(bytes))
            },
            ListTransactions: {
                path: "/core.transaction.v1.TransactionAPI/ListTransactions",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListTransactionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListTransactionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListTransactionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListTransactionResponse.deserialize(new Uint8Array(bytes))
            },
            ListTransactionsNotSynced: {
                path: "/core.transaction.v1.TransactionAPI/ListTransactionsNotSynced",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListTransactionsNotSyncedRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListTransactionsNotSyncedRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListTransactionsNotSyncedResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListTransactionsNotSyncedResponse.deserialize(new Uint8Array(bytes))
            },
            SetTransactionSynced: {
                path: "/core.transaction.v1.TransactionAPI/SetTransactionSynced",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: SetTransactionSyncedRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => SetTransactionSyncedRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: SetTransactionSyncedResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => SetTransactionSyncedResponse.deserialize(new Uint8Array(bytes))
            },
            GetTransaction: {
                path: "/core.transaction.v1.TransactionAPI/GetTransaction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTransactionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTransactionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTransactionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTransactionResponse.deserialize(new Uint8Array(bytes))
            },
            GetTransactionsByIds: {
                path: "/core.transaction.v1.TransactionAPI/GetTransactionsByIds",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTransactionsByIdsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTransactionsByIdsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTransactionsByIdsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTransactionsByIdsResponse.deserialize(new Uint8Array(bytes))
            },
            GetTransactionBlockchainByHash: {
                path: "/core.transaction.v1.TransactionAPI/GetTransactionBlockchainByHash",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTransactionBlockchainByHashResquest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTransactionBlockchainByHashResquest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTransactionBlockchainByHashResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTransactionBlockchainByHashResponse.deserialize(new Uint8Array(bytes))
            },
            CreateTransactionBatch: {
                path: "/core.transaction.v1.TransactionAPI/CreateTransactionBatch",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateTransactionBatchRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateTransactionBatchRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateTransactionBatchResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateTransactionBatchResponse.deserialize(new Uint8Array(bytes))
            },
            ReprocessTransactionsBatch: {
                path: "/core.transaction.v1.TransactionAPI/ReprocessTransactionsBatch",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ReprocessTransactionsBatchRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ReprocessTransactionsBatchRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ReprocessTransactionsBatchResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ReprocessTransactionsBatchResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract CreateTransaction(call: grpc_1.ServerUnaryCall<CreateTransactionRequest, CreateTransactionResponse>, callback: grpc_1.sendUnaryData<CreateTransactionResponse>): void;
        abstract SignTransaction(call: grpc_1.ServerUnaryCall<SignTransactionRequest, SignTransactionResponse>, callback: grpc_1.sendUnaryData<SignTransactionResponse>): void;
        abstract ListTransactions(call: grpc_1.ServerUnaryCall<ListTransactionRequest, ListTransactionResponse>, callback: grpc_1.sendUnaryData<ListTransactionResponse>): void;
        abstract ListTransactionsNotSynced(call: grpc_1.ServerUnaryCall<ListTransactionsNotSyncedRequest, ListTransactionsNotSyncedResponse>, callback: grpc_1.sendUnaryData<ListTransactionsNotSyncedResponse>): void;
        abstract SetTransactionSynced(call: grpc_1.ServerUnaryCall<SetTransactionSyncedRequest, SetTransactionSyncedResponse>, callback: grpc_1.sendUnaryData<SetTransactionSyncedResponse>): void;
        abstract GetTransaction(call: grpc_1.ServerUnaryCall<GetTransactionRequest, GetTransactionResponse>, callback: grpc_1.sendUnaryData<GetTransactionResponse>): void;
        abstract GetTransactionsByIds(call: grpc_1.ServerUnaryCall<GetTransactionsByIdsRequest, GetTransactionsByIdsResponse>, callback: grpc_1.sendUnaryData<GetTransactionsByIdsResponse>): void;
        abstract GetTransactionBlockchainByHash(call: grpc_1.ServerUnaryCall<GetTransactionBlockchainByHashResquest, GetTransactionBlockchainByHashResponse>, callback: grpc_1.sendUnaryData<GetTransactionBlockchainByHashResponse>): void;
        abstract CreateTransactionBatch(call: grpc_1.ServerUnaryCall<CreateTransactionBatchRequest, CreateTransactionBatchResponse>, callback: grpc_1.sendUnaryData<CreateTransactionBatchResponse>): void;
        abstract ReprocessTransactionsBatch(call: grpc_1.ServerUnaryCall<ReprocessTransactionsBatchRequest, ReprocessTransactionsBatchResponse>, callback: grpc_1.sendUnaryData<ReprocessTransactionsBatchResponse>): void;
    }
    export class TransactionAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static CreateTransaction = new grpc_web_1.MethodDescriptor<CreateTransactionRequest, CreateTransactionResponse>("/core.transaction.v1.TransactionAPI/CreateTransaction", grpc_web_1.MethodType.UNARY, CreateTransactionRequest, CreateTransactionResponse, (message: CreateTransactionRequest) => message.serialize(), CreateTransactionResponse.deserialize);
        CreateTransaction(message: CreateTransactionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateTransactionRequest, CreateTransactionResponse>(this._address + "/core.transaction.v1.TransactionAPI/CreateTransaction", message, metadata || {}, TransactionAPIClient.CreateTransaction);
        }
        private static SignTransaction = new grpc_web_1.MethodDescriptor<SignTransactionRequest, SignTransactionResponse>("/core.transaction.v1.TransactionAPI/SignTransaction", grpc_web_1.MethodType.UNARY, SignTransactionRequest, SignTransactionResponse, (message: SignTransactionRequest) => message.serialize(), SignTransactionResponse.deserialize);
        SignTransaction(message: SignTransactionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<SignTransactionRequest, SignTransactionResponse>(this._address + "/core.transaction.v1.TransactionAPI/SignTransaction", message, metadata || {}, TransactionAPIClient.SignTransaction);
        }
        private static ListTransactions = new grpc_web_1.MethodDescriptor<ListTransactionRequest, ListTransactionResponse>("/core.transaction.v1.TransactionAPI/ListTransactions", grpc_web_1.MethodType.UNARY, ListTransactionRequest, ListTransactionResponse, (message: ListTransactionRequest) => message.serialize(), ListTransactionResponse.deserialize);
        ListTransactions(message: ListTransactionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListTransactionRequest, ListTransactionResponse>(this._address + "/core.transaction.v1.TransactionAPI/ListTransactions", message, metadata || {}, TransactionAPIClient.ListTransactions);
        }
        private static ListTransactionsNotSynced = new grpc_web_1.MethodDescriptor<ListTransactionsNotSyncedRequest, ListTransactionsNotSyncedResponse>("/core.transaction.v1.TransactionAPI/ListTransactionsNotSynced", grpc_web_1.MethodType.UNARY, ListTransactionsNotSyncedRequest, ListTransactionsNotSyncedResponse, (message: ListTransactionsNotSyncedRequest) => message.serialize(), ListTransactionsNotSyncedResponse.deserialize);
        ListTransactionsNotSynced(message: ListTransactionsNotSyncedRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListTransactionsNotSyncedRequest, ListTransactionsNotSyncedResponse>(this._address + "/core.transaction.v1.TransactionAPI/ListTransactionsNotSynced", message, metadata || {}, TransactionAPIClient.ListTransactionsNotSynced);
        }
        private static SetTransactionSynced = new grpc_web_1.MethodDescriptor<SetTransactionSyncedRequest, SetTransactionSyncedResponse>("/core.transaction.v1.TransactionAPI/SetTransactionSynced", grpc_web_1.MethodType.UNARY, SetTransactionSyncedRequest, SetTransactionSyncedResponse, (message: SetTransactionSyncedRequest) => message.serialize(), SetTransactionSyncedResponse.deserialize);
        SetTransactionSynced(message: SetTransactionSyncedRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<SetTransactionSyncedRequest, SetTransactionSyncedResponse>(this._address + "/core.transaction.v1.TransactionAPI/SetTransactionSynced", message, metadata || {}, TransactionAPIClient.SetTransactionSynced);
        }
        private static GetTransaction = new grpc_web_1.MethodDescriptor<GetTransactionRequest, GetTransactionResponse>("/core.transaction.v1.TransactionAPI/GetTransaction", grpc_web_1.MethodType.UNARY, GetTransactionRequest, GetTransactionResponse, (message: GetTransactionRequest) => message.serialize(), GetTransactionResponse.deserialize);
        GetTransaction(message: GetTransactionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTransactionRequest, GetTransactionResponse>(this._address + "/core.transaction.v1.TransactionAPI/GetTransaction", message, metadata || {}, TransactionAPIClient.GetTransaction);
        }
        private static GetTransactionsByIds = new grpc_web_1.MethodDescriptor<GetTransactionsByIdsRequest, GetTransactionsByIdsResponse>("/core.transaction.v1.TransactionAPI/GetTransactionsByIds", grpc_web_1.MethodType.UNARY, GetTransactionsByIdsRequest, GetTransactionsByIdsResponse, (message: GetTransactionsByIdsRequest) => message.serialize(), GetTransactionsByIdsResponse.deserialize);
        GetTransactionsByIds(message: GetTransactionsByIdsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTransactionsByIdsRequest, GetTransactionsByIdsResponse>(this._address + "/core.transaction.v1.TransactionAPI/GetTransactionsByIds", message, metadata || {}, TransactionAPIClient.GetTransactionsByIds);
        }
        private static GetTransactionBlockchainByHash = new grpc_web_1.MethodDescriptor<GetTransactionBlockchainByHashResquest, GetTransactionBlockchainByHashResponse>("/core.transaction.v1.TransactionAPI/GetTransactionBlockchainByHash", grpc_web_1.MethodType.UNARY, GetTransactionBlockchainByHashResquest, GetTransactionBlockchainByHashResponse, (message: GetTransactionBlockchainByHashResquest) => message.serialize(), GetTransactionBlockchainByHashResponse.deserialize);
        GetTransactionBlockchainByHash(message: GetTransactionBlockchainByHashResquest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTransactionBlockchainByHashResquest, GetTransactionBlockchainByHashResponse>(this._address + "/core.transaction.v1.TransactionAPI/GetTransactionBlockchainByHash", message, metadata || {}, TransactionAPIClient.GetTransactionBlockchainByHash);
        }
        private static CreateTransactionBatch = new grpc_web_1.MethodDescriptor<CreateTransactionBatchRequest, CreateTransactionBatchResponse>("/core.transaction.v1.TransactionAPI/CreateTransactionBatch", grpc_web_1.MethodType.UNARY, CreateTransactionBatchRequest, CreateTransactionBatchResponse, (message: CreateTransactionBatchRequest) => message.serialize(), CreateTransactionBatchResponse.deserialize);
        CreateTransactionBatch(message: CreateTransactionBatchRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateTransactionBatchRequest, CreateTransactionBatchResponse>(this._address + "/core.transaction.v1.TransactionAPI/CreateTransactionBatch", message, metadata || {}, TransactionAPIClient.CreateTransactionBatch);
        }
        private static ReprocessTransactionsBatch = new grpc_web_1.MethodDescriptor<ReprocessTransactionsBatchRequest, ReprocessTransactionsBatchResponse>("/core.transaction.v1.TransactionAPI/ReprocessTransactionsBatch", grpc_web_1.MethodType.UNARY, ReprocessTransactionsBatchRequest, ReprocessTransactionsBatchResponse, (message: ReprocessTransactionsBatchRequest) => message.serialize(), ReprocessTransactionsBatchResponse.deserialize);
        ReprocessTransactionsBatch(message: ReprocessTransactionsBatchRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ReprocessTransactionsBatchRequest, ReprocessTransactionsBatchResponse>(this._address + "/core.transaction.v1.TransactionAPI/ReprocessTransactionsBatch", message, metadata || {}, TransactionAPIClient.ReprocessTransactionsBatch);
        }
    }
}
