import { inject, Injectable } from "@angular/core";
import { CognitoService } from "@libs/services/cognito/cognito.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationInterceptor {
  public cognitoService = inject(CognitoService);
  private maxRetries = 100;

  constructor() {}

  intercept = async (request, invoker) => {
    let authorization = this.getAuthorization();
    if (authorization) {
      request.getMetadata()["Authorization"] = authorization;
    }
    return this.handleRequestWithRetry(request, invoker, 1, false);
  };

  private async handleRequestWithRetry(request, invoker, attempt: number, useDelay: boolean): Promise<any> {
    try {
      const response = await invoker(request);
      return response;
    } catch (error) {
      console.error("Error in interceptor", error);
      if ((error.code === 401 || error.code === 2 || error.code === 16) && attempt <= this.maxRetries) {
        const renewed = await this.tryRenewToken();

        if (renewed) {
          let authorization = this.getAuthorization();
          if (authorization) {
            request.getMetadata()["Authorization"] = authorization;
          }

          if (useDelay) {
            const delay = this.getExponentialBackoffDelay(attempt);
            console.log(`Esperando ${delay / 1000} segundos antes da tentativa ${attempt} de renovação do token`);
            await this.sleep(delay);
          }

          return this.handleRequestWithRetry(request, invoker, attempt + 1, true);
        } else {
          throw new Error("Falha ao renovar o token");
        }
      }
      throw error;
    }
  }

  private getAuthorization = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      return `Bearer ${accessToken}`;
    }
    return null;
  };

  private async tryRenewToken(): Promise<boolean> {
    try {
      await this.cognitoService.refreshSession();
      return true;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return false;
    }
  }

  private getExponentialBackoffDelay(attempt: number): number {
    return Math.pow(3, attempt) * 1000;
  }

  private sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
