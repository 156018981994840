import { banks } from "../data/banks";
import { SelectItem } from "../models/select";

export const removeSymbol = (doc: string) => {
  if (!doc) {
    return "";
  }
  return doc.replace(/\s/g, "").replace(/\./g, "").replace("-", "").replace("/", "").replace("(", "").replace(")", "");
};

export const getFormatedCpfCnpj = (cpfCnpj: string): string => {
  if (!cpfCnpj) {
    return "";
  }
  cpfCnpj = cpfCnpj.replace(/\D/g, "");
  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (cpfCnpj.length === 14) {
    return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  } else {
    return cpfCnpj;
  }
};

export const getFormatedDateBR = (date: Date) => {
  const dia = date.getDate().toString().padStart(2, "0");
  const mes = (date.getMonth() + 1).toString().padStart(2, "0");
  const ano = date.getFullYear();
  const formatted = `${dia}/${mes}/${ano}`;
  return formatted;
};

export const getFormatedCurrencyBR = (value: string | number) => {
  let valueNumber = typeof value === "string" ? Number(value) : value;
  valueNumber = valueNumber / 100;
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return formatter.format(valueNumber);
};

export const getBankList = (): SelectItem[] => {
  return banks
    .filter((bank) => bank.code && bank.name && bank.code > 0)
    .map((bank) => {
      return {
        key: bank.code.toString(),
        value: bank.name.toUpperCase(),
        description: bank.fullName,
      };
    });
};

export const toId = (target: string) => {
  return target
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "_");
};

export const convertToMoneyString = (value: string): string => {
  console.log("convertToMoneyString value ==> ", value);
  if (typeof value === "number") {
    return String(value * 100);
  } else {
    const valueWithoutPoints = value.replace(/\./g, "");
    return String(Math.round(Number(valueWithoutPoints.replace(",", ".")) * 100));
  }
};

export const getReduceString = (fullString: string) => {
  return fullString.length > 22 ? `${fullString.substring(0, 7)}...${fullString.substring(fullString.length - 7)}` : fullString;
};

export const getReduceTextString = (fullString: string, max = 30) => {
  return fullString.length > max ? `${fullString.substring(0, max - 1)}...` : fullString;
};
