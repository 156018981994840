import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BaseComponent } from "@libs/util/base.component";

@Component({
  selector: "app-modal-tokenizacao-terms",
  templateUrl: "./modal-tokenizacao-terms.component.html",
  styleUrl: "./modal-tokenizacao-terms.component.scss",
})
export class ModalTokenizacaoTermsComponent extends BaseComponent implements OnInit {
  terms: string;
  lastUpdateDate: string;

  constructor(public dialogRef: MatDialogRef<ModalTokenizacaoTermsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }

  ngOnInit() {
    this.terms = this.data.termsHtml;
    this.lastUpdateDate = this.data.lastUpdateDate;
  }

  acceptTerms() {
    this.dialogRef.close(true);
    this.cognitoService.updateUser({ termsAcceptedAt: this.lastUpdateDate });
  }
}
