export const environment = {
  mobiup: {
    base_url: {
      development: "https://core.nuclea.development.supermup.com.br",
      homolog_int: "https://core.nuclea.homolog.supermup.com.br",
      homolog: "https://core.nuclea.homolog.supermup.com.br",
      production: "https://core.nuclea.production.supermup.com.br",
    },
    pool: {
      token: {
        development: { UserPoolId: "us-west-2_9vGOssWWQ", ClientId: "6815bhkvlmc06a7r173rdp612b" },
        homolog_int: { UserPoolId: "us-west-2_jHMjLnB5V", ClientId: "3lgjoi85qiqftoojjdjcdaash9" },
        homolog: { UserPoolId: "us-west-2_jHMjLnB5V", ClientId: "3lgjoi85qiqftoojjdjcdaash9" },
        production: { UserPoolId: "us-west-2_jHMjLnB5V", ClientId: "3lgjoi85qiqftoojjdjcdaash9" },
      },
      ecotas: {
        development: { UserPoolId: "us-west-2_cQZpFnY17", ClientId: "tnmabqd3s4s9do63ga5d2tnjb" },
        homolog_int: { UserPoolId: "us-west-2_dpjwqihsy", ClientId: "1nanmhqkr92v2tldjob1nhk481" },
        homolog: { UserPoolId: "us-west-2_dpjwqihsy", ClientId: "1nanmhqkr92v2tldjob1nhk481" },
        production: { UserPoolId: "us-west-2_cQZpFnY17", ClientId: "tnmabqd3s4s9do63ga5d2tnjb" },
      },
    },
    recaptcha: {
      siteKey: "6LevKzEqAAAAAB0Q1ppRfAPI5SgBqAfbny3gwQZ0",
    },
  },
  nuclea: {
    base_url: {
      development: "https://api-dev.nucleachain.com.br",
      homolog_int: "https://api-hint.nucleachain.com.br",
      homolog: "https://api-hml.nucleachain.com.br",
      production: "https://api.nucleachain.com.br",
    },
    pool: {
      token: {
        development: { UserPoolId: "us-east-1_uRdZKswyT", ClientId: "1d9a3j39sl7d5p4879r0e4evv8" },
        homolog_int: { UserPoolId: "us-east-1_AzPUNiOch", ClientId: "2meqk983o3tf9ru1dcbjiur2at" },
        homolog: { UserPoolId: "us-east-1_YbdR7RXQ4", ClientId: "3jrbq8sonoc2infj0ko8gl0cb7" },
        production: { UserPoolId: "sa-east-1_lgMtHHDD7", ClientId: "58ohl71gqvs5nk0rsealludt08" },
      },
    },
    recaptcha: {
      siteKey: "6LevKzEqAAAAAB0Q1ppRfAPI5SgBqAfbny3gwQZ0",
    },
  },
};
