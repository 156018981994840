/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/notification/v1/notification_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./notification";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.notification.v1 {
    export class SubscribeNotificationRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            notification?: dependency_1.core.notification.v1.SubscribeNotification[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("notification" in data && data.notification != undefined) {
                    this.notification = data.notification;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get notification() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.notification.v1.SubscribeNotification, 2) as dependency_1.core.notification.v1.SubscribeNotification[];
        }
        set notification(value: dependency_1.core.notification.v1.SubscribeNotification[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            id?: string;
            notification?: ReturnType<typeof dependency_1.core.notification.v1.SubscribeNotification.prototype.toObject>[];
        }): SubscribeNotificationRequest {
            const message = new SubscribeNotificationRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.notification != null) {
                message.notification = data.notification.map(item => dependency_1.core.notification.v1.SubscribeNotification.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                notification?: ReturnType<typeof dependency_1.core.notification.v1.SubscribeNotification.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.notification != null) {
                data.notification = this.notification.map((item: dependency_1.core.notification.v1.SubscribeNotification) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.notification.length)
                writer.writeRepeatedMessage(2, this.notification, (item: dependency_1.core.notification.v1.SubscribeNotification) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscribeNotificationRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscribeNotificationRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.notification, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_1.core.notification.v1.SubscribeNotification.deserialize(reader), dependency_1.core.notification.v1.SubscribeNotification));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SubscribeNotificationRequest {
            return SubscribeNotificationRequest.deserialize(bytes);
        }
    }
    export class SubscribeNotificationResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            notification?: dependency_1.core.notification.v1.Notification;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("notification" in data && data.notification != undefined) {
                    this.notification = data.notification;
                }
            }
        }
        get notification() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.notification.v1.Notification, 1) as dependency_1.core.notification.v1.Notification;
        }
        set notification(value: dependency_1.core.notification.v1.Notification) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasNotification() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            notification?: ReturnType<typeof dependency_1.core.notification.v1.Notification.prototype.toObject>;
        }): SubscribeNotificationResponse {
            const message = new SubscribeNotificationResponse({});
            if (data.notification != null) {
                message.notification = dependency_1.core.notification.v1.Notification.fromObject(data.notification);
            }
            return message;
        }
        toObject() {
            const data: {
                notification?: ReturnType<typeof dependency_1.core.notification.v1.Notification.prototype.toObject>;
            } = {};
            if (this.notification != null) {
                data.notification = this.notification.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasNotification)
                writer.writeMessage(1, this.notification, () => this.notification.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscribeNotificationResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscribeNotificationResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.notification, () => message.notification = dependency_1.core.notification.v1.Notification.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SubscribeNotificationResponse {
            return SubscribeNotificationResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedNotificationAPIService {
        static definition = {
            Subscribe: {
                path: "/core.notification.v1.NotificationAPI/Subscribe",
                requestStream: false,
                responseStream: true,
                requestSerialize: (message: SubscribeNotificationRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => SubscribeNotificationRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: SubscribeNotificationResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => SubscribeNotificationResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract Subscribe(call: grpc_1.ServerWritableStream<SubscribeNotificationRequest, SubscribeNotificationResponse>): void;
    }
    export class NotificationAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static Subscribe = new grpc_web_1.MethodDescriptor<SubscribeNotificationRequest, SubscribeNotificationResponse>("/core.notification.v1.NotificationAPI/Subscribe", grpc_web_1.MethodType.SERVER_STREAMING, SubscribeNotificationRequest, SubscribeNotificationResponse, (message: SubscribeNotificationRequest) => message.serialize(), SubscribeNotificationResponse.deserialize);
        Subscribe(message: SubscribeNotificationRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.serverStreaming(this._address + "/core.notification.v1.NotificationAPI/Subscribe", message, metadata || {}, NotificationAPIClient.Subscribe);
        }
    }
}
