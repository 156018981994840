import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent {

  @Input() titleHeader: string;
  @Input() layout: 'info' | 'warning' | 'error' | 'success' | 'default' = 'default';
  @Input() message: string;
  @Input() btnLeft: string;
  @Input() btnRight: string;
  @Input() callBackBtnLeft: () => void;
  @Input() callBackBtnRight: () => void;
  @Input() iconHeader?: string;

  constructor(
    public dialogRef: MatDialogRef<ModalAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titleHeader = data.titleHeader;
    this.layout = data.layout;
    this.message = data.message;
    this.btnLeft = data.btnLeft;
    this.btnRight = data.btnRight;
    this.callBackBtnLeft = data.callBackBtnLeft;
    this.callBackBtnRight = data.callBackBtnRight;
    this.iconHeader = data.iconHeader;
  }

  onBtnLeftClick(): void {
    if (this.callBackBtnLeft) {
      this.callBackBtnLeft();
    }
    this.dialogRef.close();
  }

  onBtnRightClick(): void {
    if (this.callBackBtnRight) {
      this.callBackBtnRight();
    }
    this.dialogRef.close();
  }
}