/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/billing/v1/billing.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as dependency_2 from "./../../transaction/v1/transaction";
import * as pb_1 from "google-protobuf";
export namespace core.billing.v1 {
    export enum TableFeePeriod {
        UNSPECIFIED_TABLE_FEE_PERIOD = 0,
        ANNUALLY_TABLE_FEE_PERIOD = 1,
        SEMI_ANNUALLY_TABLE_FEE_PERIOD = 2,
        MONTHLY_TABLE_FEE_PERIOD = 3,
        BIWEEKLY_TABLE_FEE = 4,
        WEEKLY_TABLE_FEE = 5
    }
    export enum PricingTypes {
        UNSPECIFIED_TYPE = 0,
        BLOCKCHAIN_MINT_TYPE = 1,
        BLOCKCHAIN_WALLET_TYPE = 2,
        BLOCKCHAIN_RANGE_TYPE = 3,
        BLOCKCHAIN_MONTHLY_TYPE = 4
    }
    export enum ProcessingStatus {
        PROCESSING_STATUS_UNSPECIFIED = 0,
        PROCESSING_STATUS_SUCCESS = 1,
        PROCESSING_STATUS_FAIL = 2
    }
    export class Ticket extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            updatedAt?: dependency_1.google.protobuf.Timestamp;
            transaction?: dependency_2.core.transaction.v1.Transaction;
            value?: number;
            companyId?: string;
            reportingId?: string;
            tableFeeId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("updatedAt" in data && data.updatedAt != undefined) {
                    this.updatedAt = data.updatedAt;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("reportingId" in data && data.reportingId != undefined) {
                    this.reportingId = data.reportingId;
                }
                if ("tableFeeId" in data && data.tableFeeId != undefined) {
                    this.tableFeeId = data.tableFeeId;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 2) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get updatedAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set updatedAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasUpdatedAt() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.transaction.v1.Transaction, 4) as dependency_2.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_2.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get reportingId() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set reportingId(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get tableFeeId() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set tableFeeId(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            id?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            updatedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            transaction?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>;
            value?: number;
            companyId?: string;
            reportingId?: string;
            tableFeeId?: string;
        }): Ticket {
            const message = new Ticket({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.updatedAt != null) {
                message.updatedAt = dependency_1.google.protobuf.Timestamp.fromObject(data.updatedAt);
            }
            if (data.transaction != null) {
                message.transaction = dependency_2.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.reportingId != null) {
                message.reportingId = data.reportingId;
            }
            if (data.tableFeeId != null) {
                message.tableFeeId = data.tableFeeId;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                updatedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                transaction?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>;
                value?: number;
                companyId?: string;
                reportingId?: string;
                tableFeeId?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.updatedAt != null) {
                data.updatedAt = this.updatedAt.toObject();
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.reportingId != null) {
                data.reportingId = this.reportingId;
            }
            if (this.tableFeeId != null) {
                data.tableFeeId = this.tableFeeId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.hasCreatedAt)
                writer.writeMessage(2, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.hasUpdatedAt)
                writer.writeMessage(3, this.updatedAt, () => this.updatedAt.serialize(writer));
            if (this.hasTransaction)
                writer.writeMessage(4, this.transaction, () => this.transaction.serialize(writer));
            if (this.value != 0)
                writer.writeInt64(5, this.value);
            if (this.companyId.length)
                writer.writeString(6, this.companyId);
            if (this.reportingId.length)
                writer.writeString(7, this.reportingId);
            if (this.tableFeeId.length)
                writer.writeString(8, this.tableFeeId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Ticket {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Ticket();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.updatedAt, () => message.updatedAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_2.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    case 5:
                        message.value = reader.readInt64();
                        break;
                    case 6:
                        message.companyId = reader.readString();
                        break;
                    case 7:
                        message.reportingId = reader.readString();
                        break;
                    case 8:
                        message.tableFeeId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Ticket {
            return Ticket.deserialize(bytes);
        }
    }
    export class TableFee extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            companyId?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            pricingList?: Map<string, PricingList>;
            tablePeriod?: TableFeePeriod;
            custom?: boolean;
            effectiveDateStart?: dependency_1.google.protobuf.Timestamp;
            effectiveDateEnd?: dependency_1.google.protobuf.Timestamp;
            responsibleParty?: ResponsibleParty;
            current?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("pricingList" in data && data.pricingList != undefined) {
                    this.pricingList = data.pricingList;
                }
                if ("tablePeriod" in data && data.tablePeriod != undefined) {
                    this.tablePeriod = data.tablePeriod;
                }
                if ("custom" in data && data.custom != undefined) {
                    this.custom = data.custom;
                }
                if ("effectiveDateStart" in data && data.effectiveDateStart != undefined) {
                    this.effectiveDateStart = data.effectiveDateStart;
                }
                if ("effectiveDateEnd" in data && data.effectiveDateEnd != undefined) {
                    this.effectiveDateEnd = data.effectiveDateEnd;
                }
                if ("responsibleParty" in data && data.responsibleParty != undefined) {
                    this.responsibleParty = data.responsibleParty;
                }
                if ("current" in data && data.current != undefined) {
                    this.current = data.current;
                }
            }
            if (!this.pricingList)
                this.pricingList = new Map();
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get pricingList() {
            return pb_1.Message.getField(this, 4) as any as Map<string, PricingList>;
        }
        set pricingList(value: Map<string, PricingList>) {
            pb_1.Message.setField(this, 4, value as any);
        }
        get tablePeriod() {
            return pb_1.Message.getFieldWithDefault(this, 5, TableFeePeriod.UNSPECIFIED_TABLE_FEE_PERIOD) as TableFeePeriod;
        }
        set tablePeriod(value: TableFeePeriod) {
            pb_1.Message.setField(this, 5, value);
        }
        get custom() {
            return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
        }
        set custom(value: boolean) {
            pb_1.Message.setField(this, 6, value);
        }
        get effectiveDateStart() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 7) as dependency_1.google.protobuf.Timestamp;
        }
        set effectiveDateStart(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 7, value);
        }
        get hasEffectiveDateStart() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get effectiveDateEnd() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 8) as dependency_1.google.protobuf.Timestamp;
        }
        set effectiveDateEnd(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 8, value);
        }
        get hasEffectiveDateEnd() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get responsibleParty() {
            return pb_1.Message.getWrapperField(this, ResponsibleParty, 9) as ResponsibleParty;
        }
        set responsibleParty(value: ResponsibleParty) {
            pb_1.Message.setWrapperField(this, 9, value);
        }
        get hasResponsibleParty() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get current() {
            return pb_1.Message.getFieldWithDefault(this, 10, false) as boolean;
        }
        set current(value: boolean) {
            pb_1.Message.setField(this, 10, value);
        }
        static fromObject(data: {
            id?: string;
            companyId?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            pricingList?: {
                [key: string]: ReturnType<typeof PricingList.prototype.toObject>;
            };
            tablePeriod?: TableFeePeriod;
            custom?: boolean;
            effectiveDateStart?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            effectiveDateEnd?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            responsibleParty?: ReturnType<typeof ResponsibleParty.prototype.toObject>;
            current?: boolean;
        }): TableFee {
            const message = new TableFee({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (typeof data.pricingList == "object") {
                message.pricingList = new Map(Object.entries(data.pricingList).map(([key, value]) => [key, PricingList.fromObject(value)]));
            }
            if (data.tablePeriod != null) {
                message.tablePeriod = data.tablePeriod;
            }
            if (data.custom != null) {
                message.custom = data.custom;
            }
            if (data.effectiveDateStart != null) {
                message.effectiveDateStart = dependency_1.google.protobuf.Timestamp.fromObject(data.effectiveDateStart);
            }
            if (data.effectiveDateEnd != null) {
                message.effectiveDateEnd = dependency_1.google.protobuf.Timestamp.fromObject(data.effectiveDateEnd);
            }
            if (data.responsibleParty != null) {
                message.responsibleParty = ResponsibleParty.fromObject(data.responsibleParty);
            }
            if (data.current != null) {
                message.current = data.current;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                companyId?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                pricingList?: {
                    [key: string]: ReturnType<typeof PricingList.prototype.toObject>;
                };
                tablePeriod?: TableFeePeriod;
                custom?: boolean;
                effectiveDateStart?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                effectiveDateEnd?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                responsibleParty?: ReturnType<typeof ResponsibleParty.prototype.toObject>;
                current?: boolean;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.pricingList != null) {
                data.pricingList = (Object.fromEntries)((Array.from)(this.pricingList).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.tablePeriod != null) {
                data.tablePeriod = this.tablePeriod;
            }
            if (this.custom != null) {
                data.custom = this.custom;
            }
            if (this.effectiveDateStart != null) {
                data.effectiveDateStart = this.effectiveDateStart.toObject();
            }
            if (this.effectiveDateEnd != null) {
                data.effectiveDateEnd = this.effectiveDateEnd.toObject();
            }
            if (this.responsibleParty != null) {
                data.responsibleParty = this.responsibleParty.toObject();
            }
            if (this.current != null) {
                data.current = this.current;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.companyId.length)
                writer.writeString(2, this.companyId);
            if (this.hasCreatedAt)
                writer.writeMessage(3, this.createdAt, () => this.createdAt.serialize(writer));
            for (const [key, value] of this.pricingList) {
                writer.writeMessage(4, this.pricingList, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.tablePeriod != TableFeePeriod.UNSPECIFIED_TABLE_FEE_PERIOD)
                writer.writeEnum(5, this.tablePeriod);
            if (this.custom != false)
                writer.writeBool(6, this.custom);
            if (this.hasEffectiveDateStart)
                writer.writeMessage(7, this.effectiveDateStart, () => this.effectiveDateStart.serialize(writer));
            if (this.hasEffectiveDateEnd)
                writer.writeMessage(8, this.effectiveDateEnd, () => this.effectiveDateEnd.serialize(writer));
            if (this.hasResponsibleParty)
                writer.writeMessage(9, this.responsibleParty, () => this.responsibleParty.serialize(writer));
            if (this.current != false)
                writer.writeBool(10, this.current);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TableFee {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TableFee();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.companyId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.pricingList as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = PricingList.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 5:
                        message.tablePeriod = reader.readEnum();
                        break;
                    case 6:
                        message.custom = reader.readBool();
                        break;
                    case 7:
                        reader.readMessage(message.effectiveDateStart, () => message.effectiveDateStart = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 8:
                        reader.readMessage(message.effectiveDateEnd, () => message.effectiveDateEnd = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 9:
                        reader.readMessage(message.responsibleParty, () => message.responsibleParty = ResponsibleParty.deserialize(reader));
                        break;
                    case 10:
                        message.current = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TableFee {
            return TableFee.deserialize(bytes);
        }
    }
    export class ResponsibleParty extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sub?: string;
            email?: string;
            ipAddress?: string;
            name?: string;
            username?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sub" in data && data.sub != undefined) {
                    this.sub = data.sub;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("ipAddress" in data && data.ipAddress != undefined) {
                    this.ipAddress = data.ipAddress;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
            }
        }
        get sub() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sub(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get ipAddress() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set ipAddress(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            sub?: string;
            email?: string;
            ipAddress?: string;
            name?: string;
            username?: string;
        }): ResponsibleParty {
            const message = new ResponsibleParty({});
            if (data.sub != null) {
                message.sub = data.sub;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.ipAddress != null) {
                message.ipAddress = data.ipAddress;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.username != null) {
                message.username = data.username;
            }
            return message;
        }
        toObject() {
            const data: {
                sub?: string;
                email?: string;
                ipAddress?: string;
                name?: string;
                username?: string;
            } = {};
            if (this.sub != null) {
                data.sub = this.sub;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.ipAddress != null) {
                data.ipAddress = this.ipAddress;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.username != null) {
                data.username = this.username;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sub.length)
                writer.writeString(1, this.sub);
            if (this.email.length)
                writer.writeString(2, this.email);
            if (this.ipAddress.length)
                writer.writeString(4, this.ipAddress);
            if (this.name.length)
                writer.writeString(5, this.name);
            if (this.username.length)
                writer.writeString(6, this.username);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResponsibleParty {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResponsibleParty();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sub = reader.readString();
                        break;
                    case 2:
                        message.email = reader.readString();
                        break;
                    case 4:
                        message.ipAddress = reader.readString();
                        break;
                    case 5:
                        message.name = reader.readString();
                        break;
                    case 6:
                        message.username = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ResponsibleParty {
            return ResponsibleParty.deserialize(bytes);
        }
    }
    export class TicketBalance extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            pricing?: Map<string, Pricing>;
            validity?: dependency_1.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("pricing" in data && data.pricing != undefined) {
                    this.pricing = data.pricing;
                }
                if ("validity" in data && data.validity != undefined) {
                    this.validity = data.validity;
                }
            }
            if (!this.pricing)
                this.pricing = new Map();
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get pricing() {
            return pb_1.Message.getField(this, 2) as any as Map<string, Pricing>;
        }
        set pricing(value: Map<string, Pricing>) {
            pb_1.Message.setField(this, 2, value as any);
        }
        get validity() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set validity(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasValidity() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            companyId?: string;
            pricing?: {
                [key: string]: ReturnType<typeof Pricing.prototype.toObject>;
            };
            validity?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
        }): TicketBalance {
            const message = new TicketBalance({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (typeof data.pricing == "object") {
                message.pricing = new Map(Object.entries(data.pricing).map(([key, value]) => [key, Pricing.fromObject(value)]));
            }
            if (data.validity != null) {
                message.validity = dependency_1.google.protobuf.Timestamp.fromObject(data.validity);
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                pricing?: {
                    [key: string]: ReturnType<typeof Pricing.prototype.toObject>;
                };
                validity?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.pricing != null) {
                data.pricing = (Object.fromEntries)((Array.from)(this.pricing).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.validity != null) {
                data.validity = this.validity.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            for (const [key, value] of this.pricing) {
                writer.writeMessage(2, this.pricing, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.hasValidity)
                writer.writeMessage(3, this.validity, () => this.validity.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TicketBalance {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TicketBalance();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.pricing as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = Pricing.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 3:
                        reader.readMessage(message.validity, () => message.validity = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TicketBalance {
            return TicketBalance.deserialize(bytes);
        }
    }
    export class PricingList extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            pricing?: Pricing[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("pricing" in data && data.pricing != undefined) {
                    this.pricing = data.pricing;
                }
            }
        }
        get pricing() {
            return pb_1.Message.getRepeatedWrapperField(this, Pricing, 1) as Pricing[];
        }
        set pricing(value: Pricing[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            pricing?: ReturnType<typeof Pricing.prototype.toObject>[];
        }): PricingList {
            const message = new PricingList({});
            if (data.pricing != null) {
                message.pricing = data.pricing.map(item => Pricing.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                pricing?: ReturnType<typeof Pricing.prototype.toObject>[];
            } = {};
            if (this.pricing != null) {
                data.pricing = this.pricing.map((item: Pricing) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.pricing.length)
                writer.writeRepeatedMessage(1, this.pricing, (item: Pricing) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PricingList {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PricingList();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.pricing, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Pricing.deserialize(reader), Pricing));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PricingList {
            return PricingList.deserialize(bytes);
        }
    }
    export class Pricing extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            min?: number;
            max?: number;
            value?: number;
            registeredValue?: number;
            discountPercent?: number;
            quantity?: number;
            packagePrice?: number;
            spent?: number;
            changeStatus?: ChangeStatus;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("min" in data && data.min != undefined) {
                    this.min = data.min;
                }
                if ("max" in data && data.max != undefined) {
                    this.max = data.max;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("registeredValue" in data && data.registeredValue != undefined) {
                    this.registeredValue = data.registeredValue;
                }
                if ("discountPercent" in data && data.discountPercent != undefined) {
                    this.discountPercent = data.discountPercent;
                }
                if ("quantity" in data && data.quantity != undefined) {
                    this.quantity = data.quantity;
                }
                if ("packagePrice" in data && data.packagePrice != undefined) {
                    this.packagePrice = data.packagePrice;
                }
                if ("spent" in data && data.spent != undefined) {
                    this.spent = data.spent;
                }
                if ("changeStatus" in data && data.changeStatus != undefined) {
                    this.changeStatus = data.changeStatus;
                }
            }
        }
        get min() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set min(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get max() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set max(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get registeredValue() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set registeredValue(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get discountPercent() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set discountPercent(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get quantity() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set quantity(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get packagePrice() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set packagePrice(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get spent() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set spent(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get changeStatus() {
            return pb_1.Message.getWrapperField(this, ChangeStatus, 9) as ChangeStatus;
        }
        set changeStatus(value: ChangeStatus) {
            pb_1.Message.setWrapperField(this, 9, value);
        }
        get hasChangeStatus() {
            return pb_1.Message.getField(this, 9) != null;
        }
        static fromObject(data: {
            min?: number;
            max?: number;
            value?: number;
            registeredValue?: number;
            discountPercent?: number;
            quantity?: number;
            packagePrice?: number;
            spent?: number;
            changeStatus?: ReturnType<typeof ChangeStatus.prototype.toObject>;
        }): Pricing {
            const message = new Pricing({});
            if (data.min != null) {
                message.min = data.min;
            }
            if (data.max != null) {
                message.max = data.max;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.registeredValue != null) {
                message.registeredValue = data.registeredValue;
            }
            if (data.discountPercent != null) {
                message.discountPercent = data.discountPercent;
            }
            if (data.quantity != null) {
                message.quantity = data.quantity;
            }
            if (data.packagePrice != null) {
                message.packagePrice = data.packagePrice;
            }
            if (data.spent != null) {
                message.spent = data.spent;
            }
            if (data.changeStatus != null) {
                message.changeStatus = ChangeStatus.fromObject(data.changeStatus);
            }
            return message;
        }
        toObject() {
            const data: {
                min?: number;
                max?: number;
                value?: number;
                registeredValue?: number;
                discountPercent?: number;
                quantity?: number;
                packagePrice?: number;
                spent?: number;
                changeStatus?: ReturnType<typeof ChangeStatus.prototype.toObject>;
            } = {};
            if (this.min != null) {
                data.min = this.min;
            }
            if (this.max != null) {
                data.max = this.max;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.registeredValue != null) {
                data.registeredValue = this.registeredValue;
            }
            if (this.discountPercent != null) {
                data.discountPercent = this.discountPercent;
            }
            if (this.quantity != null) {
                data.quantity = this.quantity;
            }
            if (this.packagePrice != null) {
                data.packagePrice = this.packagePrice;
            }
            if (this.spent != null) {
                data.spent = this.spent;
            }
            if (this.changeStatus != null) {
                data.changeStatus = this.changeStatus.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.min != 0)
                writer.writeInt64(1, this.min);
            if (this.max != 0)
                writer.writeInt64(2, this.max);
            if (this.value != 0)
                writer.writeInt64(3, this.value);
            if (this.registeredValue != 0)
                writer.writeInt64(4, this.registeredValue);
            if (this.discountPercent != 0)
                writer.writeInt64(5, this.discountPercent);
            if (this.quantity != 0)
                writer.writeInt64(6, this.quantity);
            if (this.packagePrice != 0)
                writer.writeInt64(7, this.packagePrice);
            if (this.spent != 0)
                writer.writeInt64(8, this.spent);
            if (this.hasChangeStatus)
                writer.writeMessage(9, this.changeStatus, () => this.changeStatus.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Pricing {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Pricing();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.min = reader.readInt64();
                        break;
                    case 2:
                        message.max = reader.readInt64();
                        break;
                    case 3:
                        message.value = reader.readInt64();
                        break;
                    case 4:
                        message.registeredValue = reader.readInt64();
                        break;
                    case 5:
                        message.discountPercent = reader.readInt64();
                        break;
                    case 6:
                        message.quantity = reader.readInt64();
                        break;
                    case 7:
                        message.packagePrice = reader.readInt64();
                        break;
                    case 8:
                        message.spent = reader.readInt64();
                        break;
                    case 9:
                        reader.readMessage(message.changeStatus, () => message.changeStatus = ChangeStatus.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Pricing {
            return Pricing.deserialize(bytes);
        }
    }
    export class ChangeStatus extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            maxChanged?: boolean;
            minChanged?: boolean;
            valueChanged?: boolean;
            percentDiscountChanged?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("maxChanged" in data && data.maxChanged != undefined) {
                    this.maxChanged = data.maxChanged;
                }
                if ("minChanged" in data && data.minChanged != undefined) {
                    this.minChanged = data.minChanged;
                }
                if ("valueChanged" in data && data.valueChanged != undefined) {
                    this.valueChanged = data.valueChanged;
                }
                if ("percentDiscountChanged" in data && data.percentDiscountChanged != undefined) {
                    this.percentDiscountChanged = data.percentDiscountChanged;
                }
            }
        }
        get maxChanged() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set maxChanged(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        get minChanged() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set minChanged(value: boolean) {
            pb_1.Message.setField(this, 2, value);
        }
        get valueChanged() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set valueChanged(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        get percentDiscountChanged() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set percentDiscountChanged(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            maxChanged?: boolean;
            minChanged?: boolean;
            valueChanged?: boolean;
            percentDiscountChanged?: boolean;
        }): ChangeStatus {
            const message = new ChangeStatus({});
            if (data.maxChanged != null) {
                message.maxChanged = data.maxChanged;
            }
            if (data.minChanged != null) {
                message.minChanged = data.minChanged;
            }
            if (data.valueChanged != null) {
                message.valueChanged = data.valueChanged;
            }
            if (data.percentDiscountChanged != null) {
                message.percentDiscountChanged = data.percentDiscountChanged;
            }
            return message;
        }
        toObject() {
            const data: {
                maxChanged?: boolean;
                minChanged?: boolean;
                valueChanged?: boolean;
                percentDiscountChanged?: boolean;
            } = {};
            if (this.maxChanged != null) {
                data.maxChanged = this.maxChanged;
            }
            if (this.minChanged != null) {
                data.minChanged = this.minChanged;
            }
            if (this.valueChanged != null) {
                data.valueChanged = this.valueChanged;
            }
            if (this.percentDiscountChanged != null) {
                data.percentDiscountChanged = this.percentDiscountChanged;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.maxChanged != false)
                writer.writeBool(1, this.maxChanged);
            if (this.minChanged != false)
                writer.writeBool(2, this.minChanged);
            if (this.valueChanged != false)
                writer.writeBool(3, this.valueChanged);
            if (this.percentDiscountChanged != false)
                writer.writeBool(4, this.percentDiscountChanged);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ChangeStatus {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ChangeStatus();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.maxChanged = reader.readBool();
                        break;
                    case 2:
                        message.minChanged = reader.readBool();
                        break;
                    case 3:
                        message.valueChanged = reader.readBool();
                        break;
                    case 4:
                        message.percentDiscountChanged = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ChangeStatus {
            return ChangeStatus.deserialize(bytes);
        }
    }
    export class TicketSummary extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tickets?: Ticket[];
            transactionsValue?: number;
            taxesValue?: number;
            summaryRecords?: SummaryRecord[];
            summaryWallets?: SummaryWallet[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 4, 5], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tickets" in data && data.tickets != undefined) {
                    this.tickets = data.tickets;
                }
                if ("transactionsValue" in data && data.transactionsValue != undefined) {
                    this.transactionsValue = data.transactionsValue;
                }
                if ("taxesValue" in data && data.taxesValue != undefined) {
                    this.taxesValue = data.taxesValue;
                }
                if ("summaryRecords" in data && data.summaryRecords != undefined) {
                    this.summaryRecords = data.summaryRecords;
                }
                if ("summaryWallets" in data && data.summaryWallets != undefined) {
                    this.summaryWallets = data.summaryWallets;
                }
            }
        }
        get tickets() {
            return pb_1.Message.getRepeatedWrapperField(this, Ticket, 1) as Ticket[];
        }
        set tickets(value: Ticket[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get transactionsValue() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set transactionsValue(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get taxesValue() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set taxesValue(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get summaryRecords() {
            return pb_1.Message.getRepeatedWrapperField(this, SummaryRecord, 4) as SummaryRecord[];
        }
        set summaryRecords(value: SummaryRecord[]) {
            pb_1.Message.setRepeatedWrapperField(this, 4, value);
        }
        get summaryWallets() {
            return pb_1.Message.getRepeatedWrapperField(this, SummaryWallet, 5) as SummaryWallet[];
        }
        set summaryWallets(value: SummaryWallet[]) {
            pb_1.Message.setRepeatedWrapperField(this, 5, value);
        }
        static fromObject(data: {
            tickets?: ReturnType<typeof Ticket.prototype.toObject>[];
            transactionsValue?: number;
            taxesValue?: number;
            summaryRecords?: ReturnType<typeof SummaryRecord.prototype.toObject>[];
            summaryWallets?: ReturnType<typeof SummaryWallet.prototype.toObject>[];
        }): TicketSummary {
            const message = new TicketSummary({});
            if (data.tickets != null) {
                message.tickets = data.tickets.map(item => Ticket.fromObject(item));
            }
            if (data.transactionsValue != null) {
                message.transactionsValue = data.transactionsValue;
            }
            if (data.taxesValue != null) {
                message.taxesValue = data.taxesValue;
            }
            if (data.summaryRecords != null) {
                message.summaryRecords = data.summaryRecords.map(item => SummaryRecord.fromObject(item));
            }
            if (data.summaryWallets != null) {
                message.summaryWallets = data.summaryWallets.map(item => SummaryWallet.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                tickets?: ReturnType<typeof Ticket.prototype.toObject>[];
                transactionsValue?: number;
                taxesValue?: number;
                summaryRecords?: ReturnType<typeof SummaryRecord.prototype.toObject>[];
                summaryWallets?: ReturnType<typeof SummaryWallet.prototype.toObject>[];
            } = {};
            if (this.tickets != null) {
                data.tickets = this.tickets.map((item: Ticket) => item.toObject());
            }
            if (this.transactionsValue != null) {
                data.transactionsValue = this.transactionsValue;
            }
            if (this.taxesValue != null) {
                data.taxesValue = this.taxesValue;
            }
            if (this.summaryRecords != null) {
                data.summaryRecords = this.summaryRecords.map((item: SummaryRecord) => item.toObject());
            }
            if (this.summaryWallets != null) {
                data.summaryWallets = this.summaryWallets.map((item: SummaryWallet) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tickets.length)
                writer.writeRepeatedMessage(1, this.tickets, (item: Ticket) => item.serialize(writer));
            if (this.transactionsValue != 0)
                writer.writeInt64(2, this.transactionsValue);
            if (this.taxesValue != 0)
                writer.writeInt64(3, this.taxesValue);
            if (this.summaryRecords.length)
                writer.writeRepeatedMessage(4, this.summaryRecords, (item: SummaryRecord) => item.serialize(writer));
            if (this.summaryWallets.length)
                writer.writeRepeatedMessage(5, this.summaryWallets, (item: SummaryWallet) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TicketSummary {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TicketSummary();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.tickets, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Ticket.deserialize(reader), Ticket));
                        break;
                    case 2:
                        message.transactionsValue = reader.readInt64();
                        break;
                    case 3:
                        message.taxesValue = reader.readInt64();
                        break;
                    case 4:
                        reader.readMessage(message.summaryRecords, () => pb_1.Message.addToRepeatedWrapperField(message, 4, SummaryRecord.deserialize(reader), SummaryRecord));
                        break;
                    case 5:
                        reader.readMessage(message.summaryWallets, () => pb_1.Message.addToRepeatedWrapperField(message, 5, SummaryWallet.deserialize(reader), SummaryWallet));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TicketSummary {
            return TicketSummary.deserialize(bytes);
        }
    }
    export class SummaryWallet extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            quantity?: number;
            companyId?: string;
            value?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("quantity" in data && data.quantity != undefined) {
                    this.quantity = data.quantity;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
        }
        get quantity() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set quantity(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            quantity?: number;
            companyId?: string;
            value?: number;
        }): SummaryWallet {
            const message = new SummaryWallet({});
            if (data.quantity != null) {
                message.quantity = data.quantity;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: {
                quantity?: number;
                companyId?: string;
                value?: number;
            } = {};
            if (this.quantity != null) {
                data.quantity = this.quantity;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.quantity != 0)
                writer.writeInt64(1, this.quantity);
            if (this.companyId.length)
                writer.writeString(2, this.companyId);
            if (this.value != 0)
                writer.writeInt64(3, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SummaryWallet {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SummaryWallet();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.quantity = reader.readInt64();
                        break;
                    case 2:
                        message.companyId = reader.readString();
                        break;
                    case 3:
                        message.value = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SummaryWallet {
            return SummaryWallet.deserialize(bytes);
        }
    }
    export class SummaryRecord extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionType?: dependency_2.core.transaction.v1.TransactionType;
            total?: number;
            value?: number;
            tax?: number;
            startDate?: dependency_1.google.protobuf.Timestamp;
            endDate?: dependency_1.google.protobuf.Timestamp;
            companyId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("total" in data && data.total != undefined) {
                    this.total = data.total;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("tax" in data && data.tax != undefined) {
                    this.tax = data.tax;
                }
                if ("startDate" in data && data.startDate != undefined) {
                    this.startDate = data.startDate;
                }
                if ("endDate" in data && data.endDate != undefined) {
                    this.endDate = data.endDate;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
            }
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_2.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as dependency_2.core.transaction.v1.TransactionType;
        }
        set transactionType(value: dependency_2.core.transaction.v1.TransactionType) {
            pb_1.Message.setField(this, 1, value);
        }
        get total() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set total(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get tax() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set tax(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get startDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 5) as dependency_1.google.protobuf.Timestamp;
        }
        set startDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasStartDate() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get endDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 6) as dependency_1.google.protobuf.Timestamp;
        }
        set endDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 6, value);
        }
        get hasEndDate() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            transactionType?: dependency_2.core.transaction.v1.TransactionType;
            total?: number;
            value?: number;
            tax?: number;
            startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            companyId?: string;
        }): SummaryRecord {
            const message = new SummaryRecord({});
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (data.total != null) {
                message.total = data.total;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.tax != null) {
                message.tax = data.tax;
            }
            if (data.startDate != null) {
                message.startDate = dependency_1.google.protobuf.Timestamp.fromObject(data.startDate);
            }
            if (data.endDate != null) {
                message.endDate = dependency_1.google.protobuf.Timestamp.fromObject(data.endDate);
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionType?: dependency_2.core.transaction.v1.TransactionType;
                total?: number;
                value?: number;
                tax?: number;
                startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                companyId?: string;
            } = {};
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.total != null) {
                data.total = this.total;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.tax != null) {
                data.tax = this.tax;
            }
            if (this.startDate != null) {
                data.startDate = this.startDate.toObject();
            }
            if (this.endDate != null) {
                data.endDate = this.endDate.toObject();
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionType != dependency_2.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(1, this.transactionType);
            if (this.total != 0)
                writer.writeInt64(2, this.total);
            if (this.value != 0)
                writer.writeInt64(3, this.value);
            if (this.tax != 0)
                writer.writeInt64(4, this.tax);
            if (this.hasStartDate)
                writer.writeMessage(5, this.startDate, () => this.startDate.serialize(writer));
            if (this.hasEndDate)
                writer.writeMessage(6, this.endDate, () => this.endDate.serialize(writer));
            if (this.companyId.length)
                writer.writeString(7, this.companyId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SummaryRecord {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SummaryRecord();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionType = reader.readEnum();
                        break;
                    case 2:
                        message.total = reader.readInt64();
                        break;
                    case 3:
                        message.value = reader.readInt64();
                        break;
                    case 4:
                        message.tax = reader.readInt64();
                        break;
                    case 5:
                        reader.readMessage(message.startDate, () => message.startDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.endDate, () => message.endDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 7:
                        message.companyId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SummaryRecord {
            return SummaryRecord.deserialize(bytes);
        }
    }
    export class BillingReport extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            filename?: string;
            startDate?: dependency_1.google.protobuf.Timestamp;
            endDate?: dependency_1.google.protobuf.Timestamp;
            processedAt?: dependency_1.google.protobuf.Timestamp;
            status?: ProcessingStatus;
            errorDescription?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            updatedAt?: dependency_1.google.protobuf.Timestamp;
            walletsDetails?: WalletDetail[];
            subscriptionFeeDetails?: SubscriptionFeeDetail[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [10, 11], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("filename" in data && data.filename != undefined) {
                    this.filename = data.filename;
                }
                if ("startDate" in data && data.startDate != undefined) {
                    this.startDate = data.startDate;
                }
                if ("endDate" in data && data.endDate != undefined) {
                    this.endDate = data.endDate;
                }
                if ("processedAt" in data && data.processedAt != undefined) {
                    this.processedAt = data.processedAt;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("errorDescription" in data && data.errorDescription != undefined) {
                    this.errorDescription = data.errorDescription;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("updatedAt" in data && data.updatedAt != undefined) {
                    this.updatedAt = data.updatedAt;
                }
                if ("walletsDetails" in data && data.walletsDetails != undefined) {
                    this.walletsDetails = data.walletsDetails;
                }
                if ("subscriptionFeeDetails" in data && data.subscriptionFeeDetails != undefined) {
                    this.subscriptionFeeDetails = data.subscriptionFeeDetails;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get filename() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set filename(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get startDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set startDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasStartDate() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get endDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 4) as dependency_1.google.protobuf.Timestamp;
        }
        set endDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasEndDate() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get processedAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 5) as dependency_1.google.protobuf.Timestamp;
        }
        set processedAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasProcessedAt() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 6, ProcessingStatus.PROCESSING_STATUS_UNSPECIFIED) as ProcessingStatus;
        }
        set status(value: ProcessingStatus) {
            pb_1.Message.setField(this, 6, value);
        }
        get errorDescription() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set errorDescription(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 8) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 8, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get updatedAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 9) as dependency_1.google.protobuf.Timestamp;
        }
        set updatedAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 9, value);
        }
        get hasUpdatedAt() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get walletsDetails() {
            return pb_1.Message.getRepeatedWrapperField(this, WalletDetail, 10) as WalletDetail[];
        }
        set walletsDetails(value: WalletDetail[]) {
            pb_1.Message.setRepeatedWrapperField(this, 10, value);
        }
        get subscriptionFeeDetails() {
            return pb_1.Message.getRepeatedWrapperField(this, SubscriptionFeeDetail, 11) as SubscriptionFeeDetail[];
        }
        set subscriptionFeeDetails(value: SubscriptionFeeDetail[]) {
            pb_1.Message.setRepeatedWrapperField(this, 11, value);
        }
        static fromObject(data: {
            id?: string;
            filename?: string;
            startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            processedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            status?: ProcessingStatus;
            errorDescription?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            updatedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            walletsDetails?: ReturnType<typeof WalletDetail.prototype.toObject>[];
            subscriptionFeeDetails?: ReturnType<typeof SubscriptionFeeDetail.prototype.toObject>[];
        }): BillingReport {
            const message = new BillingReport({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.filename != null) {
                message.filename = data.filename;
            }
            if (data.startDate != null) {
                message.startDate = dependency_1.google.protobuf.Timestamp.fromObject(data.startDate);
            }
            if (data.endDate != null) {
                message.endDate = dependency_1.google.protobuf.Timestamp.fromObject(data.endDate);
            }
            if (data.processedAt != null) {
                message.processedAt = dependency_1.google.protobuf.Timestamp.fromObject(data.processedAt);
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.errorDescription != null) {
                message.errorDescription = data.errorDescription;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.updatedAt != null) {
                message.updatedAt = dependency_1.google.protobuf.Timestamp.fromObject(data.updatedAt);
            }
            if (data.walletsDetails != null) {
                message.walletsDetails = data.walletsDetails.map(item => WalletDetail.fromObject(item));
            }
            if (data.subscriptionFeeDetails != null) {
                message.subscriptionFeeDetails = data.subscriptionFeeDetails.map(item => SubscriptionFeeDetail.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                filename?: string;
                startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                processedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                status?: ProcessingStatus;
                errorDescription?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                updatedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                walletsDetails?: ReturnType<typeof WalletDetail.prototype.toObject>[];
                subscriptionFeeDetails?: ReturnType<typeof SubscriptionFeeDetail.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.filename != null) {
                data.filename = this.filename;
            }
            if (this.startDate != null) {
                data.startDate = this.startDate.toObject();
            }
            if (this.endDate != null) {
                data.endDate = this.endDate.toObject();
            }
            if (this.processedAt != null) {
                data.processedAt = this.processedAt.toObject();
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.errorDescription != null) {
                data.errorDescription = this.errorDescription;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.updatedAt != null) {
                data.updatedAt = this.updatedAt.toObject();
            }
            if (this.walletsDetails != null) {
                data.walletsDetails = this.walletsDetails.map((item: WalletDetail) => item.toObject());
            }
            if (this.subscriptionFeeDetails != null) {
                data.subscriptionFeeDetails = this.subscriptionFeeDetails.map((item: SubscriptionFeeDetail) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.filename.length)
                writer.writeString(2, this.filename);
            if (this.hasStartDate)
                writer.writeMessage(3, this.startDate, () => this.startDate.serialize(writer));
            if (this.hasEndDate)
                writer.writeMessage(4, this.endDate, () => this.endDate.serialize(writer));
            if (this.hasProcessedAt)
                writer.writeMessage(5, this.processedAt, () => this.processedAt.serialize(writer));
            if (this.status != ProcessingStatus.PROCESSING_STATUS_UNSPECIFIED)
                writer.writeEnum(6, this.status);
            if (this.errorDescription.length)
                writer.writeString(7, this.errorDescription);
            if (this.hasCreatedAt)
                writer.writeMessage(8, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.hasUpdatedAt)
                writer.writeMessage(9, this.updatedAt, () => this.updatedAt.serialize(writer));
            if (this.walletsDetails.length)
                writer.writeRepeatedMessage(10, this.walletsDetails, (item: WalletDetail) => item.serialize(writer));
            if (this.subscriptionFeeDetails.length)
                writer.writeRepeatedMessage(11, this.subscriptionFeeDetails, (item: SubscriptionFeeDetail) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BillingReport {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BillingReport();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.filename = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.startDate, () => message.startDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.endDate, () => message.endDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.processedAt, () => message.processedAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 6:
                        message.status = reader.readEnum();
                        break;
                    case 7:
                        message.errorDescription = reader.readString();
                        break;
                    case 8:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 9:
                        reader.readMessage(message.updatedAt, () => message.updatedAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 10:
                        reader.readMessage(message.walletsDetails, () => pb_1.Message.addToRepeatedWrapperField(message, 10, WalletDetail.deserialize(reader), WalletDetail));
                        break;
                    case 11:
                        reader.readMessage(message.subscriptionFeeDetails, () => pb_1.Message.addToRepeatedWrapperField(message, 11, SubscriptionFeeDetail.deserialize(reader), SubscriptionFeeDetail));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BillingReport {
            return BillingReport.deserialize(bytes);
        }
    }
    export class WalletDetail extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            walletsCount?: number;
            totalValue?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("walletsCount" in data && data.walletsCount != undefined) {
                    this.walletsCount = data.walletsCount;
                }
                if ("totalValue" in data && data.totalValue != undefined) {
                    this.totalValue = data.totalValue;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get walletsCount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set walletsCount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get totalValue() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set totalValue(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            companyId?: string;
            walletsCount?: number;
            totalValue?: number;
        }): WalletDetail {
            const message = new WalletDetail({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.walletsCount != null) {
                message.walletsCount = data.walletsCount;
            }
            if (data.totalValue != null) {
                message.totalValue = data.totalValue;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                walletsCount?: number;
                totalValue?: number;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.walletsCount != null) {
                data.walletsCount = this.walletsCount;
            }
            if (this.totalValue != null) {
                data.totalValue = this.totalValue;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.walletsCount != 0)
                writer.writeInt64(2, this.walletsCount);
            if (this.totalValue != 0)
                writer.writeInt64(3, this.totalValue);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WalletDetail {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WalletDetail();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.walletsCount = reader.readInt64();
                        break;
                    case 3:
                        message.totalValue = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): WalletDetail {
            return WalletDetail.deserialize(bytes);
        }
    }
    export class SubscriptionFeeDetail extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            totalValue?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("totalValue" in data && data.totalValue != undefined) {
                    this.totalValue = data.totalValue;
                }
            }
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get totalValue() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set totalValue(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            companyId?: string;
            totalValue?: number;
        }): SubscriptionFeeDetail {
            const message = new SubscriptionFeeDetail({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.totalValue != null) {
                message.totalValue = data.totalValue;
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                totalValue?: number;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.totalValue != null) {
                data.totalValue = this.totalValue;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            if (this.totalValue != 0)
                writer.writeInt64(2, this.totalValue);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubscriptionFeeDetail {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubscriptionFeeDetail();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        message.totalValue = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SubscriptionFeeDetail {
            return SubscriptionFeeDetail.deserialize(bytes);
        }
    }
}
