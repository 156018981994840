import { AppName } from "./models/appName";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { Inject, Injectable } from "@angular/core";
import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { User } from "./models/user";
import { environment } from "./environments/environment";

export const LOAD_DATA_WITH_USER = new InjectionToken<(user: User) => Observable<any>>("LOAD_DATA_WITH_USER");
export const RESET_DATA = new InjectionToken<() => void>("RESET_DATA");
export const APP_NAME = new InjectionToken<string>("APP_NAME");
export const NUCLEA_ID = "271b0f17-0cd3-4d88-b410-3b96144b71c1";

@Injectable({
  providedIn: "root",
})
export class MainService {
  private readonly environmentConfigs = environment;

  private _endpointBase: string;
  private _userPool: CognitoUserPool;
  private _application: AppName;
  private _currentUrl: string;

  constructor(@Inject(APP_NAME) appName: string, public router: Router) {
    this._application = appName as AppName;
    this._currentUrl = window.location.origin;
    this.initializeEnvironment();
    this.logDetails();
  }

  private initializeEnvironment(): void {
    const envType = this.determineEnvironment();
    const config = this.environmentConfigs[this.isNucleaUrl() ? "nuclea" : "mobiup"];
    // this._endpointBase = "http://localhost:8208"
    this._endpointBase = config.base_url[envType];
    this._userPool = new CognitoUserPool(config.pool[this.application][envType]);
  }

  private determineEnvironment(): string {
    if (
      !this.currentUrl.includes("hint") &&
      !this.currentUrl.includes("hml") &&
      !this.currentUrl.includes("hext") &&
      !this.currentUrl.includes("dev") &&
      !this.currentUrl.includes("homolog") &&
      !this.currentUrl.includes("localhost")
    ) {
      this.clearLogs();
      return "production";
    }
    if (this.currentUrl.includes("hint")) {
      return "homolog_int";
    }
    if (this.currentUrl.includes("hml") || this.currentUrl.includes("homolog") || this.currentUrl.includes("hext")) {
      this.clearLogs();
      return "homolog";
    }
    // return "homolog"; //@todo: change to development after tests
    return "development";
  }

  private isNucleaUrl(): boolean {
    return this._currentUrl.includes("s3") || this._currentUrl.includes("nucleachain");
  }

  private logDetails(): void {
    console.log("APP: ", this._application);
    console.log("CurrentURL: ", this._currentUrl);
    console.log("EndpointBase: ", this._endpointBase);
    console.log("UserPool: ", this._userPool);
  }

  private clearLogs() {
    window.console.log = () => {};
    window.console.warn = () => {};
    window.console.info = () => {};
  }

  get endpointBase(): string {
    return this._endpointBase;
  }

  get userPool(): CognitoUserPool {
    return this._userPool;
  }

  get application(): AppName {
    return this._application;
  }

  get currentUrl(): string {
    return this._currentUrl;
  }

  get recaptchaSiteKey(): string {
    return this.environmentConfigs[this.isNucleaUrl() ? "nuclea" : "mobiup"].recaptcha.siteKey;
  }
}
