import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { APP_NAME, LOAD_DATA_WITH_USER, MainService, RESET_DATA } from "@libs/main.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataService } from "@services/data.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom([BrowserAnimationsModule]),
    provideAnimationsAsync(),
    MatSnackBar,
    {
      provide: APP_NAME,
      useValue: "token",
    },
    {
      provide: LOAD_DATA_WITH_USER,
      useFactory: (dataService: DataService) => dataService.loadDataWithUser.bind(dataService),
      deps: [DataService],
    },
    {
      provide: RESET_DATA,
      useFactory: (dataService: DataService) => dataService.resetData.bind(dataService),
      deps: [DataService],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      deps: [MainService],
      useFactory: (mainService: MainService) => mainService.recaptchaSiteKey,
    },
  ],
};
