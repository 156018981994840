/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/account/v1/account.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as dependency_2 from "./../../custom_attributes";
import * as dependency_3 from "./../../profile/v1/profile";
import * as pb_1 from "google-protobuf";
export namespace core.account.v1 {
    export class User extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sub?: string;
            name?: string;
            email?: string;
            companyId?: string;
            username?: string;
            permissions?: string;
            status?: string;
            enabled?: boolean;
            network?: boolean;
            template?: boolean;
            transfer?: boolean;
            users?: boolean;
            workflow?: boolean;
            scope?: string;
            profile?: string;
            document?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sub" in data && data.sub != undefined) {
                    this.sub = data.sub;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
                if ("permissions" in data && data.permissions != undefined) {
                    this.permissions = data.permissions;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("enabled" in data && data.enabled != undefined) {
                    this.enabled = data.enabled;
                }
                if ("network" in data && data.network != undefined) {
                    this.network = data.network;
                }
                if ("template" in data && data.template != undefined) {
                    this.template = data.template;
                }
                if ("transfer" in data && data.transfer != undefined) {
                    this.transfer = data.transfer;
                }
                if ("users" in data && data.users != undefined) {
                    this.users = data.users;
                }
                if ("workflow" in data && data.workflow != undefined) {
                    this.workflow = data.workflow;
                }
                if ("scope" in data && data.scope != undefined) {
                    this.scope = data.scope;
                }
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
            }
        }
        get sub() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sub(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get permissions() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set permissions(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set status(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get enabled() {
            return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
        }
        set enabled(value: boolean) {
            pb_1.Message.setField(this, 8, value);
        }
        get network() {
            return pb_1.Message.getFieldWithDefault(this, 9, false) as boolean;
        }
        set network(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        get template() {
            return pb_1.Message.getFieldWithDefault(this, 10, false) as boolean;
        }
        set template(value: boolean) {
            pb_1.Message.setField(this, 10, value);
        }
        get transfer() {
            return pb_1.Message.getFieldWithDefault(this, 11, false) as boolean;
        }
        set transfer(value: boolean) {
            pb_1.Message.setField(this, 11, value);
        }
        get users() {
            return pb_1.Message.getFieldWithDefault(this, 12, false) as boolean;
        }
        set users(value: boolean) {
            pb_1.Message.setField(this, 12, value);
        }
        get workflow() {
            return pb_1.Message.getFieldWithDefault(this, 13, false) as boolean;
        }
        set workflow(value: boolean) {
            pb_1.Message.setField(this, 13, value);
        }
        get scope() {
            return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
        }
        set scope(value: string) {
            pb_1.Message.setField(this, 14, value);
        }
        get profile() {
            return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
        }
        set profile(value: string) {
            pb_1.Message.setField(this, 15, value);
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 16, value);
        }
        static fromObject(data: {
            sub?: string;
            name?: string;
            email?: string;
            companyId?: string;
            username?: string;
            permissions?: string;
            status?: string;
            enabled?: boolean;
            network?: boolean;
            template?: boolean;
            transfer?: boolean;
            users?: boolean;
            workflow?: boolean;
            scope?: string;
            profile?: string;
            document?: string;
        }): User {
            const message = new User({});
            if (data.sub != null) {
                message.sub = data.sub;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.username != null) {
                message.username = data.username;
            }
            if (data.permissions != null) {
                message.permissions = data.permissions;
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.enabled != null) {
                message.enabled = data.enabled;
            }
            if (data.network != null) {
                message.network = data.network;
            }
            if (data.template != null) {
                message.template = data.template;
            }
            if (data.transfer != null) {
                message.transfer = data.transfer;
            }
            if (data.users != null) {
                message.users = data.users;
            }
            if (data.workflow != null) {
                message.workflow = data.workflow;
            }
            if (data.scope != null) {
                message.scope = data.scope;
            }
            if (data.profile != null) {
                message.profile = data.profile;
            }
            if (data.document != null) {
                message.document = data.document;
            }
            return message;
        }
        toObject() {
            const data: {
                sub?: string;
                name?: string;
                email?: string;
                companyId?: string;
                username?: string;
                permissions?: string;
                status?: string;
                enabled?: boolean;
                network?: boolean;
                template?: boolean;
                transfer?: boolean;
                users?: boolean;
                workflow?: boolean;
                scope?: string;
                profile?: string;
                document?: string;
            } = {};
            if (this.sub != null) {
                data.sub = this.sub;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.username != null) {
                data.username = this.username;
            }
            if (this.permissions != null) {
                data.permissions = this.permissions;
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.enabled != null) {
                data.enabled = this.enabled;
            }
            if (this.network != null) {
                data.network = this.network;
            }
            if (this.template != null) {
                data.template = this.template;
            }
            if (this.transfer != null) {
                data.transfer = this.transfer;
            }
            if (this.users != null) {
                data.users = this.users;
            }
            if (this.workflow != null) {
                data.workflow = this.workflow;
            }
            if (this.scope != null) {
                data.scope = this.scope;
            }
            if (this.profile != null) {
                data.profile = this.profile;
            }
            if (this.document != null) {
                data.document = this.document;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sub.length)
                writer.writeString(1, this.sub);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.email.length)
                writer.writeString(3, this.email);
            if (this.companyId.length)
                writer.writeString(4, this.companyId);
            if (this.username.length)
                writer.writeString(5, this.username);
            if (this.permissions.length)
                writer.writeString(6, this.permissions);
            if (this.status.length)
                writer.writeString(7, this.status);
            if (this.enabled != false)
                writer.writeBool(8, this.enabled);
            if (this.network != false)
                writer.writeBool(9, this.network);
            if (this.template != false)
                writer.writeBool(10, this.template);
            if (this.transfer != false)
                writer.writeBool(11, this.transfer);
            if (this.users != false)
                writer.writeBool(12, this.users);
            if (this.workflow != false)
                writer.writeBool(13, this.workflow);
            if (this.scope.length)
                writer.writeString(14, this.scope);
            if (this.profile.length)
                writer.writeString(15, this.profile);
            if (this.document.length)
                writer.writeString(16, this.document);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): User {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new User();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sub = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.email = reader.readString();
                        break;
                    case 4:
                        message.companyId = reader.readString();
                        break;
                    case 5:
                        message.username = reader.readString();
                        break;
                    case 6:
                        message.permissions = reader.readString();
                        break;
                    case 7:
                        message.status = reader.readString();
                        break;
                    case 8:
                        message.enabled = reader.readBool();
                        break;
                    case 9:
                        message.network = reader.readBool();
                        break;
                    case 10:
                        message.template = reader.readBool();
                        break;
                    case 11:
                        message.transfer = reader.readBool();
                        break;
                    case 12:
                        message.users = reader.readBool();
                        break;
                    case 13:
                        message.workflow = reader.readBool();
                        break;
                    case 14:
                        message.scope = reader.readString();
                        break;
                    case 15:
                        message.profile = reader.readString();
                        break;
                    case 16:
                        message.document = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): User {
            return User.deserialize(bytes);
        }
    }
    export class Company extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            document?: string;
            name?: string;
            enabled?: boolean;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            configSettings?: dependency_2.core.CustomAttributes[];
            managements?: Management[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6, 7], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("enabled" in data && data.enabled != undefined) {
                    this.enabled = data.enabled;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("configSettings" in data && data.configSettings != undefined) {
                    this.configSettings = data.configSettings;
                }
                if ("managements" in data && data.managements != undefined) {
                    this.managements = data.managements;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get enabled() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set enabled(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 5) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get configSettings() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.CustomAttributes, 6) as dependency_2.core.CustomAttributes[];
        }
        set configSettings(value: dependency_2.core.CustomAttributes[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get managements() {
            return pb_1.Message.getRepeatedWrapperField(this, Management, 7) as Management[];
        }
        set managements(value: Management[]) {
            pb_1.Message.setRepeatedWrapperField(this, 7, value);
        }
        static fromObject(data: {
            id?: string;
            document?: string;
            name?: string;
            enabled?: boolean;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            configSettings?: ReturnType<typeof dependency_2.core.CustomAttributes.prototype.toObject>[];
            managements?: ReturnType<typeof Management.prototype.toObject>[];
        }): Company {
            const message = new Company({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.document != null) {
                message.document = data.document;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.enabled != null) {
                message.enabled = data.enabled;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.configSettings != null) {
                message.configSettings = data.configSettings.map(item => dependency_2.core.CustomAttributes.fromObject(item));
            }
            if (data.managements != null) {
                message.managements = data.managements.map(item => Management.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                document?: string;
                name?: string;
                enabled?: boolean;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                configSettings?: ReturnType<typeof dependency_2.core.CustomAttributes.prototype.toObject>[];
                managements?: ReturnType<typeof Management.prototype.toObject>[];
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.document != null) {
                data.document = this.document;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.enabled != null) {
                data.enabled = this.enabled;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.configSettings != null) {
                data.configSettings = this.configSettings.map((item: dependency_2.core.CustomAttributes) => item.toObject());
            }
            if (this.managements != null) {
                data.managements = this.managements.map((item: Management) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.document.length)
                writer.writeString(2, this.document);
            if (this.name.length)
                writer.writeString(3, this.name);
            if (this.enabled != false)
                writer.writeBool(4, this.enabled);
            if (this.hasCreatedAt)
                writer.writeMessage(5, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.configSettings.length)
                writer.writeRepeatedMessage(6, this.configSettings, (item: dependency_2.core.CustomAttributes) => item.serialize(writer));
            if (this.managements.length)
                writer.writeRepeatedMessage(7, this.managements, (item: Management) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Company {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Company();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.document = reader.readString();
                        break;
                    case 3:
                        message.name = reader.readString();
                        break;
                    case 4:
                        message.enabled = reader.readBool();
                        break;
                    case 5:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.configSettings, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_2.core.CustomAttributes.deserialize(reader), dependency_2.core.CustomAttributes));
                        break;
                    case 7:
                        reader.readMessage(message.managements, () => pb_1.Message.addToRepeatedWrapperField(message, 7, Management.deserialize(reader), Management));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Company {
            return Company.deserialize(bytes);
        }
    }
    export class Customer extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sub?: string;
            name?: string;
            email?: string;
            companyId?: string;
            profile?: string;
            document?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sub" in data && data.sub != undefined) {
                    this.sub = data.sub;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("profile" in data && data.profile != undefined) {
                    this.profile = data.profile;
                }
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
            }
        }
        get sub() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sub(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get profile() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set profile(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            sub?: string;
            name?: string;
            email?: string;
            companyId?: string;
            profile?: string;
            document?: string;
        }): Customer {
            const message = new Customer({});
            if (data.sub != null) {
                message.sub = data.sub;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.profile != null) {
                message.profile = data.profile;
            }
            if (data.document != null) {
                message.document = data.document;
            }
            return message;
        }
        toObject() {
            const data: {
                sub?: string;
                name?: string;
                email?: string;
                companyId?: string;
                profile?: string;
                document?: string;
            } = {};
            if (this.sub != null) {
                data.sub = this.sub;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.profile != null) {
                data.profile = this.profile;
            }
            if (this.document != null) {
                data.document = this.document;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sub.length)
                writer.writeString(1, this.sub);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.email.length)
                writer.writeString(3, this.email);
            if (this.companyId.length)
                writer.writeString(4, this.companyId);
            if (this.profile.length)
                writer.writeString(5, this.profile);
            if (this.document.length)
                writer.writeString(6, this.document);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Customer {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Customer();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sub = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.email = reader.readString();
                        break;
                    case 4:
                        message.companyId = reader.readString();
                        break;
                    case 5:
                        message.profile = reader.readString();
                        break;
                    case 6:
                        message.document = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Customer {
            return Customer.deserialize(bytes);
        }
    }
    export class Management extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            document?: string;
            name?: string;
            email?: string;
            companyId?: string;
            accountBank?: dependency_3.core.profile.v1.AccountBank[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("accountBank" in data && data.accountBank != undefined) {
                    this.accountBank = data.accountBank;
                }
            }
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get accountBank() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_3.core.profile.v1.AccountBank, 6) as dependency_3.core.profile.v1.AccountBank[];
        }
        set accountBank(value: dependency_3.core.profile.v1.AccountBank[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data: {
            document?: string;
            name?: string;
            email?: string;
            companyId?: string;
            accountBank?: ReturnType<typeof dependency_3.core.profile.v1.AccountBank.prototype.toObject>[];
        }): Management {
            const message = new Management({});
            if (data.document != null) {
                message.document = data.document;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.accountBank != null) {
                message.accountBank = data.accountBank.map(item => dependency_3.core.profile.v1.AccountBank.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                document?: string;
                name?: string;
                email?: string;
                companyId?: string;
                accountBank?: ReturnType<typeof dependency_3.core.profile.v1.AccountBank.prototype.toObject>[];
            } = {};
            if (this.document != null) {
                data.document = this.document;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.accountBank != null) {
                data.accountBank = this.accountBank.map((item: dependency_3.core.profile.v1.AccountBank) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.document.length)
                writer.writeString(1, this.document);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.email.length)
                writer.writeString(3, this.email);
            if (this.companyId.length)
                writer.writeString(4, this.companyId);
            if (this.accountBank.length)
                writer.writeRepeatedMessage(6, this.accountBank, (item: dependency_3.core.profile.v1.AccountBank) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Management {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Management();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.document = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.email = reader.readString();
                        break;
                    case 4:
                        message.companyId = reader.readString();
                        break;
                    case 6:
                        reader.readMessage(message.accountBank, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_3.core.profile.v1.AccountBank.deserialize(reader), dependency_3.core.profile.v1.AccountBank));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Management {
            return Management.deserialize(bytes);
        }
    }
    export class Token extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            signedToken?: string;
            tokenType?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("signedToken" in data && data.signedToken != undefined) {
                    this.signedToken = data.signedToken;
                }
                if ("tokenType" in data && data.tokenType != undefined) {
                    this.tokenType = data.tokenType;
                }
            }
        }
        get signedToken() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set signedToken(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get tokenType() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set tokenType(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            signedToken?: string;
            tokenType?: string;
        }): Token {
            const message = new Token({});
            if (data.signedToken != null) {
                message.signedToken = data.signedToken;
            }
            if (data.tokenType != null) {
                message.tokenType = data.tokenType;
            }
            return message;
        }
        toObject() {
            const data: {
                signedToken?: string;
                tokenType?: string;
            } = {};
            if (this.signedToken != null) {
                data.signedToken = this.signedToken;
            }
            if (this.tokenType != null) {
                data.tokenType = this.tokenType;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.signedToken.length)
                writer.writeString(1, this.signedToken);
            if (this.tokenType.length)
                writer.writeString(2, this.tokenType);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Token {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Token();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.signedToken = reader.readString();
                        break;
                    case 2:
                        message.tokenType = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Token {
            return Token.deserialize(bytes);
        }
    }
    export class AuthorizationAPI extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            publicKey?: string;
            privateKey?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("publicKey" in data && data.publicKey != undefined) {
                    this.publicKey = data.publicKey;
                }
                if ("privateKey" in data && data.privateKey != undefined) {
                    this.privateKey = data.privateKey;
                }
            }
        }
        get publicKey() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set publicKey(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get privateKey() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set privateKey(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            publicKey?: string;
            privateKey?: string;
        }): AuthorizationAPI {
            const message = new AuthorizationAPI({});
            if (data.publicKey != null) {
                message.publicKey = data.publicKey;
            }
            if (data.privateKey != null) {
                message.privateKey = data.privateKey;
            }
            return message;
        }
        toObject() {
            const data: {
                publicKey?: string;
                privateKey?: string;
            } = {};
            if (this.publicKey != null) {
                data.publicKey = this.publicKey;
            }
            if (this.privateKey != null) {
                data.privateKey = this.privateKey;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.publicKey.length)
                writer.writeString(1, this.publicKey);
            if (this.privateKey.length)
                writer.writeString(2, this.privateKey);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthorizationAPI {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthorizationAPI();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.publicKey = reader.readString();
                        break;
                    case 2:
                        message.privateKey = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AuthorizationAPI {
            return AuthorizationAPI.deserialize(bytes);
        }
    }
}
