import { catchError, combineLatest, from, map, Observable, of, tap } from "rxjs";
import { GrpcProductService } from "@libs/services/grpc-product/grpc-product.service";
import { inject, Injectable } from "@angular/core";
import { Product } from "@libs/models/product";
import { CognitoService } from "@libs/services/cognito/cognito.service";
import { SharedService } from "@libs/services/shared/shared.service";
import { MatDialog } from "@angular/material/dialog";
import { GrpcAccountService } from "@libs/services/grpc-account/grpc-account.service";
import { ModalTokenizacaoTermsComponent } from "@components/modal-tokenizacao-terms/modal-tokenizacao-terms.component";
import { NUCLEA_ID } from "@libs/main.service";
import { User } from "@libs/models/user";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public dialog = inject(MatDialog);
  products: Product[];

  lastUpdateDate: string; //"2024-08-01T00:00:00Z"
  termsHtml: string;
  modalTermsIsOpen = false;

  userRegister: User = {};

  constructor(
    public grpcProduct: GrpcProductService,
    public cognitoService: CognitoService,
    public shared: SharedService,
    public grpcAccount: GrpcAccountService
  ) { }

  loadDataWithUser(user: User): Observable<any> {
    return this.loadData(user);
  }

  loadData(user?: User) {
    console.log("Usuário no loadData: ", user);
    console.log("Produtos no loadData: ", this.products);
    if (!user) {
      return of(null);
    }
    if (user && user.company && this.termsHtml && this.products) {
      return of(null);
    }
    return combineLatest([
      from(this.grpcProduct.getProducts()).pipe(map((resProducts: any) => resProducts.toObject())),
      from(this.grpcAccount.getTerms()).pipe(map((resTerms: any) => resTerms.toObject())),
      from(this.grpcAccount.getCompany(user.companyId ?? NUCLEA_ID)).pipe(map((resCompany: any) => resCompany.toObject())),
    ]).pipe(
      tap(([responseProducts, responseTerms, resCompany]) => {
        console.log("responseProducts: ", responseProducts);
        console.log("responseTerms: ", responseTerms);
        console.log("resCompany: ", resCompany);
        this.products = responseProducts.productInfos as Product[];
        this.shared.resetProductInfoSelected$.next(this.products);
        this.termsHtml = responseTerms.termsHtml;
        this.lastUpdateDate = responseTerms.lastUpdateDate;
        if (resCompany && resCompany.company) {
          user.company = resCompany.company;
          this.shared.user$.next({ ...user });
        }
        this.handleTerms(user);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  handleTerms(user) {
    if (user && this.termsHtml && this.lastUpdateDate) {
      if (!user.termsAcceptedAt) {
        this.openModalTokenizacaoTerms();
      } else {
        const lastUpdateDate = new Date(this.lastUpdateDate);
        const termsAcceptedDate = new Date(user.termsAcceptedAt);
        const lastUpdateDateOnly = new Date(lastUpdateDate.getFullYear(), lastUpdateDate.getMonth(), lastUpdateDate.getDate());
        const termsAcceptedDateOnly = new Date(termsAcceptedDate.getFullYear(), termsAcceptedDate.getMonth(), termsAcceptedDate.getDate());
        if (lastUpdateDateOnly.getTime() !== termsAcceptedDateOnly.getTime()) {
          this.openModalTokenizacaoTerms();
        }
      }
    }
  }

  openModalTokenizacaoTerms() {
    if (!this.modalTermsIsOpen) {
      this.modalTermsIsOpen = true;
      this.dialog.open(ModalTokenizacaoTermsComponent, {
        width: "600px",
        disableClose: true,
        data: { termsHtml: this.termsHtml, lastUpdateDate: this.lastUpdateDate },
      });
    }
  }

  resetData() {
    this.modalTermsIsOpen = false;
    this.termsHtml = null;
    this.products = null;
    this.lastUpdateDate = null;
  }
}
