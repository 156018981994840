import * as Api from "@libs/proto/core/token_validation/v1/token_validation_api";
import { Injectable } from "@angular/core";
import { MainService } from "@libs/main.service";

@Injectable({
  providedIn: "root",
})
export class GrpcTokenService {
  private client: Api.core.tokenvalidation.v1.TokenValidationAPIClient;

  constructor(public mainService: MainService) {
    this.client = new Api.core.tokenvalidation.v1.TokenValidationAPIClient(mainService.endpointBase, null, {
      unaryInterceptors: [],
    });
  }

  validateToken(hash: string, recaptchaToken?: string, hashVerify?: string) {
    const request = new Api.core.tokenvalidation.v1.ValidateTokenRequest({
      hash,
      hashVerify,
      recaptchaToken,
    });
    return this.client.Validate(request, {});
  }
}
