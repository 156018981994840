import { inject, Injectable } from "@angular/core";
import { CognitoService } from "@libs/services/cognito/cognito.service";
import { ClientReadableStream, RpcError, Status, Metadata } from "grpc-web";

@Injectable({
  providedIn: "root",
})
export class AuthorizationStreamInterceptor {
  public cognitoService = inject(CognitoService);
  private maxRetries = 100;

  constructor() {}

  intercept = (request, invoker): ClientReadableStream<any> => {
    let authorization = this.getAuthorization();
    if (authorization) {
      request.getMetadata()["Authorization"] = authorization;
    }

    const originalStream = invoker(request);
    const interceptedStream = new InterceptedStream(originalStream, request, invoker, this);

    return interceptedStream;
  };

  getAuthorization = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      return `Bearer ${accessToken}`;
    }
    return null;
  };

  public async tryRenewToken() {
    try {
      await this.cognitoService.refreshSession();
      return true;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return false;
    }
  }

  public getExponentialBackoffDelay(attempt: number): number {
    return Math.pow(3, attempt) * 1000;
  }

  public sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getMaxRetries(): number {
    return this.maxRetries;
  }
}

class InterceptedStream<RESPONSE> implements ClientReadableStream<RESPONSE> {
  constructor(
    private readonly stream: ClientReadableStream<RESPONSE>,
    private readonly request: any,
    private readonly invoker: any,
    private readonly interceptor: AuthorizationStreamInterceptor
  ) {}

  cancel(): void {
    this.stream.cancel();
  }

  on(eventType: "data", callback: (response: RESPONSE) => void): ClientReadableStream<RESPONSE>;
  on(eventType: "status", callback: (status: Status) => void): ClientReadableStream<RESPONSE>;
  on(eventType: "end", callback: () => void): ClientReadableStream<RESPONSE>;
  on(eventType: "error", callback: (err: RpcError) => void): ClientReadableStream<RESPONSE>;
  on(eventType: "metadata", callback: (metadata: Metadata) => void): ClientReadableStream<RESPONSE>;
  on(eventType: any, callback: any): ClientReadableStream<RESPONSE> {
    if (eventType === "data") {
      const newCallback = (response: RESPONSE) => {
        // Processa a resposta antes de passar para o callback original
        callback(response);
      };
      this.stream.on(eventType, newCallback);
    } else if (eventType === "error") {
      const newCallback = async (error: RpcError) => {
        console.error("Error in stream interceptor", error);
        if (error.code === 401 || error.code === 2 || error.code === 16) {
          await this.handleRetry(error, callback, 1);
        } else {
          callback(error);
        }
      };
      this.stream.on(eventType, newCallback);
    } else if (eventType === "status" || eventType === "end" || eventType === "metadata") {
      this.stream.on(eventType, callback);
    } else {
      throw new Error(`Unsupported event type: ${eventType}`);
    }
    return this;
  }

  private async handleRetry(error: RpcError, callback: any, attempt: number): Promise<void> {
    if (attempt > this.interceptor.getMaxRetries()) {
      callback(new Error("Falha ao renovar o token após múltiplas tentativas"));
      return;
    }

    const renewed = await this.interceptor.tryRenewToken();

    if (renewed) {
      const authorization = this.interceptor.getAuthorization();
      if (authorization) {
        this.request.getMetadata()["Authorization"] = authorization;
      }

      const delay = this.interceptor.getExponentialBackoffDelay(attempt);
      console.log(`Esperando ${delay / 1000} segundos antes da tentativa ${attempt} de renovação do token`);

      await this.interceptor.sleep(delay);
      this.stream.cancel();
      const newStream = this.invoker(this.request);
      newStream.on("error", async (err: RpcError) => {
        if (err.code === 401 || err.code === 2 || err.code === 16) {
          await this.handleRetry(err, callback, attempt + 1);
        } else {
          callback(err);
        }
      });
      newStream.on("data", callback);
      newStream.on("status", callback);
      newStream.on("end", callback);
      newStream.on("metadata", callback);
    } else {
      callback(new Error("Falha ao renovar o token"));
    }
  }

  removeListener(eventType: any, callback: any) {
    this.stream.removeListener(eventType, callback);
  }
}
