/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/auction/v1/auction_api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./auction";
import * as dependency_2 from "./../../common/v1/composite_filter";
import * as dependency_3 from "./../../../google/api/annotations";
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export namespace core.auction.v1 {
    export class ListAuctionSummaryRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            filters?: dependency_2.core.common.v1.CompositeFilter[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("filters" in data && data.filters != undefined) {
                    this.filters = data.filters;
                }
            }
        }
        get filters() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.common.v1.CompositeFilter, 1) as dependency_2.core.common.v1.CompositeFilter[];
        }
        set filters(value: dependency_2.core.common.v1.CompositeFilter[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            filters?: ReturnType<typeof dependency_2.core.common.v1.CompositeFilter.prototype.toObject>[];
        }): ListAuctionSummaryRequest {
            const message = new ListAuctionSummaryRequest({});
            if (data.filters != null) {
                message.filters = data.filters.map(item => dependency_2.core.common.v1.CompositeFilter.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                filters?: ReturnType<typeof dependency_2.core.common.v1.CompositeFilter.prototype.toObject>[];
            } = {};
            if (this.filters != null) {
                data.filters = this.filters.map((item: dependency_2.core.common.v1.CompositeFilter) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.filters.length)
                writer.writeRepeatedMessage(1, this.filters, (item: dependency_2.core.common.v1.CompositeFilter) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListAuctionSummaryRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListAuctionSummaryRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.filters, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.core.common.v1.CompositeFilter.deserialize(reader), dependency_2.core.common.v1.CompositeFilter));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListAuctionSummaryRequest {
            return ListAuctionSummaryRequest.deserialize(bytes);
        }
    }
    export class ListAuctionSummaryResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            auctionSummary?: dependency_1.core.auction.v1.AuctionSummary;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("auctionSummary" in data && data.auctionSummary != undefined) {
                    this.auctionSummary = data.auctionSummary;
                }
            }
        }
        get auctionSummary() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.AuctionSummary, 1) as dependency_1.core.auction.v1.AuctionSummary;
        }
        set auctionSummary(value: dependency_1.core.auction.v1.AuctionSummary) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasAuctionSummary() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            auctionSummary?: ReturnType<typeof dependency_1.core.auction.v1.AuctionSummary.prototype.toObject>;
        }): ListAuctionSummaryResponse {
            const message = new ListAuctionSummaryResponse({});
            if (data.auctionSummary != null) {
                message.auctionSummary = dependency_1.core.auction.v1.AuctionSummary.fromObject(data.auctionSummary);
            }
            return message;
        }
        toObject() {
            const data: {
                auctionSummary?: ReturnType<typeof dependency_1.core.auction.v1.AuctionSummary.prototype.toObject>;
            } = {};
            if (this.auctionSummary != null) {
                data.auctionSummary = this.auctionSummary.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasAuctionSummary)
                writer.writeMessage(1, this.auctionSummary, () => this.auctionSummary.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListAuctionSummaryResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListAuctionSummaryResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.auctionSummary, () => message.auctionSummary = dependency_1.core.auction.v1.AuctionSummary.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListAuctionSummaryResponse {
            return ListAuctionSummaryResponse.deserialize(bytes);
        }
    }
    export class CreateAuctionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            auction?: dependency_1.core.auction.v1.Auction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("auction" in data && data.auction != undefined) {
                    this.auction = data.auction;
                }
            }
        }
        get auction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.Auction, 1) as dependency_1.core.auction.v1.Auction;
        }
        set auction(value: dependency_1.core.auction.v1.Auction) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasAuction() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            auction?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>;
        }): CreateAuctionRequest {
            const message = new CreateAuctionRequest({});
            if (data.auction != null) {
                message.auction = dependency_1.core.auction.v1.Auction.fromObject(data.auction);
            }
            return message;
        }
        toObject() {
            const data: {
                auction?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>;
            } = {};
            if (this.auction != null) {
                data.auction = this.auction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasAuction)
                writer.writeMessage(1, this.auction, () => this.auction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateAuctionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateAuctionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.auction, () => message.auction = dependency_1.core.auction.v1.Auction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateAuctionRequest {
            return CreateAuctionRequest.deserialize(bytes);
        }
    }
    export class CreateAuctionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            auction?: dependency_1.core.auction.v1.Auction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("auction" in data && data.auction != undefined) {
                    this.auction = data.auction;
                }
            }
        }
        get auction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.Auction, 1) as dependency_1.core.auction.v1.Auction;
        }
        set auction(value: dependency_1.core.auction.v1.Auction) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasAuction() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            auction?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>;
        }): CreateAuctionResponse {
            const message = new CreateAuctionResponse({});
            if (data.auction != null) {
                message.auction = dependency_1.core.auction.v1.Auction.fromObject(data.auction);
            }
            return message;
        }
        toObject() {
            const data: {
                auction?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>;
            } = {};
            if (this.auction != null) {
                data.auction = this.auction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasAuction)
                writer.writeMessage(1, this.auction, () => this.auction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateAuctionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateAuctionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.auction, () => message.auction = dependency_1.core.auction.v1.Auction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateAuctionResponse {
            return CreateAuctionResponse.deserialize(bytes);
        }
    }
    export class ListAuctionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            page?: number;
            itemPerPage?: number;
            filters?: dependency_2.core.common.v1.CompositeFilter[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemPerPage" in data && data.itemPerPage != undefined) {
                    this.itemPerPage = data.itemPerPage;
                }
                if ("filters" in data && data.filters != undefined) {
                    this.filters = data.filters;
                }
            }
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get itemPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set itemPerPage(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get filters() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_2.core.common.v1.CompositeFilter, 3) as dependency_2.core.common.v1.CompositeFilter[];
        }
        set filters(value: dependency_2.core.common.v1.CompositeFilter[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            page?: number;
            itemPerPage?: number;
            filters?: ReturnType<typeof dependency_2.core.common.v1.CompositeFilter.prototype.toObject>[];
        }): ListAuctionRequest {
            const message = new ListAuctionRequest({});
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemPerPage != null) {
                message.itemPerPage = data.itemPerPage;
            }
            if (data.filters != null) {
                message.filters = data.filters.map(item => dependency_2.core.common.v1.CompositeFilter.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                page?: number;
                itemPerPage?: number;
                filters?: ReturnType<typeof dependency_2.core.common.v1.CompositeFilter.prototype.toObject>[];
            } = {};
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemPerPage != null) {
                data.itemPerPage = this.itemPerPage;
            }
            if (this.filters != null) {
                data.filters = this.filters.map((item: dependency_2.core.common.v1.CompositeFilter) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.page != 0)
                writer.writeInt32(1, this.page);
            if (this.itemPerPage != 0)
                writer.writeInt32(2, this.itemPerPage);
            if (this.filters.length)
                writer.writeRepeatedMessage(3, this.filters, (item: dependency_2.core.common.v1.CompositeFilter) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListAuctionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListAuctionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.page = reader.readInt32();
                        break;
                    case 2:
                        message.itemPerPage = reader.readInt32();
                        break;
                    case 3:
                        reader.readMessage(message.filters, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_2.core.common.v1.CompositeFilter.deserialize(reader), dependency_2.core.common.v1.CompositeFilter));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListAuctionRequest {
            return ListAuctionRequest.deserialize(bytes);
        }
    }
    export class ListAuctionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            auctions?: dependency_1.core.auction.v1.Auction[];
            totalPages?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("auctions" in data && data.auctions != undefined) {
                    this.auctions = data.auctions;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
            }
        }
        get auctions() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.auction.v1.Auction, 1) as dependency_1.core.auction.v1.Auction[];
        }
        set auctions(value: dependency_1.core.auction.v1.Auction[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            auctions?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>[];
            totalPages?: number;
        }): ListAuctionResponse {
            const message = new ListAuctionResponse({});
            if (data.auctions != null) {
                message.auctions = data.auctions.map(item => dependency_1.core.auction.v1.Auction.fromObject(item));
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            return message;
        }
        toObject() {
            const data: {
                auctions?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>[];
                totalPages?: number;
            } = {};
            if (this.auctions != null) {
                data.auctions = this.auctions.map((item: dependency_1.core.auction.v1.Auction) => item.toObject());
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.auctions.length)
                writer.writeRepeatedMessage(1, this.auctions, (item: dependency_1.core.auction.v1.Auction) => item.serialize(writer));
            if (this.totalPages != 0)
                writer.writeInt32(2, this.totalPages);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListAuctionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListAuctionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.auctions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.core.auction.v1.Auction.deserialize(reader), dependency_1.core.auction.v1.Auction));
                        break;
                    case 2:
                        message.totalPages = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListAuctionResponse {
            return ListAuctionResponse.deserialize(bytes);
        }
    }
    export class GetAuctionRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            id?: string;
        }): GetAuctionRequest {
            const message = new GetAuctionRequest({});
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetAuctionRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetAuctionRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetAuctionRequest {
            return GetAuctionRequest.deserialize(bytes);
        }
    }
    export class GetAuctionResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            auction?: dependency_1.core.auction.v1.Auction;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("auction" in data && data.auction != undefined) {
                    this.auction = data.auction;
                }
            }
        }
        get auction() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.Auction, 1) as dependency_1.core.auction.v1.Auction;
        }
        set auction(value: dependency_1.core.auction.v1.Auction) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasAuction() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            auction?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>;
        }): GetAuctionResponse {
            const message = new GetAuctionResponse({});
            if (data.auction != null) {
                message.auction = dependency_1.core.auction.v1.Auction.fromObject(data.auction);
            }
            return message;
        }
        toObject() {
            const data: {
                auction?: ReturnType<typeof dependency_1.core.auction.v1.Auction.prototype.toObject>;
            } = {};
            if (this.auction != null) {
                data.auction = this.auction.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasAuction)
                writer.writeMessage(1, this.auction, () => this.auction.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetAuctionResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetAuctionResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.auction, () => message.auction = dependency_1.core.auction.v1.Auction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetAuctionResponse {
            return GetAuctionResponse.deserialize(bytes);
        }
    }
    export class GetConfirmBidRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            externalId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
            }
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            externalId?: string;
        }): GetConfirmBidRequest {
            const message = new GetConfirmBidRequest({});
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            return message;
        }
        toObject() {
            const data: {
                externalId?: string;
            } = {};
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.externalId.length)
                writer.writeString(2, this.externalId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetConfirmBidRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetConfirmBidRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 2:
                        message.externalId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetConfirmBidRequest {
            return GetConfirmBidRequest.deserialize(bytes);
        }
    }
    export class CreateBidRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bidValue?: number;
            externalId?: string;
            managementDocument?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bidValue" in data && data.bidValue != undefined) {
                    this.bidValue = data.bidValue;
                }
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
                if ("managementDocument" in data && data.managementDocument != undefined) {
                    this.managementDocument = data.managementDocument;
                }
            }
        }
        get bidValue() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set bidValue(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get managementDocument() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set managementDocument(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            bidValue?: number;
            externalId?: string;
            managementDocument?: string;
        }): CreateBidRequest {
            const message = new CreateBidRequest({});
            if (data.bidValue != null) {
                message.bidValue = data.bidValue;
            }
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            if (data.managementDocument != null) {
                message.managementDocument = data.managementDocument;
            }
            return message;
        }
        toObject() {
            const data: {
                bidValue?: number;
                externalId?: string;
                managementDocument?: string;
            } = {};
            if (this.bidValue != null) {
                data.bidValue = this.bidValue;
            }
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            if (this.managementDocument != null) {
                data.managementDocument = this.managementDocument;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.bidValue != 0)
                writer.writeDouble(1, this.bidValue);
            if (this.externalId.length)
                writer.writeString(2, this.externalId);
            if (this.managementDocument.length)
                writer.writeString(3, this.managementDocument);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateBidRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateBidRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.bidValue = reader.readDouble();
                        break;
                    case 2:
                        message.externalId = reader.readString();
                        break;
                    case 3:
                        message.managementDocument = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateBidRequest {
            return CreateBidRequest.deserialize(bytes);
        }
    }
    export class CreateBidResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bid?: dependency_1.core.auction.v1.Bid;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
            }
        }
        get bid() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.Bid, 1) as dependency_1.core.auction.v1.Bid;
        }
        set bid(value: dependency_1.core.auction.v1.Bid) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasBid() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            bid?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>;
        }): CreateBidResponse {
            const message = new CreateBidResponse({});
            if (data.bid != null) {
                message.bid = dependency_1.core.auction.v1.Bid.fromObject(data.bid);
            }
            return message;
        }
        toObject() {
            const data: {
                bid?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>;
            } = {};
            if (this.bid != null) {
                data.bid = this.bid.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasBid)
                writer.writeMessage(1, this.bid, () => this.bid.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateBidResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateBidResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.bid, () => message.bid = dependency_1.core.auction.v1.Bid.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateBidResponse {
            return CreateBidResponse.deserialize(bytes);
        }
    }
    export class ListBidsRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            externalId?: string;
            page?: number;
            itemPerPage?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("itemPerPage" in data && data.itemPerPage != undefined) {
                    this.itemPerPage = data.itemPerPage;
                }
            }
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get itemPerPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set itemPerPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            externalId?: string;
            page?: number;
            itemPerPage?: number;
        }): ListBidsRequest {
            const message = new ListBidsRequest({});
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.itemPerPage != null) {
                message.itemPerPage = data.itemPerPage;
            }
            return message;
        }
        toObject() {
            const data: {
                externalId?: string;
                page?: number;
                itemPerPage?: number;
            } = {};
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.itemPerPage != null) {
                data.itemPerPage = this.itemPerPage;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.externalId.length)
                writer.writeString(1, this.externalId);
            if (this.page != 0)
                writer.writeInt64(2, this.page);
            if (this.itemPerPage != 0)
                writer.writeInt64(3, this.itemPerPage);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListBidsRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListBidsRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.externalId = reader.readString();
                        break;
                    case 2:
                        message.page = reader.readInt64();
                        break;
                    case 3:
                        message.itemPerPage = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListBidsRequest {
            return ListBidsRequest.deserialize(bytes);
        }
    }
    export class ListBidsResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bids?: dependency_1.core.auction.v1.Bid[];
            totalPages?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bids" in data && data.bids != undefined) {
                    this.bids = data.bids;
                }
                if ("totalPages" in data && data.totalPages != undefined) {
                    this.totalPages = data.totalPages;
                }
            }
        }
        get bids() {
            return pb_1.Message.getRepeatedWrapperField(this, dependency_1.core.auction.v1.Bid, 1) as dependency_1.core.auction.v1.Bid[];
        }
        set bids(value: dependency_1.core.auction.v1.Bid[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get totalPages() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set totalPages(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            bids?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>[];
            totalPages?: number;
        }): ListBidsResponse {
            const message = new ListBidsResponse({});
            if (data.bids != null) {
                message.bids = data.bids.map(item => dependency_1.core.auction.v1.Bid.fromObject(item));
            }
            if (data.totalPages != null) {
                message.totalPages = data.totalPages;
            }
            return message;
        }
        toObject() {
            const data: {
                bids?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>[];
                totalPages?: number;
            } = {};
            if (this.bids != null) {
                data.bids = this.bids.map((item: dependency_1.core.auction.v1.Bid) => item.toObject());
            }
            if (this.totalPages != null) {
                data.totalPages = this.totalPages;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.bids.length)
                writer.writeRepeatedMessage(1, this.bids, (item: dependency_1.core.auction.v1.Bid) => item.serialize(writer));
            if (this.totalPages != 0)
                writer.writeInt64(2, this.totalPages);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ListBidsResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ListBidsResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.bids, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.core.auction.v1.Bid.deserialize(reader), dependency_1.core.auction.v1.Bid));
                        break;
                    case 2:
                        message.totalPages = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ListBidsResponse {
            return ListBidsResponse.deserialize(bytes);
        }
    }
    export class GetTopUserBidRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            externalId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
            }
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            externalId?: string;
        }): GetTopUserBidRequest {
            const message = new GetTopUserBidRequest({});
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            return message;
        }
        toObject() {
            const data: {
                externalId?: string;
            } = {};
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.externalId.length)
                writer.writeString(1, this.externalId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTopUserBidRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTopUserBidRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.externalId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTopUserBidRequest {
            return GetTopUserBidRequest.deserialize(bytes);
        }
    }
    export class GetTopUserBidResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bid?: dependency_1.core.auction.v1.Bid;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
            }
        }
        get bid() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.Bid, 1) as dependency_1.core.auction.v1.Bid;
        }
        set bid(value: dependency_1.core.auction.v1.Bid) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasBid() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            bid?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>;
        }): GetTopUserBidResponse {
            const message = new GetTopUserBidResponse({});
            if (data.bid != null) {
                message.bid = dependency_1.core.auction.v1.Bid.fromObject(data.bid);
            }
            return message;
        }
        toObject() {
            const data: {
                bid?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>;
            } = {};
            if (this.bid != null) {
                data.bid = this.bid.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasBid)
                writer.writeMessage(1, this.bid, () => this.bid.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTopUserBidResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTopUserBidResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.bid, () => message.bid = dependency_1.core.auction.v1.Bid.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTopUserBidResponse {
            return GetTopUserBidResponse.deserialize(bytes);
        }
    }
    export class GetTopBidRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            externalId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("externalId" in data && data.externalId != undefined) {
                    this.externalId = data.externalId;
                }
            }
        }
        get externalId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set externalId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            externalId?: string;
        }): GetTopBidRequest {
            const message = new GetTopBidRequest({});
            if (data.externalId != null) {
                message.externalId = data.externalId;
            }
            return message;
        }
        toObject() {
            const data: {
                externalId?: string;
            } = {};
            if (this.externalId != null) {
                data.externalId = this.externalId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.externalId.length)
                writer.writeString(1, this.externalId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTopBidRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTopBidRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.externalId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTopBidRequest {
            return GetTopBidRequest.deserialize(bytes);
        }
    }
    export class GetTopBidResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bid?: dependency_1.core.auction.v1.Bid;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bid" in data && data.bid != undefined) {
                    this.bid = data.bid;
                }
            }
        }
        get bid() {
            return pb_1.Message.getWrapperField(this, dependency_1.core.auction.v1.Bid, 1) as dependency_1.core.auction.v1.Bid;
        }
        set bid(value: dependency_1.core.auction.v1.Bid) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasBid() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            bid?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>;
        }): GetTopBidResponse {
            const message = new GetTopBidResponse({});
            if (data.bid != null) {
                message.bid = dependency_1.core.auction.v1.Bid.fromObject(data.bid);
            }
            return message;
        }
        toObject() {
            const data: {
                bid?: ReturnType<typeof dependency_1.core.auction.v1.Bid.prototype.toObject>;
            } = {};
            if (this.bid != null) {
                data.bid = this.bid.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasBid)
                writer.writeMessage(1, this.bid, () => this.bid.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTopBidResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTopBidResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.bid, () => message.bid = dependency_1.core.auction.v1.Bid.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): GetTopBidResponse {
            return GetTopBidResponse.deserialize(bytes);
        }
    }
    export class CancelQuotasRequest extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            assetIds?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetIds" in data && data.assetIds != undefined) {
                    this.assetIds = data.assetIds;
                }
            }
        }
        get assetIds() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set assetIds(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            assetIds?: string[];
        }): CancelQuotasRequest {
            const message = new CancelQuotasRequest({});
            if (data.assetIds != null) {
                message.assetIds = data.assetIds;
            }
            return message;
        }
        toObject() {
            const data: {
                assetIds?: string[];
            } = {};
            if (this.assetIds != null) {
                data.assetIds = this.assetIds;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetIds.length)
                writer.writeRepeatedString(1, this.assetIds);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CancelQuotasRequest {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CancelQuotasRequest();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CancelQuotasRequest {
            return CancelQuotasRequest.deserialize(bytes);
        }
    }
    export class CancelQuotasResponse extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            message?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get message() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set message(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            message?: string;
        }): CancelQuotasResponse {
            const message = new CancelQuotasResponse({});
            if (data.message != null) {
                message.message = data.message;
            }
            return message;
        }
        toObject() {
            const data: {
                message?: string;
            } = {};
            if (this.message != null) {
                data.message = this.message;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.message.length)
                writer.writeString(1, this.message);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CancelQuotasResponse {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CancelQuotasResponse();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.message = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CancelQuotasResponse {
            return CancelQuotasResponse.deserialize(bytes);
        }
    }
    export abstract class UnimplementedAuctionAPIService {
        static definition = {
            CreateBid: {
                path: "/core.auction.v1.AuctionAPI/CreateBid",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateBidRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateBidRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateBidResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateBidResponse.deserialize(new Uint8Array(bytes))
            },
            ListBids: {
                path: "/core.auction.v1.AuctionAPI/ListBids",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListBidsRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListBidsRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListBidsResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListBidsResponse.deserialize(new Uint8Array(bytes))
            },
            GetTopUserBid: {
                path: "/core.auction.v1.AuctionAPI/GetTopUserBid",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTopUserBidRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTopUserBidRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTopUserBidResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTopUserBidResponse.deserialize(new Uint8Array(bytes))
            },
            GetTopBid: {
                path: "/core.auction.v1.AuctionAPI/GetTopBid",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetTopBidRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetTopBidRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetTopBidResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetTopBidResponse.deserialize(new Uint8Array(bytes))
            },
            CreateAuction: {
                path: "/core.auction.v1.AuctionAPI/CreateAuction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CreateAuctionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CreateAuctionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CreateAuctionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CreateAuctionResponse.deserialize(new Uint8Array(bytes))
            },
            ListAuction: {
                path: "/core.auction.v1.AuctionAPI/ListAuction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListAuctionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListAuctionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListAuctionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListAuctionResponse.deserialize(new Uint8Array(bytes))
            },
            GetAuction: {
                path: "/core.auction.v1.AuctionAPI/GetAuction",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: GetAuctionRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => GetAuctionRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: GetAuctionResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => GetAuctionResponse.deserialize(new Uint8Array(bytes))
            },
            CancelQuotas: {
                path: "/core.auction.v1.AuctionAPI/CancelQuotas",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: CancelQuotasRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => CancelQuotasRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: CancelQuotasResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => CancelQuotasResponse.deserialize(new Uint8Array(bytes))
            },
            ListAuctionSummary: {
                path: "/core.auction.v1.AuctionAPI/ListAuctionSummary",
                requestStream: false,
                responseStream: false,
                requestSerialize: (message: ListAuctionSummaryRequest) => Buffer.from(message.serialize()),
                requestDeserialize: (bytes: Buffer) => ListAuctionSummaryRequest.deserialize(new Uint8Array(bytes)),
                responseSerialize: (message: ListAuctionSummaryResponse) => Buffer.from(message.serialize()),
                responseDeserialize: (bytes: Buffer) => ListAuctionSummaryResponse.deserialize(new Uint8Array(bytes))
            }
        };
        [method: string]: grpc_1.UntypedHandleCall;
        abstract CreateBid(call: grpc_1.ServerUnaryCall<CreateBidRequest, CreateBidResponse>, callback: grpc_1.sendUnaryData<CreateBidResponse>): void;
        abstract ListBids(call: grpc_1.ServerUnaryCall<ListBidsRequest, ListBidsResponse>, callback: grpc_1.sendUnaryData<ListBidsResponse>): void;
        abstract GetTopUserBid(call: grpc_1.ServerUnaryCall<GetTopUserBidRequest, GetTopUserBidResponse>, callback: grpc_1.sendUnaryData<GetTopUserBidResponse>): void;
        abstract GetTopBid(call: grpc_1.ServerUnaryCall<GetTopBidRequest, GetTopBidResponse>, callback: grpc_1.sendUnaryData<GetTopBidResponse>): void;
        abstract CreateAuction(call: grpc_1.ServerUnaryCall<CreateAuctionRequest, CreateAuctionResponse>, callback: grpc_1.sendUnaryData<CreateAuctionResponse>): void;
        abstract ListAuction(call: grpc_1.ServerUnaryCall<ListAuctionRequest, ListAuctionResponse>, callback: grpc_1.sendUnaryData<ListAuctionResponse>): void;
        abstract GetAuction(call: grpc_1.ServerUnaryCall<GetAuctionRequest, GetAuctionResponse>, callback: grpc_1.sendUnaryData<GetAuctionResponse>): void;
        abstract CancelQuotas(call: grpc_1.ServerUnaryCall<CancelQuotasRequest, CancelQuotasResponse>, callback: grpc_1.sendUnaryData<CancelQuotasResponse>): void;
        abstract ListAuctionSummary(call: grpc_1.ServerUnaryCall<ListAuctionSummaryRequest, ListAuctionSummaryResponse>, callback: grpc_1.sendUnaryData<ListAuctionSummaryResponse>): void;
    }
    export class AuctionAPIClient {
        private _address: string;
        private _client: grpc_web_1.GrpcWebClientBase;
        constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
            if (!options)
                options = {};
            options.format = options.format || "text";
            this._address = address;
            this._client = new grpc_web_1.GrpcWebClientBase(options);
        }
        private static CreateBid = new grpc_web_1.MethodDescriptor<CreateBidRequest, CreateBidResponse>("/core.auction.v1.AuctionAPI/CreateBid", grpc_web_1.MethodType.UNARY, CreateBidRequest, CreateBidResponse, (message: CreateBidRequest) => message.serialize(), CreateBidResponse.deserialize);
        CreateBid(message: CreateBidRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateBidRequest, CreateBidResponse>(this._address + "/core.auction.v1.AuctionAPI/CreateBid", message, metadata || {}, AuctionAPIClient.CreateBid);
        }
        private static ListBids = new grpc_web_1.MethodDescriptor<ListBidsRequest, ListBidsResponse>("/core.auction.v1.AuctionAPI/ListBids", grpc_web_1.MethodType.UNARY, ListBidsRequest, ListBidsResponse, (message: ListBidsRequest) => message.serialize(), ListBidsResponse.deserialize);
        ListBids(message: ListBidsRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListBidsRequest, ListBidsResponse>(this._address + "/core.auction.v1.AuctionAPI/ListBids", message, metadata || {}, AuctionAPIClient.ListBids);
        }
        private static GetTopUserBid = new grpc_web_1.MethodDescriptor<GetTopUserBidRequest, GetTopUserBidResponse>("/core.auction.v1.AuctionAPI/GetTopUserBid", grpc_web_1.MethodType.UNARY, GetTopUserBidRequest, GetTopUserBidResponse, (message: GetTopUserBidRequest) => message.serialize(), GetTopUserBidResponse.deserialize);
        GetTopUserBid(message: GetTopUserBidRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTopUserBidRequest, GetTopUserBidResponse>(this._address + "/core.auction.v1.AuctionAPI/GetTopUserBid", message, metadata || {}, AuctionAPIClient.GetTopUserBid);
        }
        private static GetTopBid = new grpc_web_1.MethodDescriptor<GetTopBidRequest, GetTopBidResponse>("/core.auction.v1.AuctionAPI/GetTopBid", grpc_web_1.MethodType.UNARY, GetTopBidRequest, GetTopBidResponse, (message: GetTopBidRequest) => message.serialize(), GetTopBidResponse.deserialize);
        GetTopBid(message: GetTopBidRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetTopBidRequest, GetTopBidResponse>(this._address + "/core.auction.v1.AuctionAPI/GetTopBid", message, metadata || {}, AuctionAPIClient.GetTopBid);
        }
        private static CreateAuction = new grpc_web_1.MethodDescriptor<CreateAuctionRequest, CreateAuctionResponse>("/core.auction.v1.AuctionAPI/CreateAuction", grpc_web_1.MethodType.UNARY, CreateAuctionRequest, CreateAuctionResponse, (message: CreateAuctionRequest) => message.serialize(), CreateAuctionResponse.deserialize);
        CreateAuction(message: CreateAuctionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CreateAuctionRequest, CreateAuctionResponse>(this._address + "/core.auction.v1.AuctionAPI/CreateAuction", message, metadata || {}, AuctionAPIClient.CreateAuction);
        }
        private static ListAuction = new grpc_web_1.MethodDescriptor<ListAuctionRequest, ListAuctionResponse>("/core.auction.v1.AuctionAPI/ListAuction", grpc_web_1.MethodType.UNARY, ListAuctionRequest, ListAuctionResponse, (message: ListAuctionRequest) => message.serialize(), ListAuctionResponse.deserialize);
        ListAuction(message: ListAuctionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListAuctionRequest, ListAuctionResponse>(this._address + "/core.auction.v1.AuctionAPI/ListAuction", message, metadata || {}, AuctionAPIClient.ListAuction);
        }
        private static GetAuction = new grpc_web_1.MethodDescriptor<GetAuctionRequest, GetAuctionResponse>("/core.auction.v1.AuctionAPI/GetAuction", grpc_web_1.MethodType.UNARY, GetAuctionRequest, GetAuctionResponse, (message: GetAuctionRequest) => message.serialize(), GetAuctionResponse.deserialize);
        GetAuction(message: GetAuctionRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<GetAuctionRequest, GetAuctionResponse>(this._address + "/core.auction.v1.AuctionAPI/GetAuction", message, metadata || {}, AuctionAPIClient.GetAuction);
        }
        private static CancelQuotas = new grpc_web_1.MethodDescriptor<CancelQuotasRequest, CancelQuotasResponse>("/core.auction.v1.AuctionAPI/CancelQuotas", grpc_web_1.MethodType.UNARY, CancelQuotasRequest, CancelQuotasResponse, (message: CancelQuotasRequest) => message.serialize(), CancelQuotasResponse.deserialize);
        CancelQuotas(message: CancelQuotasRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<CancelQuotasRequest, CancelQuotasResponse>(this._address + "/core.auction.v1.AuctionAPI/CancelQuotas", message, metadata || {}, AuctionAPIClient.CancelQuotas);
        }
        private static ListAuctionSummary = new grpc_web_1.MethodDescriptor<ListAuctionSummaryRequest, ListAuctionSummaryResponse>("/core.auction.v1.AuctionAPI/ListAuctionSummary", grpc_web_1.MethodType.UNARY, ListAuctionSummaryRequest, ListAuctionSummaryResponse, (message: ListAuctionSummaryRequest) => message.serialize(), ListAuctionSummaryResponse.deserialize);
        ListAuctionSummary(message: ListAuctionSummaryRequest, metadata: grpc_web_1.Metadata | null) {
            return this._client.thenableCall<ListAuctionSummaryRequest, ListAuctionSummaryResponse>(this._address + "/core.auction.v1.AuctionAPI/ListAuctionSummary", message, metadata || {}, AuctionAPIClient.ListAuctionSummary);
        }
    }
}
