import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { catchError, map, switchMap } from "rxjs/operators";
import { CognitoService } from "@libs/services/cognito/cognito.service";
import { from, Observable, of } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { LOAD_DATA_WITH_USER } from "@libs/main.service";
import { LoadingService } from "@libs/services/loading/loading.service";
import { SharedService } from "@libs/services/shared/shared.service";
import { User } from "@libs/models/user";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  user: User;

  constructor(
    private router: Router,
    private shared: SharedService,
    private loadingService: LoadingService,
    private cognitoService: CognitoService,
    @Inject(LOAD_DATA_WITH_USER) private loadDataWithUser: (user: User) => Observable<any>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.user = undefined;
    this.loadingService.loading$.next(true);
    const cognitoUser = this.cognitoService.getCurrentUser();
    if (cognitoUser) {
      return from(
        new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => {
            if (err) {
              reject(false);
            } else if (session.isValid()) {
              cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                  console.error("Error cognito: ", err);
                  reject(false);
                } else if (this.shared.user?.company) {
                  this.user = this.shared.user;
                  resolve(true);
                } else {
                  const user: User = this.cognitoService.getUserFromCognito(cognitoUser, session, attributes);
                  this.user = user;
                  this.shared.user$.next({ ...user });
                  resolve(true);
                }
              });
            } else {
              resolve(false);
            }
          });
        })
      ).pipe(
        switchMap((isSessionValid: boolean) => {
          if (isSessionValid) {
            const currentRoute = state.url;
            const hasPermission = this.checkUserPermissions(currentRoute);
            return hasPermission ? this.loadDataAndNavigate() : this.handleLoginRedirect();
          } else {
            return this.handleLoginRedirect();
          }
        })
      );
    } else {
      return this.handleLoginRedirect();
    }
  }

  private checkUserPermissions(route: string): boolean {
    if (this.user && this.user.permissions) {
      const allowWorkflow = this.user.permissions["workflow"] === "1";
      const allowTransfer = this.user.permissions["transfer"] === "1";
      const allowUsers = this.user.permissions["users"] === "1";
      const allowTemplate = this.user.permissions["template"] === "1";
      const allowNetwork = this.user.permissions["network"] === "1";
      if (route.includes("/admin")) {
        if (allowUsers) {
          return true;
        } else {
          return false;
        }
      } else if (route.includes("/workflow")) {
        if (allowWorkflow) {
          return true;
        } else {
          return false;
        }
      } else if (route.includes("/transfer")) {
        if (allowTransfer) {
          return true;
        } else {
          return false;
        }
      } else if (route.includes("/settings")) {
        if (allowTemplate) {
          return true;
        } else {
          return false;
        }
      } else if (route.includes("/network")) {
        if (allowNetwork) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    console.error("Usuário não autenticado");
    return false;
  }

  private loadDataAndNavigate(): Observable<boolean> {
    console.log("loadDataAndNavigate");
    if (this.loadDataWithUser) {
      return this.loadDataWithUser(this.user).pipe(
        map(() => {
          this.loadingService.loading$.next(false);
          return true;
        }),
        catchError((err) => this.handleLoginRedirect(err))
      );
    } else {
      return of(true);
    }
  }

  private handleLoginRedirect(errorMessage?): Observable<boolean> {
    console.error("handleLoginRedirect with errors: ", errorMessage);
    if (this.user) {
      this.router.navigateByUrl(`/`);
    } else {
      this.router.navigateByUrl(`/login`);
    }
    return of(false);
  }
}
