/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/transaction/v1/transaction.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as pb_1 from "google-protobuf";
export namespace core.transaction.v1 {
    export enum TransactionType {
        UNSPECIFIED_TRANSACTION_TYPE = 0,
        BLOCKCHAIN_TRANSFER_SMART_CONTRACT = 1,
        BLOCKCHAIN_PUBLISH_SMART_CONTRACT = 2,
        BLOCKCHAIN_MINT_SMART_CONTRACT = 3,
        BLOCKCHAIN_BURN_TOKEN = 4,
        BLOCKCHAIN_CHANGE_STATUS_SMART_CONTRACT = 5,
        BLOCKCHAIN_TELEPORT_SMART_CONTRACT = 6
    }
    export enum TransactionStatus {
        UNSPECIFIED_STATE = 0,
        INITIAL_STATE = 1,
        FINISHED_STATE = 2,
        PENDING_SIGNATURE_STATE = 3,
        PENDING_BLOCKCHAIN_STATE = 4,
        ERROR_STATE = 5
    }
    export enum SignatureActionType {
        PUBLISH = 0,
        TRANSFER = 1
    }
    export enum PaymentMethod {
        CREDIT_CARD = 0,
        DEBIT_CARD = 1,
        PIX = 2,
        BANK_SLIP = 3
    }
    export enum PixType {
        DOCUMENT_IDENTIFIER = 0,
        PHONE_IDENTIFIER = 1,
        EMAIL_IDENTIFIER = 2,
        RANDOM_IDENTIFIER = 3
    }
    export class Transaction extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            transactionType?: TransactionType;
            extraData?: Map<string, string>;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            status?: TransactionStatus;
            signatures?: Signature[];
            externalServiceId?: string;
            transactionLog?: Map<string, string>;
            finishAt?: dependency_1.google.protobuf.Timestamp;
            error?: string;
            hashVerify?: string;
            billingTicketId?: string;
            productInfoId?: string;
            value?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("extraData" in data && data.extraData != undefined) {
                    this.extraData = data.extraData;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("signatures" in data && data.signatures != undefined) {
                    this.signatures = data.signatures;
                }
                if ("externalServiceId" in data && data.externalServiceId != undefined) {
                    this.externalServiceId = data.externalServiceId;
                }
                if ("transactionLog" in data && data.transactionLog != undefined) {
                    this.transactionLog = data.transactionLog;
                }
                if ("finishAt" in data && data.finishAt != undefined) {
                    this.finishAt = data.finishAt;
                }
                if ("error" in data && data.error != undefined) {
                    this.error = data.error;
                }
                if ("hashVerify" in data && data.hashVerify != undefined) {
                    this.hashVerify = data.hashVerify;
                }
                if ("billingTicketId" in data && data.billingTicketId != undefined) {
                    this.billingTicketId = data.billingTicketId;
                }
                if ("productInfoId" in data && data.productInfoId != undefined) {
                    this.productInfoId = data.productInfoId;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
            if (!this.extraData)
                this.extraData = new Map();
            if (!this.transactionLog)
                this.transactionLog = new Map();
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 2, TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as TransactionType;
        }
        set transactionType(value: TransactionType) {
            pb_1.Message.setField(this, 2, value);
        }
        get extraData() {
            return pb_1.Message.getField(this, 3) as any as Map<string, string>;
        }
        set extraData(value: Map<string, string>) {
            pb_1.Message.setField(this, 3, value as any);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 4) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 5, TransactionStatus.UNSPECIFIED_STATE) as TransactionStatus;
        }
        set status(value: TransactionStatus) {
            pb_1.Message.setField(this, 5, value);
        }
        get signatures() {
            return pb_1.Message.getRepeatedWrapperField(this, Signature, 6) as Signature[];
        }
        set signatures(value: Signature[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        get externalServiceId() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set externalServiceId(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get transactionLog() {
            return pb_1.Message.getField(this, 8) as any as Map<string, string>;
        }
        set transactionLog(value: Map<string, string>) {
            pb_1.Message.setField(this, 8, value as any);
        }
        get finishAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 9) as dependency_1.google.protobuf.Timestamp;
        }
        set finishAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 9, value);
        }
        get hasFinishAt() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get error() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set error(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get hashVerify() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set hashVerify(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        get billingTicketId() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set billingTicketId(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get productInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
        }
        set productInfoId(value: string) {
            pb_1.Message.setField(this, 13, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        static fromObject(data: {
            id?: string;
            transactionType?: TransactionType;
            extraData?: {
                [key: string]: string;
            };
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            status?: TransactionStatus;
            signatures?: ReturnType<typeof Signature.prototype.toObject>[];
            externalServiceId?: string;
            transactionLog?: {
                [key: string]: string;
            };
            finishAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            error?: string;
            hashVerify?: string;
            billingTicketId?: string;
            productInfoId?: string;
            value?: number;
        }): Transaction {
            const message = new Transaction({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (typeof data.extraData == "object") {
                message.extraData = new Map(Object.entries(data.extraData));
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.signatures != null) {
                message.signatures = data.signatures.map(item => Signature.fromObject(item));
            }
            if (data.externalServiceId != null) {
                message.externalServiceId = data.externalServiceId;
            }
            if (typeof data.transactionLog == "object") {
                message.transactionLog = new Map(Object.entries(data.transactionLog));
            }
            if (data.finishAt != null) {
                message.finishAt = dependency_1.google.protobuf.Timestamp.fromObject(data.finishAt);
            }
            if (data.error != null) {
                message.error = data.error;
            }
            if (data.hashVerify != null) {
                message.hashVerify = data.hashVerify;
            }
            if (data.billingTicketId != null) {
                message.billingTicketId = data.billingTicketId;
            }
            if (data.productInfoId != null) {
                message.productInfoId = data.productInfoId;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                transactionType?: TransactionType;
                extraData?: {
                    [key: string]: string;
                };
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                status?: TransactionStatus;
                signatures?: ReturnType<typeof Signature.prototype.toObject>[];
                externalServiceId?: string;
                transactionLog?: {
                    [key: string]: string;
                };
                finishAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                error?: string;
                hashVerify?: string;
                billingTicketId?: string;
                productInfoId?: string;
                value?: number;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.extraData != null) {
                data.extraData = (Object.fromEntries)(this.extraData);
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.signatures != null) {
                data.signatures = this.signatures.map((item: Signature) => item.toObject());
            }
            if (this.externalServiceId != null) {
                data.externalServiceId = this.externalServiceId;
            }
            if (this.transactionLog != null) {
                data.transactionLog = (Object.fromEntries)(this.transactionLog);
            }
            if (this.finishAt != null) {
                data.finishAt = this.finishAt.toObject();
            }
            if (this.error != null) {
                data.error = this.error;
            }
            if (this.hashVerify != null) {
                data.hashVerify = this.hashVerify;
            }
            if (this.billingTicketId != null) {
                data.billingTicketId = this.billingTicketId;
            }
            if (this.productInfoId != null) {
                data.productInfoId = this.productInfoId;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.transactionType != TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(2, this.transactionType);
            for (const [key, value] of this.extraData) {
                writer.writeMessage(3, this.extraData, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (this.hasCreatedAt)
                writer.writeMessage(4, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.status != TransactionStatus.UNSPECIFIED_STATE)
                writer.writeEnum(5, this.status);
            if (this.signatures.length)
                writer.writeRepeatedMessage(6, this.signatures, (item: Signature) => item.serialize(writer));
            if (this.externalServiceId.length)
                writer.writeString(7, this.externalServiceId);
            for (const [key, value] of this.transactionLog) {
                writer.writeMessage(8, this.transactionLog, () => {
                    writer.writeString(1, key);
                    writer.writeString(2, value);
                });
            }
            if (this.hasFinishAt)
                writer.writeMessage(9, this.finishAt, () => this.finishAt.serialize(writer));
            if (this.error.length)
                writer.writeString(10, this.error);
            if (this.hashVerify.length)
                writer.writeString(11, this.hashVerify);
            if (this.billingTicketId.length)
                writer.writeString(12, this.billingTicketId);
            if (this.productInfoId.length)
                writer.writeString(13, this.productInfoId);
            if (this.value != 0)
                writer.writeInt64(14, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Transaction {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Transaction();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.transactionType = reader.readEnum();
                        break;
                    case 3:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.extraData as any, reader, reader.readString, reader.readString));
                        break;
                    case 4:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 5:
                        message.status = reader.readEnum();
                        break;
                    case 6:
                        reader.readMessage(message.signatures, () => pb_1.Message.addToRepeatedWrapperField(message, 6, Signature.deserialize(reader), Signature));
                        break;
                    case 7:
                        message.externalServiceId = reader.readString();
                        break;
                    case 8:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.transactionLog as any, reader, reader.readString, reader.readString));
                        break;
                    case 9:
                        reader.readMessage(message.finishAt, () => message.finishAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 10:
                        message.error = reader.readString();
                        break;
                    case 11:
                        message.hashVerify = reader.readString();
                        break;
                    case 12:
                        message.billingTicketId = reader.readString();
                        break;
                    case 13:
                        message.productInfoId = reader.readString();
                        break;
                    case 14:
                        message.value = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Transaction {
            return Transaction.deserialize(bytes);
        }
    }
    export class Signature extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sub?: string;
            email?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            ipAddress?: string;
            name?: string;
            username?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sub" in data && data.sub != undefined) {
                    this.sub = data.sub;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("ipAddress" in data && data.ipAddress != undefined) {
                    this.ipAddress = data.ipAddress;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("username" in data && data.username != undefined) {
                    this.username = data.username;
                }
            }
        }
        get sub() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sub(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get ipAddress() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set ipAddress(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get username() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set username(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            sub?: string;
            email?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            ipAddress?: string;
            name?: string;
            username?: string;
        }): Signature {
            const message = new Signature({});
            if (data.sub != null) {
                message.sub = data.sub;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.ipAddress != null) {
                message.ipAddress = data.ipAddress;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.username != null) {
                message.username = data.username;
            }
            return message;
        }
        toObject() {
            const data: {
                sub?: string;
                email?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                ipAddress?: string;
                name?: string;
                username?: string;
            } = {};
            if (this.sub != null) {
                data.sub = this.sub;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.ipAddress != null) {
                data.ipAddress = this.ipAddress;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.username != null) {
                data.username = this.username;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sub.length)
                writer.writeString(1, this.sub);
            if (this.email.length)
                writer.writeString(2, this.email);
            if (this.hasCreatedAt)
                writer.writeMessage(3, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.ipAddress.length)
                writer.writeString(4, this.ipAddress);
            if (this.name.length)
                writer.writeString(5, this.name);
            if (this.username.length)
                writer.writeString(6, this.username);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Signature {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Signature();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sub = reader.readString();
                        break;
                    case 2:
                        message.email = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        message.ipAddress = reader.readString();
                        break;
                    case 5:
                        message.name = reader.readString();
                        break;
                    case 6:
                        message.username = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Signature {
            return Signature.deserialize(bytes);
        }
    }
    export class Party extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            document?: string;
            companyName?: string;
            responsibleName?: string;
            role?: string;
            phone?: string;
            email?: string;
            address?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
                if ("companyName" in data && data.companyName != undefined) {
                    this.companyName = data.companyName;
                }
                if ("responsibleName" in data && data.responsibleName != undefined) {
                    this.responsibleName = data.responsibleName;
                }
                if ("role" in data && data.role != undefined) {
                    this.role = data.role;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
            }
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get companyName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set companyName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get responsibleName() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set responsibleName(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get role() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set role(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            document?: string;
            companyName?: string;
            responsibleName?: string;
            role?: string;
            phone?: string;
            email?: string;
            address?: string;
        }): Party {
            const message = new Party({});
            if (data.document != null) {
                message.document = data.document;
            }
            if (data.companyName != null) {
                message.companyName = data.companyName;
            }
            if (data.responsibleName != null) {
                message.responsibleName = data.responsibleName;
            }
            if (data.role != null) {
                message.role = data.role;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.address != null) {
                message.address = data.address;
            }
            return message;
        }
        toObject() {
            const data: {
                document?: string;
                companyName?: string;
                responsibleName?: string;
                role?: string;
                phone?: string;
                email?: string;
                address?: string;
            } = {};
            if (this.document != null) {
                data.document = this.document;
            }
            if (this.companyName != null) {
                data.companyName = this.companyName;
            }
            if (this.responsibleName != null) {
                data.responsibleName = this.responsibleName;
            }
            if (this.role != null) {
                data.role = this.role;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.address != null) {
                data.address = this.address;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.document.length)
                writer.writeString(1, this.document);
            if (this.companyName.length)
                writer.writeString(2, this.companyName);
            if (this.responsibleName.length)
                writer.writeString(3, this.responsibleName);
            if (this.role.length)
                writer.writeString(4, this.role);
            if (this.phone.length)
                writer.writeString(5, this.phone);
            if (this.email.length)
                writer.writeString(6, this.email);
            if (this.address.length)
                writer.writeString(7, this.address);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Party {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Party();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.document = reader.readString();
                        break;
                    case 2:
                        message.companyName = reader.readString();
                        break;
                    case 3:
                        message.responsibleName = reader.readString();
                        break;
                    case 4:
                        message.role = reader.readString();
                        break;
                    case 5:
                        message.phone = reader.readString();
                        break;
                    case 6:
                        message.email = reader.readString();
                        break;
                    case 7:
                        message.address = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Party {
            return Party.deserialize(bytes);
        }
    }
    export class CounterParty extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            document?: string;
            companyName?: string;
            responsibleName?: string;
            role?: string;
            phone?: string;
            email?: string;
            address?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("document" in data && data.document != undefined) {
                    this.document = data.document;
                }
                if ("companyName" in data && data.companyName != undefined) {
                    this.companyName = data.companyName;
                }
                if ("responsibleName" in data && data.responsibleName != undefined) {
                    this.responsibleName = data.responsibleName;
                }
                if ("role" in data && data.role != undefined) {
                    this.role = data.role;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
            }
        }
        get document() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set document(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get companyName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set companyName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get responsibleName() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set responsibleName(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get role() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set role(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            document?: string;
            companyName?: string;
            responsibleName?: string;
            role?: string;
            phone?: string;
            email?: string;
            address?: string;
        }): CounterParty {
            const message = new CounterParty({});
            if (data.document != null) {
                message.document = data.document;
            }
            if (data.companyName != null) {
                message.companyName = data.companyName;
            }
            if (data.responsibleName != null) {
                message.responsibleName = data.responsibleName;
            }
            if (data.role != null) {
                message.role = data.role;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.address != null) {
                message.address = data.address;
            }
            return message;
        }
        toObject() {
            const data: {
                document?: string;
                companyName?: string;
                responsibleName?: string;
                role?: string;
                phone?: string;
                email?: string;
                address?: string;
            } = {};
            if (this.document != null) {
                data.document = this.document;
            }
            if (this.companyName != null) {
                data.companyName = this.companyName;
            }
            if (this.responsibleName != null) {
                data.responsibleName = this.responsibleName;
            }
            if (this.role != null) {
                data.role = this.role;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.address != null) {
                data.address = this.address;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.document.length)
                writer.writeString(1, this.document);
            if (this.companyName.length)
                writer.writeString(2, this.companyName);
            if (this.responsibleName.length)
                writer.writeString(3, this.responsibleName);
            if (this.role.length)
                writer.writeString(4, this.role);
            if (this.phone.length)
                writer.writeString(5, this.phone);
            if (this.email.length)
                writer.writeString(6, this.email);
            if (this.address.length)
                writer.writeString(7, this.address);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CounterParty {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CounterParty();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.document = reader.readString();
                        break;
                    case 2:
                        message.companyName = reader.readString();
                        break;
                    case 3:
                        message.responsibleName = reader.readString();
                        break;
                    case 4:
                        message.role = reader.readString();
                        break;
                    case 5:
                        message.phone = reader.readString();
                        break;
                    case 6:
                        message.email = reader.readString();
                        break;
                    case 7:
                        message.address = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CounterParty {
            return CounterParty.deserialize(bytes);
        }
    }
    export class PaymentDetails extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            amount?: number;
            discount?: number;
            operationDate?: dependency_1.google.protobuf.Timestamp;
            paymentMethod?: PaymentMethod;
            pixType?: PixType;
            pixIdentifier?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("amount" in data && data.amount != undefined) {
                    this.amount = data.amount;
                }
                if ("discount" in data && data.discount != undefined) {
                    this.discount = data.discount;
                }
                if ("operationDate" in data && data.operationDate != undefined) {
                    this.operationDate = data.operationDate;
                }
                if ("paymentMethod" in data && data.paymentMethod != undefined) {
                    this.paymentMethod = data.paymentMethod;
                }
                if ("pixType" in data && data.pixType != undefined) {
                    this.pixType = data.pixType;
                }
                if ("pixIdentifier" in data && data.pixIdentifier != undefined) {
                    this.pixIdentifier = data.pixIdentifier;
                }
            }
        }
        get amount() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set amount(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get discount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set discount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get operationDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set operationDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasOperationDate() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get paymentMethod() {
            return pb_1.Message.getFieldWithDefault(this, 4, PaymentMethod.CREDIT_CARD) as PaymentMethod;
        }
        set paymentMethod(value: PaymentMethod) {
            pb_1.Message.setField(this, 4, value);
        }
        get pixType() {
            return pb_1.Message.getFieldWithDefault(this, 5, PixType.DOCUMENT_IDENTIFIER) as PixType;
        }
        set pixType(value: PixType) {
            pb_1.Message.setField(this, 5, value);
        }
        get pixIdentifier() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set pixIdentifier(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            amount?: number;
            discount?: number;
            operationDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            paymentMethod?: PaymentMethod;
            pixType?: PixType;
            pixIdentifier?: string;
        }): PaymentDetails {
            const message = new PaymentDetails({});
            if (data.amount != null) {
                message.amount = data.amount;
            }
            if (data.discount != null) {
                message.discount = data.discount;
            }
            if (data.operationDate != null) {
                message.operationDate = dependency_1.google.protobuf.Timestamp.fromObject(data.operationDate);
            }
            if (data.paymentMethod != null) {
                message.paymentMethod = data.paymentMethod;
            }
            if (data.pixType != null) {
                message.pixType = data.pixType;
            }
            if (data.pixIdentifier != null) {
                message.pixIdentifier = data.pixIdentifier;
            }
            return message;
        }
        toObject() {
            const data: {
                amount?: number;
                discount?: number;
                operationDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                paymentMethod?: PaymentMethod;
                pixType?: PixType;
                pixIdentifier?: string;
            } = {};
            if (this.amount != null) {
                data.amount = this.amount;
            }
            if (this.discount != null) {
                data.discount = this.discount;
            }
            if (this.operationDate != null) {
                data.operationDate = this.operationDate.toObject();
            }
            if (this.paymentMethod != null) {
                data.paymentMethod = this.paymentMethod;
            }
            if (this.pixType != null) {
                data.pixType = this.pixType;
            }
            if (this.pixIdentifier != null) {
                data.pixIdentifier = this.pixIdentifier;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.amount != 0)
                writer.writeInt64(1, this.amount);
            if (this.discount != 0)
                writer.writeInt64(2, this.discount);
            if (this.hasOperationDate)
                writer.writeMessage(3, this.operationDate, () => this.operationDate.serialize(writer));
            if (this.paymentMethod != PaymentMethod.CREDIT_CARD)
                writer.writeEnum(4, this.paymentMethod);
            if (this.pixType != PixType.DOCUMENT_IDENTIFIER)
                writer.writeEnum(5, this.pixType);
            if (this.pixIdentifier.length)
                writer.writeString(6, this.pixIdentifier);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PaymentDetails {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PaymentDetails();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.amount = reader.readInt64();
                        break;
                    case 2:
                        message.discount = reader.readInt64();
                        break;
                    case 3:
                        reader.readMessage(message.operationDate, () => message.operationDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        message.paymentMethod = reader.readEnum();
                        break;
                    case 5:
                        message.pixType = reader.readEnum();
                        break;
                    case 6:
                        message.pixIdentifier = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PaymentDetails {
            return PaymentDetails.deserialize(bytes);
        }
    }
    export class Device extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            ipAddress?: string;
            port?: string;
            userAgent?: string;
            geolocation?: string;
            timeZone?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ipAddress" in data && data.ipAddress != undefined) {
                    this.ipAddress = data.ipAddress;
                }
                if ("port" in data && data.port != undefined) {
                    this.port = data.port;
                }
                if ("userAgent" in data && data.userAgent != undefined) {
                    this.userAgent = data.userAgent;
                }
                if ("geolocation" in data && data.geolocation != undefined) {
                    this.geolocation = data.geolocation;
                }
                if ("timeZone" in data && data.timeZone != undefined) {
                    this.timeZone = data.timeZone;
                }
            }
        }
        get ipAddress() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set ipAddress(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get port() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set port(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get userAgent() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set userAgent(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get geolocation() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set geolocation(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get timeZone() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set timeZone(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            ipAddress?: string;
            port?: string;
            userAgent?: string;
            geolocation?: string;
            timeZone?: string;
        }): Device {
            const message = new Device({});
            if (data.ipAddress != null) {
                message.ipAddress = data.ipAddress;
            }
            if (data.port != null) {
                message.port = data.port;
            }
            if (data.userAgent != null) {
                message.userAgent = data.userAgent;
            }
            if (data.geolocation != null) {
                message.geolocation = data.geolocation;
            }
            if (data.timeZone != null) {
                message.timeZone = data.timeZone;
            }
            return message;
        }
        toObject() {
            const data: {
                ipAddress?: string;
                port?: string;
                userAgent?: string;
                geolocation?: string;
                timeZone?: string;
            } = {};
            if (this.ipAddress != null) {
                data.ipAddress = this.ipAddress;
            }
            if (this.port != null) {
                data.port = this.port;
            }
            if (this.userAgent != null) {
                data.userAgent = this.userAgent;
            }
            if (this.geolocation != null) {
                data.geolocation = this.geolocation;
            }
            if (this.timeZone != null) {
                data.timeZone = this.timeZone;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ipAddress.length)
                writer.writeString(1, this.ipAddress);
            if (this.port.length)
                writer.writeString(2, this.port);
            if (this.userAgent.length)
                writer.writeString(3, this.userAgent);
            if (this.geolocation.length)
                writer.writeString(4, this.geolocation);
            if (this.timeZone.length)
                writer.writeString(5, this.timeZone);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Device {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Device();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.ipAddress = reader.readString();
                        break;
                    case 2:
                        message.port = reader.readString();
                        break;
                    case 3:
                        message.userAgent = reader.readString();
                        break;
                    case 4:
                        message.geolocation = reader.readString();
                        break;
                    case 5:
                        message.timeZone = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Device {
            return Device.deserialize(bytes);
        }
    }
}
